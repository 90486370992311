@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyMemTempCards {
  width: 700px;

  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100vh;
}

body {
  background: #e8e8e8;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.team-cards-info-header {
  width: 100%;
  height: 56px;

  background: #304457;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* margin-bottom: 20px; */
  overflow-x: hidden;
}
.team-cards-info-header img {
  margin-left: 15px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.team-cards-info-header > .team-cards-info-text img {
  margin-left: 0px;
}

.team-cards-label {
  width: 100%;
  height: 38px;

  background: #d9d9d9;
  opacity: 0.2;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  /* Text color */

  color: #333333;
}
.no-active-team-cards-dis {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.add-team-cards-btn {
  width: 90%;
  height: 49px;

  background: #36a6a4;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: auto;
  margin-right: auto; */
  position: absolute;
  bottom: 10px;
  margin: 0 auto;
  left: 0;
  right: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #ffffff;
}
.no-active-team-cards-ff {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.no-active-team-cards-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  color: #333333;

  margin-top: 20px;
  margin-bottom: 20px;
}
.no-active-team-cards-text-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  text-align: center;

  /* Text color */

  color: #333333;

  opacity: 0.67;
}

@media only screen and (max-width: 600px) {
  body {
    background: #e8e8e8;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .team-cards-info-header {
    width: 100vw;
    height: 56px;

    background: #304457;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }
  .team-cards-info-header img {
    margin-left: 15px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .team-cards-info-header > .team-cards-info-text img {
    margin-left: 0px;
  }

  .team-cards-label {
    width: 100%;
    height: 38px;

    background: #d9d9d9;
    opacity: 0.2;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* Text color */

    color: #333333;
  }
  .no-active-team-cards-dis {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }

  .add-team-cards-btn {
    width: 90%;
    height: 49px;

    background: #36a6a4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: auto;
  margin-right: auto; */
    position: absolute;
    bottom: 10px;
    margin: 0 auto;
    left: 0;
    right: 0;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    color: #ffffff;
  }
  .no-active-team-cards-ff {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .no-active-team-cards-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #333333;

    margin-top: 20px;
    margin-bottom: 20px;
  }
  .no-active-team-cards-text-sub {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: center;

    /* Text color */

    color: #333333;

    opacity: 0.67;
  }
}
