@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

/* The Modal (background) */
.totalResBodyLog {
  width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.logoutGen-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%; /* Full width */
  max-width: 500px;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.7);

  /* opacity: 0.7; */
  backdrop-filter: blur(20px);
}

.logoutModalGen-content {
  position: absolute;
  bottom: 0;
  height: fit-content;
  width: 100%;

  background: #f2f2f2;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;

  /* transition: 0.3s ease-in-out 0s; */
  left: 0;
  right: 0;

  border-radius: 17px 17px 0px 0px;
  /* padding: 10px; */
}

@-webkit-keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.modal-body {
  padding: 2px 16px;
}

.logoutModal-cancelFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.close_logout_btn {
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
}

.close_logout_btn img {
  width: 36px;
  height: 36px;
}

.close_logout_btn:hover {
  transform: scale(1.1);
}

.logout-modal-btnText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  color: #ff0000;
}

.logout-preTextF {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #171923;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.logout-Mtext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;

  opacity: 0.77;

  width: 90%;
  text-align: left;
  word-wrap: break-word;
  margin-left: 20px;
  margin-right: 20px;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.logout-btnsFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;
}

.logout-Admincontinue-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  height: 49px;

  /* background: #36a6a4; */
  border-radius: 14px;
  margin-top: 20px;
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}

.logout-Admincontinue-btn:hover {
  /* background: #248381; */

  opacity: 0.8;
  border-radius: 10px;
}

.logout-Admincancel-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  height: 49px;

  background: #f2f2f2;
  border-radius: 14px;
  box-sizing: border-box;
  border: 1px solid #171923;
  margin-top: 10px;
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}
.logout-Admincancel-btn:hover {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}
.logout-fulltext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* or 183% */
  margin-bottom: 10px;
  margin-top: 5px;

  /* Text color */

  color: #333333;
}
.logout-continue-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  width: 212px;

  border: 1px solid #36a6a4;
  border-radius: 3px;
  margin-bottom: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background: #36a6a4;
  /* Text color */

  color: #ffffff;
}
.logout-cancel-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  width: 212px;
  background: #ffffff;
  border-radius: 3px;

  font-style: normal;
  font-weight: 400;
  border: 1px solid #36a6a4;

  font-size: 14px;

  /* Text color */

  color: #333333;
}

@media only screen and (max-width: 1400px) {
  .logoutGen-modal {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .logoutGen-modal {
    max-width: 100%;
  }
}
