@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }

  .members-sendInvite-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    margin-bottom: 20px;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  }
  .members-sendInvite-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .members-sendInvite-info-header > .members-sendInvite-info-text {
    margin-left: -30px;
  }

  .members-inputSendInvite {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .members-inputSendInvite input {
    padding: 8px 30px;
    padding-top: 30px;
    padding-left: 20px;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 80%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    -webkit-appearance: none;
    border-radius: 0;

    color: #333333;
  }

  .members-inputSendInvite input:focus {
    /* border: 1.8px solid #6b6b6b; */
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .members-inputSendInvite span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .members-inputSendInvite input:focus + span,
  .members-inputSendInvite input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .members-inputSendInvite input,
  .members-inputSendInvite span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .members-sendInvite-info-textttts {
    margin-top: 50%;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */
    text-align: center;

    color: #333333;

    opacity: 0.3;
  }
  .members-sendInvite-info-addmemb {
    margin-top: 10%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;

    color: #fcb816;
  }
  .members-sendInvite-info-sendin-btn {
    margin-top: 20%;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 49px;

    /* PROVIDUS YELLOS */
    border-radius: 6px;

    background: #fcb816;
    /* opacity: 0.3; */
  }

  .members-sendInvite-info-sendin-btn input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    width: 100%;
    height: 100%;

    opacity: 1;
    border-radius: 6px;
  }
}
