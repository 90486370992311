.genIconCont_dyna {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
}

.phone-icon-s_dyna {
  /* margin-top: 5px; */
  width: 100%;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border-radius: 50%;
  opacity: 0.2;
}

.childIcon_dyna {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}
