.backgroundGenFamCropper {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  /* position: relative; */
}
.MuiButton-root {
  padding: 0;
}

.container {
  height: 80%;
  width: 500px;
  /* background-color: #ffffff; */
  /* filter: blur(8px); */
  background: rgba(23, 25, 35, 0.7);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;

  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.4s;
  animation-name: animateright;
  animation-duration: 0.8s;
  /* transition: transform 0.3s ease-in-out; */
  z-index: 6000000000;
}

/* Add Animation */
@-webkit-keyframes animateright {
  from {
    right: -600px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -600px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.container-cropper {
  height: 90%;
  padding: 10px;
}

.cropper {
  height: 90%;
  position: relative;
}

.slider {
  height: 10%;
  display: flex;
  align-items: center;
  margin: auto;
  width: 60%;
}

.container-buttons {
  /* border: 1px solid #f5f5f5; */
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* backdrop-filter: blur(10px); */
  background: #171923;
  /* background: rgba(23, 25, 35, 0.2); */
  backdrop-filter: blur(10px);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.cropperStyleShe {
  font-size: 12px;
}

@media only screen and (max-width: 1400px) {
  .container {
    width: 500px;
  }

  .backgroundGenFamCropper {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    /* position: relative; */
  }
}

@media only screen and (max-width: 600px) {
  .container {
    width: 100%;
  }

  .backgroundGenFamCropper {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    /* position: relative; */
  }
}

@media only screen and (max-width: 330px) {
  .cropperStyleShe {
    font-size: 10px;
  }
}
