.totalResBodyPreAccount {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  background: #fbfbfb;
  position: relative;
  height: 100vh;
}

.sectionHam-preAccount {
  width: 100%;
  height: 56px;

  background: #171923;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* margin-bottom: 20px; */
  overflow-x: hidden;
}

/* .support-info-header img {
    margin-left: 30px;
  } */

/* .backdrop-header img {
  margin-right: 100px;
} */

.sectionHam-preAccount > .sectionHam-preAccount-text {
  margin-left: 30px;
}

.shareicon-preAccount {
  width: 30px;
  height: 30px;
  background: #171923;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.share-preAccount {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.preAcct_profile-pics-cont {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 235px;
  border: none;
  margin-top: -1px;

  background: #171923;
  border-radius: 0px 0px 40px 0px;
  /* padding-top: 10px; */
  /* margin-top: 50px; */
  margin-bottom: 30px;
}

.preAcct_backdrop-cont {
  position: absolute;
  margin-top: 0px;

  top: 5px;
  box-sizing: border-box;

  width: 95%;
  height: 156px;

  background: #ffffff;
  border-radius: 0px 0px 35px 35px;
  overflow: hidden;
}

.preAcct_backdrop-img-main {
  width: 100%;
  height: 100%;
}

.preAcct_cont-all-avatar {
  position: relative;
}

.preAcct_user-profile-pics {
  overflow: hidden;
  position: relative;

  width: 169px;
  height: 161px;

  background: #fbffff;
  /* box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2); */
  border: 4px solid #36a6a4;
  backdrop-filter: blur(1.5px);

  border-radius: 15px;
  top: -20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #171923;
}

.preAcct_uploaded-img img {
  margin-top: 16px;
  height: 100%;
  width: 100%;
}

.preAcct_user-profile-pics-text {
  margin-top: 10px;
}

.preAcct_edit-profile-pics-family {
  position: absolute;
  bottom: 35px;

  left: 55px;
}

.preAcct_user-profile-details {
  position: absolute;
  bottom: 5px;
  text-align: center;
}

.preAcct_user-name {
  font-style: normal;
  font-weight: 600;
  font-size: 28px;

  color: #ffffff;
}

.preAcct_email-contAd {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-left: 20px; */
  margin-right: 20px;
  transition: 0.3s ease-in-out 0s;
}

.preAcct_phone-first-flexAd {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out 0s;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.preAcctemail-icon-sAd {
  margin-top: 5px;
}

.preAcctemail-addressAd {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  /* Text color */
  margin-left: 20px;
  color: #333333;

  line-height: 22px;
  letter-spacing: 0.0075em;

  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.preAcctContact_head {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-left: auto;
  margin-right: auto;
  width: 90%;

  color: #333333;
  margin-top: 40px;
}

.preAcctModalGen {
  box-sizing: border-box;
  width: 100%;
}

.preAcctModal_headText {
  font-style: italic;
  font-weight: 300;
  font-size: 13px;

  color: #36a6a4;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.preAcctConter_Gen {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
}

.preAcctCounter_Head {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: #333333;
}

.preAcctCounter_FamFlex {
  display: flex;
  align-items: center;
  width: 100px;
}
.preAcctCounter_minus {
  margin-left: 10px;
  margin-right: 10px;
}
.preAcctCounter_minus img {
  width: 41px;
  height: 41px;
}

.preAcctCounter_input {
  background: transparent;
  border: none;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  width: 20px;
  text-align: center;
  color: #333333;
}

.preAcctCounter_add {
  margin-left: 10px;
  margin-right: 10px;
}

.preAcctCounter_add img {
  width: 41px;
  height: 41px;
}

/* .react-switch {
  vertical-align: middle;
  margin-left: 4px;
} */
/* .react-switch input {
  display: none;
} */

.preAcctSwitch_toggleGen {
  display: flex;
  align-items: center;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}
.preAcctSwitch_text {
  font-style: normal;
  font-weight: 700;
  font-size: 15px;

  color: #333333;
  margin-right: 20px;
}

.preAcct_dashLine {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  border: 1px dashed #aaaaaa;
  margin-top: 30px;
}

.preAcct_TotalFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}

.preAcct_headText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #171923;
}

.preAcct_price {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #171923;
}

.preAcct_emailCont {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;

  height: 44px;

  background: #f6f7f0;
  border: 1px dashed #b8b598;
  border-radius: 100px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.preAcct_emailtext {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
}
.preAcct_emailIcon {
  width: 25px;
  height: 24px;
  margin-left: 10px;
}

.preAcct_emailIcon img {
  width: 25px;
  height: 24px;
}

.cardDesignOneGen {
  width: 152px;
  height: 114px;

  background: #444444;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
}
.cardDesignRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardDesignEachRow {
  width: fit-content;
  min-width: 29px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  color: #fbfbfb;
  margin: 6px;
}

.cardDesignBodyFam {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1400px) {
  .totalResBodyPreAccount {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }

  .totalResBodyPreAccount {
    max-width: 100%;
  }

  .share-preAccount {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 415px) {
  .preAcct_emailCont {
    width: 80%;
  }
}
@media only screen and (max-width: 320px) {
  .preAcct_user-name {
    font-size: 15px;
  }
}
