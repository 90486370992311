.inputFieldPersonalize-num {
  position: relative;
  width: 550px;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.inputFieldPersonalize-num input {
  padding: 8px 20px;
  padding-top: 30px;
  border: 1.5px solid #e2e8f0;
  /* border-bottom: 1px solid #cbd5e0; */
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 90%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  -webkit-appearance: none;
  border-radius: 18px;
  color: #333333;
  background: #f8fafc;
}

.inputFieldPersonalize-num input:focus {
  /* border: 1.8px solid #6b6b6b; */
  outline: none;
  border: 1.5px solid #36a6a4;
}
.inputFieldPersonalize-num span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.inputFieldPersonalize-num input:focus + span,
.inputFieldPersonalize-num input:not(:placeholder-shown) + span {
  top: 7px;
  -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  font-size: 14px;
}
.inputFieldPersonalize-num input,
.inputFieldPersonalize-num span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

@media only screen and (max-width: 600px) {
  .inputFieldPersonalize-num {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .inputFieldPersonalize-num input {
    padding: 8px 20px;
    padding-top: 30px;
    border: 1.5px solid #e2e8f0;
    /* border-bottom: 1px solid #cbd5e0; */
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 80%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    -webkit-appearance: none;
    border-radius: 18px;
    color: #333333;
    background: #f8fafc;
  }

  .inputFieldPersonalize-num input:focus {
    /* border: 1.8px solid #6b6b6b; */
    outline: none;
    border: 1.5px solid #36a6a4;
  }
  .inputFieldPersonalize-num span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .inputFieldPersonalize-num input:focus + span,
  .inputFieldPersonalize-num input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .inputFieldPersonalize-num input,
  .inputFieldPersonalize-num span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}
