.totalResBodyMemAddTwo {
  width: 700px;
  margin-right: auto;
  margin-left: auto;
  /* height: 700px; */
  /* position: relative; */
}

.team-cards-two-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

  background: rgba(23, 25, 35, 0.8);
  backdrop-filter: blur(20px);
}
.team-cards-two-close_logout_icon {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3), 0px 6px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  /* top: -50px; */
  /* margin: 0 auto; */
  /* left: 0;
    right: 0;
    margin: 0 auto; */
  top: 18%;
  text-align: center;
  /* -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out; */
}

/* Modal Content */
.team-cards-two-modal-content {
  position: relative;

  text-align: center;
  /* background-color: #fefefe; */
  margin: auto;
  padding: 0;
  /* border: 1px solid #888; */
  /* width: 80%; */
  width: 80%;
  height: 500px;

  background: #ffffff;
  box-shadow: 4px 9px 20px 4px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  /* -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out; */
  overflow-y: scroll;
}

/* Add Animation */

.team-cards-two-modal-content-cccc {
  margin: auto;
  padding: 0;
  position: relative;

  text-align: center;
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.4s;
  animation-name: animateright;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
}
@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.team-cards-two-modal-body {
  /* margin-top: 20px; */
  padding: 2px 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  /* margin-left: auto;
    margin-right: auto; */

  /* Text color */

  color: #333333;
}

.team-cards-two-select-temp {
  width: 100%;

  height: 46px;

  background: #ffffff;

  border-bottom: 1px solid #cbd5e0;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Text color */

  color: #333333;
  margin-left: auto;
  margin-left: auto;
  margin-bottom: 40px;
  border-radius: 16px 16px 0px 0px;
}

.team-assign-text-two {
  margin-right: 190px;
}

.back-arrow-two {
  margin-left: 25px;
}
.team-cards-two-select-temp-li {
  width: 100%;

  height: 46px;

  background: #ffffff;

  border-bottom: 1px solid #cbd5e0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  /* Text color */

  color: #333333;
  margin-left: auto;
  margin-left: auto;
  /* margin-top: 20px; */

  opacity: 0.8;
}

@media only screen and (max-width: 600px) {
  /* The Modal (background) */
  .team-cards-two-modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    /* padding-top: 100px;  */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */

    background: rgba(23, 25, 35, 0.8);
    backdrop-filter: blur(20px);
  }
  .team-cards-two-close_logout_icon {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3),
      0px 6px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    /* top: -50px; */
    /* margin: 0 auto; */
    /* left: 0;
    right: 0;
    margin: 0 auto; */
    top: 18%;
    text-align: center;
    /* -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out; */
  }

  /* Modal Content */
  .team-cards-two-modal-content {
    position: relative;

    text-align: center;
    /* background-color: #fefefe; */
    margin: auto;
    padding: 0;
    /* border: 1px solid #888; */
    /* width: 80%; */
    width: 80%;
    height: 50%;

    background: #ffffff;
    box-shadow: 4px 9px 20px 4px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    /* -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out; */
    overflow-y: scroll;
  }

  /* Add Animation */

  .team-cards-two-modal-content-cccc {
    margin: auto;
    padding: 0;
    position: relative;

    text-align: center;
    -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
  }
  @-webkit-keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  .team-cards-two-modal-body {
    /* margin-top: 20px; */
    padding: 2px 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* margin-left: auto;
    margin-right: auto; */

    /* Text color */

    color: #333333;
  }

  .team-cards-two-select-temp {
    width: 100%;

    height: 46px;

    background: #ffffff;

    border-bottom: 1px solid #cbd5e0;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    /* Text color */

    color: #333333;
    margin-left: auto;
    margin-left: auto;
    margin-bottom: 40px;
    border-radius: 16px 16px 0px 0px;
  }

  .team-assign-text-two {
    margin-right: 25px;
  }

  .back-arrow-two {
    margin-left: 25px;
  }
  .team-cards-two-select-temp-li {
    width: 100%;

    height: 46px;

    background: #ffffff;

    border-bottom: 1px solid #cbd5e0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;

    /* Text color */

    color: #333333;
    margin-left: auto;
    margin-left: auto;
    /* margin-top: 20px; */

    opacity: 0.8;
  }
}
