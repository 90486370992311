.totalResBodyTeamSupport {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f8f8;
  overflow-x: hidden;
}
.team-support-family {
  background: #e8e8e8;
  padding-bottom: 100px;
}
.team-support-info-header {
  width: 100%;
  height: 56px;

  background: #304457;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  margin-bottom: 20px;
  overflow-x: hidden;
}
/* .team-support-info-header img {
    margin-left: 30px;
  } */

/* .backdrop-header img {
  margin-right: 100px;
} */

.team-support-info-header > .team-support-info-text {
  margin-left: 0px;
}

.team-support_text_heading {
  width: 100%;
  height: 31px;

  /* Label */

  background: #e9e9e9;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
}

.team-share-flex-cont {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}
.team-whatsapp-share-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;

  color: #333333;

  width: 130px;
  height: 120px;

  background: #ffffff;
  box-shadow: -4px -3px 8px #ffffff, 4px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 22px;
}
.team-whatsapp-share-cont img {
  margin-top: 10px;
}

.team-mail-share-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #333333;

  width: 130px;
  height: 120px;

  background: #ffffff;
  box-shadow: -4px -3px 8px #ffffff, 4px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 22px;
}
.team-mail-share-cont img {
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #e8e8e8;
    overflow-x: hidden;
  }
  .team-support-family {
    background: #f8f8f8;
    padding-bottom: 100px;
  }
  .team-support-info-header {
    width: 100vw;
    height: 56px;

    background: #304457;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    margin-bottom: 20px;
    overflow-x: hidden;
  }
  /* .team-support-info-header img {
    margin-left: 30px;
  } */

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .team-support-info-header > .team-support-info-text {
    margin-left: 0px;
  }

  .team-support_text_heading {
    width: 100%;
    height: 31px;

    /* Label */

    background: #e9e9e9;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
  }

  .team-share-flex-cont {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }
  .team-whatsapp-share-cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.02em;

    color: #333333;

    width: 130px;
    height: 120px;

    background: #ffffff;
    box-shadow: -4px -3px 8px #ffffff, 4px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 22px;
  }
  .team-whatsapp-share-cont img {
    margin-top: 10px;
  }

  .team-mail-share-cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    color: #333333;

    width: 130px;
    height: 120px;

    background: #ffffff;
    box-shadow: -4px -3px 8px #ffffff, 4px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 22px;
  }
  .team-mail-share-cont img {
    margin-top: 10px;
  }
}
