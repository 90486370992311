.newModal-genBody {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.7);
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}

.newModal-Content {
  position: absolute;
  bottom: 0;
  height: 70%;
  width: 100%;

  background: #fbfbfb;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  left: 0;
  right: 0;

  border-radius: 17px 17px 0px 0px;
  /* padding: 10px; */
}

@-webkit-keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.newModalContent-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  height: 60px;
}

.newModalContent-headerText {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #171923;
  /* margin-left: 20px; */
}

.newModalContent-headerImg {
  transition: 0.5s ease-in-out 0s;
}

.newModalContent-headerImg img {
  width: 36px;
  height: 36px;
  margin-right: 20px;
  cursor: pointer;
}

.newModalContent-headerImg img:hover {
  transform: scale(1.1);
}

.newModal-Btn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 14px 11px; */

  /* position: absolute; */
  position: fixed;
  width: 90%;
  height: 49px;
  bottom: 5px;
  right: 0;
  left: 0%;
  margin: 0 auto;

  /* primary/deets green */

  /* background: #36a6a4; */
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
  transition: 0.5s ease-in-out 0s;
}

.newModal-Btn:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 1400px) {
  .newModal-genBody {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .newModal-genBody {
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* align-items: flex-end; */
    right: 0;
    top: 0;
    max-width: 100%;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background: #171923; */
    background: rgba(23, 25, 35, 0.7);
    /* opacity: 0.7; */
    /* backdrop-filter: blur(10px); */
  }

  .newModal-Content {
    position: absolute;
    bottom: 0;
    height: 70%;
    width: 100%;

    background: #fbfbfb;
    /* padding: 10px; */
    /* z-index: 400000; */
    overflow-y: scroll;
    -webkit-animation-name: animatedown;
    -webkit-animation-duration: 0.4s;
    animation-name: animatedown;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
    left: 0;
    right: 0;

    border-radius: 17px 17px 0px 0px;
    /* padding: 10px; */
  }

  @-webkit-keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  .newModalContent-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    height: 60px;
  }

  .newModalContent-headerText {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    color: #171923;
    /* margin-left: 20px; */
  }

  .newModalContent-headerImg img {
    width: 36px;
    height: 36px;
    margin-right: 20px;
    cursor: pointer;
  }

  .newModal-Btn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 14px 11px; */

    /* position: absolute; */
    position: fixed;
    width: 90%;
    height: 49px;
    bottom: 5px;
    right: 0;
    left: 0%;
    margin: 0 auto;

    /* primary/deets green */

    /* background: #36a6a4; */
    border-radius: 12px;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    color: #ffffff;
  }
}
