@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}
.totalResProfBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.profile-imgBodyGen {
  background: #f2f2f2;
  min-height: 100vh;
  overflow-x: hidden;
  padding-bottom: 100px;
}

.profile-imgFrame {
  margin-left: auto;
  margin-right: auto;
  width: 169px;
  height: 161px;

  background: #f2f2f2;
  border-radius: 15px;
  /* border: 4px solid #36a6a4; */
  position: relative;
  margin-top: 30px;
}
._imageLoader {
  /* margin-left: auto;
  margin-right: auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.delete_imageGen {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
}

.delete_imageBtn_actv {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  background: #36a6a4;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  margin-top: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.delete_imageBtn_actv:hover {
  opacity: 0.8;
}
.delete_imageBtn_dis {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  background: #fbfbfb;
  border: 1px solid #171923;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  margin-top: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
}
.profile-uploadedImgPre {
  width: 100%;
  height: 100%;
}

.profile-uploadedImgPre img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.profile-uploadedImgText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 100px;
  height: 100px;

  color: #333333;

  opacity: 0.6;

  position: absolute;
  top: 50%;
  left: 50%;
  /* -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */

  margin-left: -50px;
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}

.profile-imgUploadBtn {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
  transition: 0.5s ease-in-out 0s;
}

.profile-imgUploadBtn:hover {
  cursor: pointer;
  background: #e9e9e9;
}
.cancel_profImgSec {
  width: 36px;
  height: 36px;

  /* background: rgba(54, 166, 164, 0.4);
  border: 2px solid #36a6a4; */
  backdrop-filter: blur(1.5px);

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
  transition: 0.2s ease-in-out 0s;
  cursor: pointer;
}

.cancel_profImgSec img {
  width: 12px;
  height: 12px;
}

.cancel_profImgSec:hover {
  transform: scale(1.1);
}

.profile-imgUploadBtn input {
  position: inherit;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
}

.profile-imgUploadBtn img {
  width: 16px;
  height: 14px;
}

.profileImg-btnFamGen {
  margin-top: 15px;
}

.selectProf-ImgBtn {
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 14px 11px; */

  width: 183px;
  height: 40px;

  /* primary/deets green */

  /* background: #36a6a4; */
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.selectProf-ImgBtn:hover {
  opacity: 0.8;
}
.selectProf-ImgBtn input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
}
.uploadProf-BtnFam {
  display: flex;
  align-items: center;
  justify-content: center;
}

.changeProf-btn {
  width: 130px;
  height: 40px;
  border: 1px solid #171923;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f2f2f2;
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  box-sizing: border-box;
  margin-right: 5px;
  margin-left: 10px;
  position: relative;
  transition: 0.2s ease-in-out 0s;
}

.changeProf-btn:hover {
  background: #e9e9e9;
  cursor: pointer;
}

.changeProf-btn input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.uploadPro-mainBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 183px;
  height: 40px;

  /* primary/deets green */

  background: #36a6a4;
  border-radius: 12px;

  box-sizing: border-box;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  margin-right: 10px;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.uploadPro-mainBtn:hover {
  background: #248381;
}

@media only screen and (max-width: 1400px) {
  .totalResProfBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResProfBody {
    max-width: 100%;
  }
}
