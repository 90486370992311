.save-cont-button {
  /* padding: 16px 5px; */
  height: 58px;
  width: 42%;
  display: flex;
  justify-content: center;
  /* margin-right: 10px; */
  align-items: center;

  font-family: "Poppins";

  font-style: normal;
  font-weight: 700;
  font-size: 0.9375rem;

  border: none;
  color: #171923;

  cursor: pointer;
  box-sizing: border-box;
  transition: 0.3s ease-in-out 0s;

  background: #ffffff;
  /* border: 1px solid #ffffff; */
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}

.saveConnects_btnV {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #36a6a4;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
  border-radius: 13px;
  font-family: Poppins;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 90%;
  height: 30px;

  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}
.saveConnects_btnV button {
  outline: none;
}

.saveConnects_btnV:hover {
  opacity: 0.8;
  /* border: 1.5px solid #d2d2d2; */
  border-radius: 10px;
}
.save-cont-button button {
  outline: none;
}
.save-cont-button:hover {
  background: #e9e9e9;
  /* border: 1.5px solid #d2d2d2; */
}

@media only screen and (max-width: 1400px) {
  .save-cont-button {
    width: 42%;
  }
}

@media only screen and (max-width: 600px) {
  .save-cont-button {
    width: 42%;
    height: 44px;
    font-size: 0.89rem;
    /* font-size: 0.9375rem; */
  }
}

@media only screen and (max-width: 428px) {
  .save-cont-button {
    width: 42%;
  }
}

@media only screen and (max-width: 340px) {
  .save-cont-button {
    width: 42%;
  }
}
