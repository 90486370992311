.adminError-genBody {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.7);
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}

.adminError-Content {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;

  background: #f2f2f2;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  left: 0;
  right: 0;
}

@-webkit-keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.adminErrorCont-Gen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.adminErrorheadText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #333333;
  width: 80%;
  word-wrap: break-word;
  margin-top: 10px;
  margin-bottom: 5px;
  text-align: center;
}

.adminErrorParaText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #333333;

  opacity: 0.6;
  width: 80%;
  word-wrap: break-word;
  /* margin-top: 10px; */
  margin-bottom: 5px;
  text-align: center;
}

.adminError_btn {
  display: flex;

  justify-content: center;
  align-items: center;

  width: 80%;
  height: 40px;

  background: #36a6a4;
  border-radius: 12px;
  margin-top: 50px;
  transition: 0.3s ease-in-out 0s;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  cursor: pointer;
}

.adminError_btn:hover {
  background: #248381;
}
@media only screen and (max-width: 1400px) {
  .adminError-genBody {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .adminError-genBody {
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* align-items: flex-end; */
    right: 0;
    top: 0;
    max-width: 100%;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background: #171923; */
    background: rgba(23, 25, 35, 0.7);
    /* opacity: 0.7; */
    /* backdrop-filter: blur(10px); */
  }

  .adminError-Content {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;

    background: #f2f2f2;
    /* padding: 10px; */
    /* z-index: 400000; */
    overflow-y: scroll;
    -webkit-animation-name: animatedown;
    -webkit-animation-duration: 0.4s;
    animation-name: animatedown;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
    left: 0;
    right: 0;
  }

  @-webkit-keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }
}
