.totalResBodyTeamViewConnectModal {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
  /* height: 100%; */
}

.view-Connectt-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: rgba(23, 25, 35, 0.2);
  backdrop-filter: blur(10px);
}

/* Modal Content */
.view-Connectt-modal-content {
  position: relative;
  /* display: flex;
    justify-content: center;
    align-items: center; */
  flex-direction: column;
  /* text-align: center; */
  /* background-color: #fefefe; */
  margin: auto;
  padding: 0;
  /* border: 1px solid #888; */
  /* width: 80%; */
  width: 70%;
  height: 70%;
  overflow-y: scroll;

  background: #ffffff;
  box-shadow: 4px 9px 20px 4px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.4s;
  animation-name: animateright;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  /* margin-top: 100px; */
}

/* Add Animation */
@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.view-Connectt-close_logout_icon {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3), 0px 6px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 7%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.view-Connectt-text-scan {
  margin-top: 10px;
}

.view-connectt-conection-headd {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 46px;

  background: #ffffff;

  border-bottom: 1px solid #cbd5e0;
  border-radius: 16px 16px 0px 0px;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* Text color */

  color: #333333;
}

.view-connectt-conection-namee {
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-left: 10px;

  color: #333333;

  opacity: 0.8;
}
.view-connectt-conection-posiii {
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  margin-left: 10px;

  color: #333333;

  opacity: 0.8;
}

.view-connectt-conection-compName {
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  margin-left: 10px;

  color: #333333;

  opacity: 0.8;
}

.view-connectt-conection-emailll {
  word-wrap: break-word;
  width: 90%;
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;

  color: #333333;

  opacity: 0.8;
  margin-left: 10px;
}

.view-connectt-conection-phonee-fam {
  margin-top: 10px;
  display: flex;

  align-items: center;
  width: 100%;
}
.view-connectt-conection-phonee-ooo {
  margin-left: 10px;
  font-style: normal;
  font-weight: 700;
  font-size: 11px;

  color: #333333;

  opacity: 0.3;
  width: 50%;
}

.view-connectt-conection-phonee-noo {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  width: 50%;

  color: #333333;

  opacity: 0.8;
}
.view-connectt-conection-pho-uuhh {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 20px;
}

.view-connectt-conection-pho-ooo {
  font-style: normal;
  font-weight: 700;
  font-size: 11px;

  color: #333333;
  width: 50%;
  margin-left: 10px;
  opacity: 0.9;
}
.view-connectt-conection-pho-noo {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  width: 50%;
  color: #333333;

  opacity: 0.6;
}

.view-connectt-conection-hghghg {
  padding: 5px;
  margin-top: 10px;
  width: 90%;
  margin-left: 10px;
  word-wrap: break-word;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #333333;

  opacity: 0.9;
  margin-bottom: 10px;
}
.view-connectt-conection-nnnnnnn {
  padding: 5px;
  margin-top: 10px;
  width: 90%;
  margin-left: 10px;
  word-wrap: break-word;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #333333;

  opacity: 0.9;

  border-top: 1px solid #cbd5e0;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  /* The Modal (background) */
  .view-Connectt-modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    /* padding-top: 100px;  */
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(23, 25, 35, 0.2);
    backdrop-filter: blur(10px);
  }

  /* Modal Content */
  .view-Connectt-modal-content {
    position: relative;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    flex-direction: column;
    /* text-align: center; */
    /* background-color: #fefefe; */
    margin: auto;
    padding: 0;
    /* border: 1px solid #888; */
    /* width: 80%; */
    width: 70%;
    height: 70%;
    overflow-y: scroll;

    background: #ffffff;
    box-shadow: 4px 9px 20px 4px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
    /* margin-top: 100px; */
  }

  /* Add Animation */
  @-webkit-keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  .view-Connectt-close_logout_icon {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3),
      0px 6px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 7%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .view-Connectt-text-scan {
    margin-top: 10px;
  }

  .view-connectt-conection-headd {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;

    background: #ffffff;

    border-bottom: 1px solid #cbd5e0;
    border-radius: 16px 16px 0px 0px;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* Text color */

    color: #333333;
  }

  .view-connectt-conection-namee {
    margin-top: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-left: 10px;

    color: #333333;

    opacity: 0.8;
  }
  .view-connectt-conection-posiii {
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    margin-left: 10px;

    color: #333333;

    opacity: 0.8;
  }

  .view-connectt-conection-compName {
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    margin-left: 10px;

    color: #333333;

    opacity: 0.8;
  }

  .view-connectt-conection-emailll {
    word-wrap: break-word;
    width: 90%;
    margin-top: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;

    color: #333333;

    opacity: 0.8;
    margin-left: 10px;
  }

  .view-connectt-conection-phonee-fam {
    margin-top: 10px;
    display: flex;

    align-items: center;
    width: 100%;
  }
  .view-connectt-conection-phonee-ooo {
    margin-left: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;

    color: #333333;

    opacity: 0.3;
    width: 50%;
  }

  .view-connectt-conection-phonee-noo {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    width: 50%;

    color: #333333;

    opacity: 0.8;
  }
  .view-connectt-conection-pho-uuhh {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
  }

  .view-connectt-conection-pho-ooo {
    font-style: normal;
    font-weight: 700;
    font-size: 11px;

    color: #333333;
    width: 50%;
    margin-left: 10px;
    opacity: 0.9;
  }
  .view-connectt-conection-pho-noo {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    width: 50%;
    color: #333333;

    opacity: 0.6;
  }

  .view-connectt-conection-hghghg {
    padding: 5px;
    margin-top: 10px;
    width: 90%;
    margin-left: 10px;
    word-wrap: break-word;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    color: #333333;

    opacity: 0.9;
    margin-bottom: 10px;
  }
  .view-connectt-conection-nnnnnnn {
    padding: 5px;
    margin-top: 10px;
    width: 90%;
    margin-left: 10px;
    word-wrap: break-word;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    color: #333333;

    opacity: 0.9;

    border-top: 1px solid #cbd5e0;
    margin-bottom: 10px;
  }
}
