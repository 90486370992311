@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

@media only screen and (max-width: 600px) {
  body {
    background: #e8e8e8;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
  .team-cards-view-header {
    width: 100vw;
    height: 56px;

    background: #304457;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }
  .team-cards-view-header img {
    margin-left: 15px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .team-cards-view-header > .team-cards-view-text img {
    margin-left: 0px;
  }
  .team-cards-search-fam {
    position: relative;
    width: 68%;
    align-items: center;
  }
  .search-team-icon {
    position: absolute;
    left: 0;
    margin-top: 10px;
    margin-left: 10px;
  }
  .team-cards-search-input input {
    width: 100%;

    height: 40px;

    /* White */

    background: #ffffff;
    outline: none;
    border: none;
    padding-left: 50px;
    padding-right: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;

    color: #333333;

    opacity: 0.7;
  }
  .team-cards-search-bar-cont {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
  .team-cards-search-filter {
    width: 13%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* White */

    background: #ffffff;
  }
  .dd-team-cards-filter-viewss {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    border-bottom: 1px solid #e9e9e9;

    height: 40px;
    background: #ffffff;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px 20px 0px 0px;
  }

  .dd-team-relaaa {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .dd-team-cards-filter-famm {
    position: absolute;
    top: 10%;
    margin: 0 auto;
    left: 0;
    right: 0;

    width: 80%;

    background: #ffffff;

    border-radius: 20px;
    z-index: 20;
  }
  .dd-team-cards-filter-allk {
    margin-left: 41%;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .dd-team-cards-filter-iconss {
    margin-right: 15px;
  }
  .dd-team-cards-filter-manag {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
    height: 40px;
    background: #ffffff;
    border-bottom: 1px solid #e9e9e9;

    color: #333333;

    opacity: 0.8;
  }

  .dd-team-cards-filter-rrrr {
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    /* padding-top: 100px;  */
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(23, 25, 35, 0.2);
    backdrop-filter: blur(10px);
  }
}
