/* .stats-each-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fafafa;
  height: 75px;
  width: 25%;

  border-radius: 10px;

  color: white;
  border-width: 3px;
  border-style: solid;
} */

.stats-each-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25%;
  height: 75px;
  /* margin: auto; */
  /* max-width: 22em; */
  position: relative;
  /* padding: 30% 2em; */
  box-sizing: border-box;
  color: #fff;
  background: #fafafa;
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 10px;
}

/* .stats-each-cont:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;
} */

@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.stats-number {
  /* padding: 5px; */
  display: flex;
  justify-content: center;
  align-items: center;

  /* Label */
  /* 
  background: #ffffff;

  border-radius: 10px; */

  font-weight: 600;
  font-size: 17px;

  color: #333333;
}

.stats-label {
  margin-top: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #333333;

  opacity: 0.7;
}

.statsImg img {
  width: 9px;
  height: 9px;
  /* margin-right: 10px; */
}

.statsnumbCont {
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 67px;
  height: 42px; */
}
@media only screen and (max-width: 600px) {
  /* .stats-each-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } */

  /* .stats-number {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;


    font-weight: 600;
    font-size: 14px;

    color: #333333;
  } */

  /* .stats-label {
    font-weight: 500;
    font-size: 12px;

    color: #333333;
  } */
}
