.no_connectionGen-Fam {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 85vh;
}

.no_connectionGenText {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  text-align: center;

  color: #333333;
  margin-bottom: 10px;
}

.no_connectionGen-FamImg {
  margin-bottom: 40px;
  margin-top: -100px;
}

.no_connectionTextFam {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #333333;
  width: 75%;
  word-wrap: break-word;
  text-align: center;
  opacity: 0.6;
}

.noConnectionBtn-home {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  width: 130px;
  height: 45px;
  border: 1px solid #171923;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in-out 0s;
}

.noConnectionBtn-home:hover {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
}
