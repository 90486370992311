.totalResBodyTeamCompInfo {
  max-width: 500px;
  margin: auto;
  position: relative;
}

.teamCompinfo-info-family {
  background: #f8fafc;
  /* padding-bottom: 100px; */
  height: 100vh;
  padding-bottom: 70px;
}

.phone-number-inputTempCompany {
  margin-top: 10px;
}

.phone-number-inputTempCompany {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.phone-number-inputTempCompany {
  position: relative;
  display: flex;
}

.remove-iconTempCompany {
  position: absolute;
  right: 14%;
  bottom: 8px;
  cursor: pointer;
}

.add-phone-familyTempCompany {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height */

  display: flex;
  justify-content: flex-end;

  color: #36a6a4;
  /* margin-right: 12%; */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.add-phone-contTempCompan {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 30px; */
  margin-top: 10px;
  cursor: pointer;
  margin-right: 5px;
}

.addCon_svgTempCompan {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.location-family-entireTempCompa {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact-location-familyTempCompa {
  margin-top: 20px;
}

.remove-iconTempCompany {
  right: 15%;
}

.inputFieldContact-seleTempCompa {
  position: relative;
  width: 100%;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}
.inputFieldContact-seleTempCompa select:focus {
  border: 1.5px solid #36a6a4;
}

.inputFieldContact-seleTempCompa select {
  /* width: 90%; */
  -webkit-appearance: none;
  border-radius: 16px;
  width: 90%;
  height: 52px;

  background: #f8fafc;
  /* DEETS GREEN */

  border: 1.5px solid #e2e8f0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  color: #64748b;
  padding-left: 20px;
  padding-right: 10px;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyTeamCompInfo {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  .remove-iconTempCompany {
    right: 15%;
  }

  .inputFieldContact-seleTempCompa {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyTeamCompInfo {
    max-width: 100%;
  }

  .remove-iconTempCompany {
    position: absolute;

    right: 50px;
    bottom: 10px;
  }
  .remove-iconTempCompany img {
    width: 19.5px;
    height: 19.5px;
  }

  .inputFieldContact-seleTempCompa {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .inputFieldContact-seleTempCompa select:focus {
    border-bottom: 2px solid #36a6a4;
  }

  .inputFieldContact-seleTempCompa select {
    width: 88.5%;
    -webkit-appearance: none;
    border-radius: 16px;
  }
}

@media only screen and (max-width: 500px) {
  .remove-iconTempCompany {
    right: 50px;
    bottom: 8px;
  }
}

@media only screen and (max-width: 453px) {
  .inputFieldContact-sele select {
    width: 90%;
  }
}

@media only screen and (max-width: 440px) {
  .inputFieldContact-sele select {
    width: 94%;
  }

  .inputFieldContact-seleTempCompa select {
    width: 90%;
  }
}

@media only screen and (max-width: 396px) {
  .inputFieldContact-sele select {
    width: 93%;
  }
  .inputFieldContact-seleTempCompa select {
    width: 93%;
  }
}

@media only screen and (max-width: 393px) {
  .remove-iconTempCompany {
    right: 40px;
    bottom: 8px;
  }
}

@media only screen and (max-width: 318px) {
  .inputFieldContact-sele select {
    width: 97%;
  }
}
