@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}

.thumbLinkModal-genBody {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.7);
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}

.thumbLinkModal-Content {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;

  background: #f2f2f2;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  left: 0;
  right: 0;

  /* border-radius: 14px 14px 0px 0px; */
  /* padding: 10px; */
}

@-webkit-keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.thumbNailHeader-CanFam {
  width: 100%;
  height: 56px;

  background: #171923;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  box-sizing: border-box;
}

/* .sectionHeader-CanFam:hover {
  color: #e9e9e9;
} */
.thumbNailHeader-CanFam-btn {
  cursor: pointer;
}

.thumbNailHeader-CanFam-btn:hover {
  color: #d1cdcd;
}

.totalResThumbBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.thumb-imgBodyGen {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  /* height: 100vh; */
  overflow-x: hidden;
  /* padding-bottom: 100px; */
}

.thumb-imgFrame {
  margin-left: auto;
  margin-right: auto;
  width: 169px;
  height: 161px;

  background: #f2f2f2;
  border-radius: 15px;
  border: 4px solid #36a6a4;
  position: relative;
  margin-top: 30px;
}

.thumb-uploadedImgPre {
  width: 100%;
  height: 100%;
}

.thumb-uploadedImgPre img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

.thumb-uploadedImgText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 100px;
  height: 100px;

  color: #333333;

  opacity: 0.6;

  position: absolute;
  top: 50%;
  left: 50%;
  /* -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); */

  margin-left: -50px;
  margin-top: -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000;
}

.thumb-imgUploadBtn {
  width: 35px;
  height: 35px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px);

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
  transition: 0.5s ease-in-out 0s;
}

.thumb-imgUploadBtn:hover {
  cursor: pointer;
  background: #e9e9e9;
}
.cancelthumb_profImgSec {
  width: 36px;
  height: 36px;

  background: rgba(54, 166, 164, 0.4);
  border: 2px solid #36a6a4;
  backdrop-filter: blur(1.5px);

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
  transition: 0.2s ease-in-out 0s;
  cursor: pointer;
}

.cancelthumb_profImgSec img {
  width: 12px;
  height: 12px;
}

.cancelthumb_profImgSec:hover {
  transform: scale(1.1);
}

.thumb-imgUploadBtn input {
  position: inherit;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
}

.thumb-imgUploadBtn img {
  width: 16px;
  height: 14px;
}

.thumbImg-btnFamGen {
  margin-top: 15px;
}

.selectProfThumb-ImgBtn {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding: 14px 11px; */

  width: 183px;
  height: 40px;

  /* primary/deets green */

  background: #36a6a4;
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.selectProfThumb-ImgBtn:hover {
  background: #248381;
}
.selectProfThumb-ImgBtn input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
}
.uploadThumbNail-BtnFam {
  display: flex;
  align-items: center;
  justify-content: center;
}

.changeThumbNail-btn {
  width: 130px;
  height: 40px;
  border: 1px solid #171923;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f2f2f2;
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  box-sizing: border-box;
  margin-right: 5px;
  margin-left: 10px;
  position: relative;
  transition: 0.2s ease-in-out 0s;
}

.changeThumbNail-btn:hover {
  background: #e9e9e9;
  cursor: pointer;
}

.changeThumbNail-btn input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.uploadThumbNaill-mainBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 183px;
  height: 40px;

  /* primary/deets green */

  background: #36a6a4;
  border-radius: 12px;

  box-sizing: border-box;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  margin-right: 10px;
  margin-left: 5px;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.uploadThumbNaill-mainBtn:hover {
  background: #248381;
}

@media only screen and (max-width: 1400px) {
  .thumbLinkModal-genBody {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .thumbLinkModal-genBody {
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* align-items: flex-end; */
    right: 0;
    top: 0;
    width: 100%; /* Full width */
    max-width: 100%;
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background: #171923; */
    background: rgba(23, 25, 35, 0.7);
    /* opacity: 0.7; */
    /* backdrop-filter: blur(10px); */
  }

  .thumbLinkModal-Content {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;

    background: #f2f2f2;
    /* padding: 10px; */
    /* z-index: 400000; */
    overflow-y: scroll;
    -webkit-animation-name: animatedown;
    -webkit-animation-duration: 0.4s;
    animation-name: animatedown;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
    left: 0;
    right: 0;

    /* border-radius: 14px 14px 0px 0px; */
    /* padding: 10px; */
  }

  @-webkit-keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }
}
