.admin_switchBtn_parent {
  display: flex;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 20px;
}

.admin_switchBtn_text {
  color: #333;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 10px;
}
