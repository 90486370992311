.totalResBodySupport {
  max-width: 500px;
  margin: auto;
  position: relative;
}

.support-family {
  background: #f2f2f2;
  padding-bottom: 100px;
  height: 100vh;
}
.support-info-header {
  width: 100%;
  height: 56px;

  background: #171923;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  margin-bottom: 20px;
  overflow-x: hidden;
}
/* .support-info-header img {
    margin-left: 30px;
  } */

/* .backdrop-header img {
  margin-right: 100px;
} */

.support-info-header > .support-info-text {
  margin-left: -30px;
}

.support_text_heading {
  width: 100%;
  height: 31px;

  /* Label */

  background: #e9e9e9;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  /* identical to box height */

  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
}

.share-flex-cont {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}
.whatsapp-share-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;

  color: #333333;

  width: 130px;
  height: 120px;

  background: #ffffff;
  box-shadow: -4px -3px 8px #ffffff, 4px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.whatsapp-share-cont img {
  margin-top: 10px;
}

.mail-share-cont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #333333;

  width: 130px;
  height: 120px;

  background: #ffffff;
  box-shadow: -4px -3px 8px #ffffff, 4px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
}
.mail-share-cont img {
  margin-top: 10px;
}
.support-form-cont {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
  padding-top: 30px;
}

.support-submitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 49px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  /* background: #36a6a4; */
  border-radius: 12px;

  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  transition: 0.5s ease-in-out 0s;
}

.support-submitBtn:hover {
  opacity: 0.8;
}

.support-save-form {
  /* position: absolute; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  /* margin-bottom: 50px; */
}
.support_form_header {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;

  color: #838383;
  width: 90%;
}

.support_btn-save {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.support_btn-save input {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 15px 10px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 90%;
  height: 49px;

  background: #36a6a4;
  /* opacity: 0.3; */
  border-radius: 12px;
  box-sizing: border-box;
}
@media only screen and (max-width: 1400px) {
  .totalResBodySupport {
    max-width: 500px;
  }
}
@media only screen and (max-width: 600px) {
  .totalResBodySupport {
    max-width: 100%;
  }
  .support-family {
    background: #f2f2f2;
    padding-bottom: 100px;
  }
  .support-info-header {
    width: 100vw;
    height: 56px;

    background: #171923;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    margin-bottom: 20px;
    overflow-x: hidden;
  }
  /* .support-info-header img {
    margin-left: 30px;
  } */

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .support-save-form {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    /* bottom: 30px; */

    margin-top: 50px;
    /* margin-bottom: 50px; */
  }

  .support-save-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 10px 15px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 90%;
    height: 49px;

    background: #36a6a4;
    /* opacity: 0.3; */
    border-radius: 12px;
    box-sizing: border-box;
  }
  .support-info-header > .support-info-text {
    margin-left: -30px;
  }

  .support_text_heading {
    width: 100%;
    height: 31px;

    /* Label */

    background: #e9e9e9;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
  }

  .share-flex-cont {
    display: flex;
    justify-content: space-evenly;
    margin-top: 50px;
  }
  .whatsapp-share-cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.02em;

    color: #333333;

    width: 130px;
    height: 120px;

    background: #ffffff;
    box-shadow: -4px -3px 8px #ffffff, 4px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
  }
  .whatsapp-share-cont img {
    margin-top: 10px;
  }

  .mail-share-cont {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;

    color: #333333;

    width: 130px;
    height: 120px;

    background: #ffffff;
    box-shadow: -4px -3px 8px #ffffff, 4px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 3px;
  }
  .mail-share-cont img {
    margin-top: 10px;
  }
}
