@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.singleConnectModal-genBody {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.7);
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}

.singleConnectModal-Content {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;

  background: #f2f2f2;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  left: 0;
  right: 0;

  border-radius: 0px;
  /* padding: 10px; */
}

@-webkit-keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.body-for-mobile {
  background: #f2f2f2;
  height: 100%;
}

.totalProfileHomePage {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.singleConnectModal-info-header {
  width: 100%;
  height: 56px;
  background: #171923;
  border-bottom: 1px solid rgba(51, 51, 51, 0.06);

  /* identical to box height, or 157% */

  /* Text color */

  /* padding-left: 30px; */
  /* padding-right: 30px; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  overflow-x: hidden;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #f2f2f2;
}

.singleConnectModal-info-header img {
  margin-left: 30px;
  cursor: pointer;
}

.singleConnectModalarrowImg-cont {
  transition: 0.5s ease-in-out 0s;
}

.singleConnectModalarrowImg-cont:hover {
  transform: translateX(-5px);
  cursor: pointer;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.singleConnectModal-info-header > .singleConnectModal-info-text {
  margin-left: -30px;
}

.singleConnectModal-navbar-cont {
  background: #171923;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.singleConnectModal-nav-logo {
  margin-left: 20px;
}
.get-card-cont {
  background: #31333a;
  border-radius: 9px;
  width: 135px;
  height: 32px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}

.get-card-cont:hover {
  background: #2b2d34;
  color: #d1d1d1;
}
.singleConnectModal-pics-cont {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 320px;

  background: #171923;
  border-radius: 0px 0px 40px 0px;
  padding-top: 10px;
  margin-top: -1px;
}
.containertt {
  background: #ffffff;
}
.singleConnectModalbackdrop-cont {
  position: absolute;
  margin-top: 0px;

  top: 5px;
  box-sizing: border-box;

  width: 95%;
  height: 220px;

  background: #ffffff;
  border-radius: 0px 0px 35px 35px;
  overflow: hidden;
}
.user-singleConnectModal-pics {
  overflow: hidden;
  position: relative;

  width: 210px;
  height: 210px;

  background: #fbffff;
  border: 4px solid #36a6a4;
  backdrop-filter: blur(1.5px);

  border-radius: 15px;
  top: -30px;

  font-style: normal;
  font-weight: 600;
  font-size: 60px;

  color: #171923;

  display: flex;
  align-items: center;
  justify-content: center;
}

/* .singleConnectModal-uploaded-img {
  width: 161px;
  height: 169px;
} */

.singleConnectModal-uploaded-img img {
  margin-top: 16px;
  height: 100%;
  width: 100%;
}

.singleConnectModalcont-all-avatar {
  position: relative;
}

.singleConnectModal-img-main {
  width: 100%;
  height: 100%;
}
.user-singleConnectModal-details {
  position: absolute;
  bottom: 5px;
  /* left: 0; */
  text-align: center;
}
.singleConnectModal-user-name {
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;

  color: #ffffff;
}
.singleConnectModal-user-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  /* Label */

  color: #e9e9e9;
}
/* .body-div {
    padding-left: 10px;
    padding-right: 10px;
  } */

.singleConnectModal-con-cont {
  display: flex;
  justify-content: center;

  margin-top: 20px;
}
.view-singleConnectModal-button {
  /* padding: 16px 11px; */
  /* padding: 16px 5px; */
  height: 48px;
  width: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;

  /* primary/deets green */

  background: #36a6a4;
  box-sizing: border-box;
  border-radius: 12px;
  transition: 0.3s ease-in-out 0s;
  cursor: pointer;
}
.view-singleConnectModal-button:hover {
  background: #248381;
}

.exchange-cont-button {
  height: 48px;
  width: 240px;
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  border-radius: 12px;
  border: 1.5px solid #171923;
  color: #171923;

  background: #f2f2f2;
  cursor: pointer;
  box-sizing: border-box;
  transition: 0.3s ease-in-out 0s;
}

.exchange-cont-button:hover {
  background: #e9e9e9;
  border: 1.5px solid #d2d2d2;
}
.about-singleConnectModal-cont {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* Label */

  /* background: #e9e9e9; */
}
.about-singleConnectModal {
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* Text color */

  color: #333333;
}
.about-singleConnectModal-family {
  margin-top: 10px;
}
.about-singleConnectModal-cont {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-left: 20px;
  margin-right: 20px;
  /* or 175% */

  display: flex;
  align-items: center;
  text-align: justify;

  /* Text color */

  color: #333333;
}

.contact-singleConnectModal-cont {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* Label */

  /* background: #e9e9e9; */
}
.contact-singleConnectModal {
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* Text color */

  color: #333333;
}

.singleConnectModal-phone-cont {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 20px;
  margin-right: 20px;
  transition: 0.3s ease-in-out 0s;
}
.singleConnectModalphone-first-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out 0s;
}

.singleConnectarrow-forwardIcon {
  margin-left: -5px;
}

.singleConnectModal-phone-cont:hover .singleConnectarrow-forwardIcon img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}

.singleConnectModalphone-numb {
  font-style: normal;
  text-decoration: none;
  margin-left: 20px;
  font-weight: 600;
  font-size: 17px;
  /* Text color */

  color: #333333;
  /* margin-right: 20px; */
  line-height: 22px;
  letter-spacing: 0.0075em;
  margin-right: 10px;
  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.singleConnectModalphone-icon-s {
  margin-top: 5px;
}

.singleConnectModalemail-cont {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 20px;
  margin-right: 20px;
  transition: 0.3s ease-in-out 0s;
}

.singleConnectModalemail-cont:hover .singleConnectarrow-forwardIcon img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}

.singleConnectModalemail-address {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  /* Text color */
  margin-left: 20px;
  color: #333333;
  /* margin-right: 20px; */
  /* margin-right: 220px; */
  /* margin-left: 70px; */
  line-height: 22px;
  letter-spacing: 0.0075em;
  margin-right: 10px;

  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.singleConnectModalemail-icon-s {
  margin-top: 5px;
}
.singleConnectModallocation-cont {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 20px;
  margin-right: 20px;
  transition: 0.3s ease-in-out 0s;
}

.singleConnectModallocation-cont:hover .singleConnectarrow-forwardIcon img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}

.singleConnectModallocation-address {
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
  margin-right: 10px;
  font-size: 17px;
  /* Text color */

  color: #333333;

  line-height: 22px;
  letter-spacing: 0.0075em;
  width: 400px;
  overflow: hidden;
}

.singleConnectsocial-header-cont {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* Label */

  /* background: #e9e9e9; */
}
.singleConnectsocial-header {
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* Text color */

  color: #333333;
}
.singleConnectsocial-media-group {
  display: flex;
  justify-content: center;

  flex-wrap: wrap;
  margin-right: 20px;
  margin-left: 20px;
}
.singleConnectsocial-media-icon {
  position: relative;
  width: 95px;
  height: 95px;
  display: flex;
  margin-right: 15px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: #ffffff;
  box-shadow: 0px 1.69681px 5.09043px rgba(0, 0, 0, 0.1),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
  border-radius: 6px;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: 0.01em;

  color: #57c2a2;
  transition: 0.3s ease-in-out 0s;
}

.singleConnectsocial-media-icon:hover {
  box-shadow: none;
}
.singleConnectsocial-media-tag {
  position: absolute;
  bottom: 2px;
}

.singleConnectvideo-header-cont {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* Label */

  /* background: #e9e9e9; */
}
.singleConnectvideo-header {
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* Text color */

  color: #333333;
}
.singleConnectvideo-group {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

.singleConnectlinks-header-cont {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  /* Label */

  /* background: #e9e9e9; */
}
.singleConnectlinks-header {
  margin-left: 20px;
  margin-top: auto;
  margin-bottom: auto;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* Text color */

  color: #333333;
}

.singleConnectlinks-cont {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-left: 20px;
  margin-right: 20px;
  transition: 0.3s ease-in-out 0s;
}

.singleConnectgenLinksDiv:hover .singleConnectarrow-forwardIcon img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}

.singleConnectlinks-first-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.singleConnectmain-links {
  font-style: normal;
  margin-left: 10px;
  margin-right: 10px;
  font-weight: 600;
  font-size: 17px;
  /* Text color */
  /* overflow-wrap: break-word; */
  color: #333333;
  /* margin-right: 20px; */
  line-height: 22px;
  letter-spacing: 0.0075em;
  word-wrap: break-word;
  /* width: 60%; */
  white-space: nowrap;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.singleConnectlinks-icon-s {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 5px; */
  width: 42px;
  height: 42px;

  /* Primary/light */

  background: #f5f5f5;
  border-radius: 10px;
}

.singleConnectlinks-group {
  margin-bottom: 50px;
}
/* Desktop view error */

@media only screen and (max-width: 1400px) {
  .singleConnectModal-genBody {
    max-width: 500px;
  }

  .totalProfileHomePage {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  .singleConnectModalbackdrop-cont {
    height: 200px;
  }

  div.singleConnectmain-links {
    width: 400px;
  }

  .view-singleConnectModal-button {
    width: 250px;
  }

  .exchange-cont-button {
    width: 150px;
  }

  .singleConnectModal-pics-cont {
    height: 290px;
  }

  .user-singleConnectModal-pics {
    top: -35px;
  }
}

@media only screen and (max-width: 600px) {
  .singleConnectModal-genBody {
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* align-items: flex-end; */
    right: 0;
    top: 0;
    max-width: 100%;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background: #171923; */
    background: rgba(23, 25, 35, 0.7);
    /* opacity: 0.7; */
    /* backdrop-filter: blur(10px); */
  }

  .singleConnectModal-Content {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;

    background: #f2f2f2;
    /* padding: 10px; */
    /* z-index: 400000; */
    overflow-y: scroll;
    -webkit-animation-name: animatedown;
    -webkit-animation-duration: 0.4s;
    animation-name: animatedown;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
    left: 0;
    right: 0;

    border-radius: 0px;
    /* padding: 10px; */
  }

  @-webkit-keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  .totalProfileHomePage {
    max-width: 100%;
  }

  .singleConnectModal-info-family {
    background: #f8f8f8;
    padding-bottom: 100px;
  }
  .singleConnectModal-info-header {
    width: 100vw;
    height: 56px;
    background: #171923;
    border-bottom: 1px solid rgba(51, 51, 51, 0.06);

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }
  .singleConnectModal-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .singleConnectModal-info-header > .singleConnectModal-info-text {
    margin-left: -40px;
  }

  .singleConnectModal-navbar-cont {
    background: #171923;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .singleConnectModal-nav-logo {
    margin-left: 20px;
  }
  .get-card-cont {
    background: #31333a;

    border-radius: 9px;
    width: 135px;
    height: 32px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #ffffff;
  }
  .singleConnectModal-pics-cont {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 245px;

    background: #171923;
    border-radius: 0px 0px 40px 0px;
    padding-top: 10px;
    margin-top: -1px;
  }
  .containertt {
    background: #ffffff;
  }
  .singleConnectModalbackdrop-cont {
    position: absolute;
    margin-top: 0px;

    top: 5px;
    box-sizing: border-box;

    width: 95%;
    height: 150px;

    background: #ffffff;
    border-radius: 0px 0px 35px 35px;
    overflow: hidden;
  }
  .user-singleConnectModal-pics {
    overflow: hidden;
    position: relative;

    width: 161px;
    height: 169px;

    background: #fbffff;
    border: 4px solid #36a6a4;
    backdrop-filter: blur(1.5px);

    border-radius: 15px;
    top: -35px;

    font-style: normal;
    font-weight: 600;
    font-size: 60px;

    color: #171923;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .singleConnectModal-uploaded-img {
    width: 161px;
    height: 169px;
  } */

  .singleConnectModal-uploaded-img img {
    margin-top: 16px;
    height: 100%;
    width: 100%;
  }

  .singleConnectModalcont-all-avatar {
    position: relative;
  }

  .singleConnectModal-img-main {
    width: 100%;
    height: 100%;
  }
  .user-singleConnectModal-details {
    position: absolute;
    bottom: 5px;
    /* left: 0; */
    text-align: center;
  }
  .singleConnectModal-user-name {
    margin-top: 10px;

    font-style: normal;
    font-weight: 600;
    font-size: 28px;

    color: #ffffff;
  }
  .singleConnectModal-user-title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    color: #e9e9e9;
  }
  /* .body-div {
    padding-left: 10px;
    padding-right: 10px;
  } */

  .singleConnectModal-con-cont {
    display: flex;
    justify-content: center;

    margin-top: 20px;
  }

  .view-singleConnectModal-button {
    width: 230px;
  }

  .exchange-cont-button {
    width: 150px;
  }

  .about-singleConnectModal-cont {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .about-singleConnectModal {
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* Text color */

    color: #333333;
  }
  .about-singleConnectModal-family {
    margin-top: 10px;
  }
  .about-singleConnectModal-cont {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-left: 20px;
    margin-right: 20px;

    display: flex;
    align-items: center;
    text-align: justify;

    color: #333333;
  }

  .contact-singleConnectModal-cont {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  .contact-singleConnectModal {
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    color: #333333;
  }

  .singleConnectModal-phone-cont {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: 20px;
    margin-right: 20px;
  }
  .singleConnectModalphone-first-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .singleConnectModalphone-icon-s {
    margin-top: 5px;
  }

  .singleConnectModalemail-cont {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: 20px;
    margin-right: 20px;
  }

  .singleConnectModalemail-icon-s {
    margin-top: 5px;
  }
  .singleConnectModallocation-cont {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: 20px;
    margin-right: 20px;
  }

  .singleConnectsocial-header-cont {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    /* Label */

    /* background: #e9e9e9; */
  }
  .singleConnectsocial-header {
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* Text color */

    color: #333333;
  }
  .singleConnectsocial-media-group {
    display: flex;
    justify-content: center;

    flex-wrap: wrap;
    margin-right: 20px;
    margin-left: 20px;
  }
  .singleConnectsocial-media-icon {
    position: relative;
    width: 95px;
    height: 95px;
    display: flex;
    margin-right: 15px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: #ffffff;
    box-shadow: 0px 1.69681px 5.09043px rgba(0, 0, 0, 0.1),
      0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
    border-radius: 6px;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    /* identical to box height, or 183% */

    letter-spacing: 0.01em;

    color: #57c2a2;
  }

  .singleConnectsocial-media-tag {
    position: absolute;
    bottom: 2px;
  }

  .singleConnectvideo-header-cont {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    /* Label */

    /* background: #e9e9e9; */
  }
  .singleConnectvideo-header {
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* Text color */

    color: #333333;
  }
  .singleConnectvideo-group {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .singleConnectlinks-header-cont {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    margin-top: 20px;
    /* Label */

    /* background: #e9e9e9; */
  }
  .singleConnectlinks-header {
    margin-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    /* Text color */

    color: #333333;
  }

  .singleConnectlinks-cont {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-left: 20px;
    margin-right: 20px;
  }
  .singleConnectlinks-first-flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .singleConnectlinks-icon-s {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 5px; */
    width: 42px;
    height: 42px;

    /* Primary/light */

    background: #f5f5f5;
    border-radius: 10px;
  }

  .singleConnectlinks-group {
    margin-bottom: 50px;
  }
  /* Desktop view error */

  .modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    /* padding-top: 100px;  */
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(23, 25, 35, 0.2);
    backdrop-filter: blur(10px);
  }

  /* Modal Content */
  .modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    /* background-color: #fefefe; */
    margin: auto;
    padding: 0;
    /* border: 1px solid #888; */
    width: 90%;

    /* width: 267px; */
    height: 232px;

    background: #ffffff;

    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out; */
    border-radius: 12px;
  }

  .close_logout_icon {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3),
      0px 6px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -50px;
  }

  div.singleConnectmain-links {
    width: 400px;
  }
  .singleConnectModalphone-numb {
    width: 400px;
  }

  .singleConnectModalemail-address {
    width: 400px;
  }

  .singleConnectModallocation-address {
    width: 400px;
  }
}

@media only screen and (max-width: 512px) {
  div.singleConnectmain-links {
    width: 350px;
  }

  .singleConnectModalphone-numb {
    width: 350px;
  }

  .singleConnectModalemail-address {
    width: 350px;
  }

  .singleConnectModallocation-address {
    width: 350px;
  }
}

@media only screen and (max-width: 451px) {
  div.singleConnectmain-links {
    width: 250px;
  }

  .singleConnectModalphone-numb {
    width: 250px;
  }

  .singleConnectModalemail-address {
    width: 250px;
  }

  .singleConnectModallocation-address {
    width: 250px;
  }
}

@media only screen and (max-width: 428px) {
  .view-singleConnectModal-button {
    width: 183px;
  }
  .exchange-cont-button {
    width: 140px;
  }
}

@media only screen and (max-width: 365px) {
  div.singleConnectmain-links {
    width: 180px;
  }

  .singleConnectModalphone-numb {
    width: 180px;
  }

  .singleConnectModalemail-address {
    width: 180px;
  }

  .singleConnectModallocation-address {
    width: 180px;
  }
}

@media only screen and (max-width: 340px) {
  .view-singleConnectModal-button {
    width: 153px;
  }
  .exchange-cont-button {
    width: 120px;
  }
}

@media only screen and (max-width: 301px) {
  .singleConnectModal-user-name {
    font-size: 20px;
  }
  .singleConnectModal-user-title {
    font-size: 10px;
  }
}

@media only screen and (max-width: 275px) {
  div.singleConnectmain-links {
    width: 120px;
  }

  .singleConnectModalphone-numb {
    width: 120px;
  }

  .singleConnectModalemail-address {
    width: 120px;
  }

  .singleConnectModallocation-address {
    width: 120px;
  }
}

@media only screen and (max-width: 213px) {
  div.singleConnectmain-links {
    width: 80px;
  }

  .singleConnectModalphone-numb {
    width: 80px;
  }

  .singleConnectModalemail-address {
    width: 80px;
  }

  .singleConnectModallocation-address {
    width: 80px;
  }
}

@media only screen and (max-width: 170px) {
  div.singleConnectmain-links {
    width: 50px;
  }

  .singleConnectModalphone-numb {
    width: 50px;
  }

  .singleConnectModalemail-address {
    width: 50px;
  }

  .singleConnectModallocation-address {
    width: 50px;
  }
}
