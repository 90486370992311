/* The Modal (background) */

/* .totalResBodySuccessPage {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  background: #f2f2f2;
} */
.success-solo-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%;
  max-width: 500px; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

  /* background: rgba(23, 25, 35, 0.8); */

  background: #171923;
  opacity: 0.95;
  backdrop-filter: blur(20px);
}

/* Modal Content */
.success-solo-content {
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  text-align: center;
  /* background-color: #fefefe; */
  /* margin: auto; */
  padding: 0;
  /* border: 1px solid #888; */
  width: 90%;

  /* width: 267px; */
  height: 90%;

  background: transparent;

  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.4s;
  animation-name: animateright;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  border-radius: 12px;
}

/* Add Animation */
@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

/* .success-solo-body {
  padding: 2px 16px;
} */

.success-solo-text-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
  color: #333333;

  display: flex;
  justify-content: center;
  align-items: center;
}

.success-solo-text-2 img {
  width: 117px;
  height: 115px;
}

.success-solo-fulltext {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 30px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #f2f2f2;
}
.success-solo-continue-btn {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  width: 140px;

  background: transparent;
  border-radius: 14px;
  margin-top: 30px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  border: 1px solid #f2f2f2;

  color: #f2f2f2;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1400px) {
  .success-solo-modal {
    max-width: 500px;
  }
  /* .totalResBodySuccessPage {
    max-width: 500px;
  } */
}

@media only screen and (max-width: 600px) {
  .success-solo-modal {
    max-width: 100%;
  }

  /* .totalResBodySuccessPage {
    max-width: 100%;
  } */
}
