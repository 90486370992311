@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyTeamTempProf {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative;
  height: 100vh; */
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hidden;
  /* overflow-y: auto; */
}

.personalInfo-temp-info-header {
  width: 100%;
  height: 56px;

  background: #f8f8f8;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* margin-bottom: 20px; */
  overflow-x: hidden;
}
.personalInfo-temp-info-header img {
  margin-left: 30px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.personalInfo-temp-info-header > .personalInfo-temp-info-text {
  margin-left: -50px;
}

.personalInfo-temp-head-text {
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b2b2b2;

  /* Label */

  background: #f5f5f5;

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}

.personalInfo-temp-form-fam {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 90%;
  height: 50px;

  background: #ffffff;
  border-bottom: 1px solid #cbd5e0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.personalInfo-temp-label {
  margin-left: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #333333;
}

.personalInfo-temp-tick {
  margin-right: 10px;
}

.personalInfo-temp-link-dd {
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  height: 37px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;

  justify-content: center;
  align-items: center;

  /* Text color */

  color: #333333;

  /* Label */

  background: #e9e9e9;
}

.save-profile-temp-info {
  /* position: absolute; */
  display: flex;
  align-items: center;
  /* width: 100vw; */
  justify-content: center;

  margin-bottom: 30px;
}
.save-profile-temp-info button {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  width: 90%;

  height: 49px;
  margin-top: 20px;

  /* background: #fcb816; */
  /* opacity: 0.3; */
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hidden;
    /* overflow-y: auto; */
  }

  .personalInfo-temp-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }
  .personalInfo-temp-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .personalInfo-temp-info-header > .personalInfo-temp-info-text {
    margin-left: -50px;
  }

  .personalInfo-temp-head-text {
    margin-top: 5px;
    margin-bottom: 20px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b2b2b2;

    /* Label */

    background: #f5f5f5;

    font-style: normal;
    font-weight: 300;
    font-size: 12px;
  }

  .personalInfo-temp-form-fam {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 90%;
    height: 50px;

    background: #ffffff;
    border-bottom: 1px solid #cbd5e0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;
  }

  .personalInfo-temp-label {
    margin-left: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    color: #333333;
  }

  .personalInfo-temp-tick {
    margin-right: 10px;
  }

  .personalInfo-temp-link-dd {
    margin-top: 10px;
    margin-bottom: 20px;
    width: 100%;
    height: 37px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    display: flex;

    justify-content: center;
    align-items: center;

    /* Text color */

    color: #333333;

    /* Label */

    background: #e9e9e9;
  }

  .save-profile-temp-info {
    /* position: absolute; */
    display: flex;
    align-items: center;
    /* width: 100vw; */
    justify-content: center;

    margin-bottom: 30px;
  }
  .save-profile-temp-info button {
    border: none;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #ffffff;
    width: 90%;

    height: 49px;
    margin-top: 20px;

    /* background: #fcb816; */
    /* opacity: 0.3; */
    border-radius: 6px;
  }
}
