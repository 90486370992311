/* Extra small devices (phones, 600px and down) */
@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyTeamMemberPer {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #e8e8e8;
  overflow-x: hiddens;
}
.team-profile-pics-cont {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 330px;

  background: #304457;
  border-radius: 0px 0px 40px 0px;
  padding-top: 10px;
  margin-top: -1px;
}
.team-containertt {
  background: #ffffff;
}
.team-backdrop-cont {
  position: absolute;
  margin-top: 0px;

  top: 5px;
  box-sizing: border-box;

  width: 95%;
  height: 250px;

  background: #ffffff;
  border-radius: 0px 0px 35px 35px;
  overflow: hidden;
}
.team-user-profile-pics {
  overflow: hidden;
  position: relative;

  top: 50px;
  margin-left: 340px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #304457;

  width: 87px;
  height: 87px;

  background: #304457;
  border: 2px solid #f2f2f2;
  border-radius: 50%;
}
.team-edit-profile-pics-family {
  position: absolute;
  /* top: 38px; */
  /* left: 258px; */
  top: 108px;
  right: -110px;
}

.team-deets-admin-logo {
  margin-left: 25px;
}

.team-uploaded-img img {
  margin-top: 16px;
  height: 100%;
  width: 100%;
}

.team-cont-all-avatar {
  position: relative;
  width: 320px;
  height: 90px;
  margin-right: 20px;
  /* padding-left: 100px; */
}
.members-navbar-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  height: 50px;

  background: #304457;
  /* position: fixed; */
}

.team-shareicon-cont {
  width: 30px;
  height: 30px;
  background: #171923;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  opacity: 0.7;
}

.team-share-iii {
  display: flex;
  justify-content: center;
  align-items: center;
}
.team-edit-backdrop-fam-c {
  position: absolute;
  height: 35px;
  top: 50px;
  right: 170px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(6px);
  /* Note: backdrop-filter has minimal browser support */
}
.team-edit-blackpencont {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.team-backdrop-img-main {
  width: 100%;
  height: 100%;
}
.team-user-profile-details {
  position: absolute;
  bottom: 5px;
  /* left: 0; */
  text-align: center;
}

.team-edit-profile-pics-icon {
  position: relative;

  width: 35px;
  height: 35px;

  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(6px);

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.edit-profile-pics-icon input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}
.user-profile-pics-text {
  margin-top: 10px;
}

.team-user-name {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;

  /* identical to box height, or 107% */

  color: #ffffff;
}
.team-user-title {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  /* Label */

  color: #c4c8ca;
}
.team-body-div {
  padding-left: 10px;
  padding-right: 10px;
}
.team-prof-con-cont {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;
}
.team-profile-button {
  /* padding: 16px 11px; */
  padding: 16px 5px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";

  font-style: normal;
  font-weight: 500;
  margin-right: 20px;
  font-size: 13px;

  /* Text color */

  color: #333333;

  /* height: 49px; */
  /* left: 189px;
top: 327px; */

  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #333333;
  box-sizing: border-box;
}
.team-profile-button button {
  outline: none;
}
.team-connect-button {
  padding: 16px 5px;
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  /* Text color */

  color: #ffffff;

  border-radius: 5px;
  border: 0;
  outline: none;
}

/* .team-connect-button button {
    border: 1px;
    outline: none;

  } */
.team-about-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}
.team-edit-teamEditPenicon {
  margin-right: 20px;
  height: 35px;
  width: 35px;
  background: #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.team-about-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
.team-about-user-text {
  padding-left: 10px;
  padding-right: 10px;
}
.team-about-placeholder {
  height: 130px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}

.unlocked-icon {
  margin-right: 30px;
}
.unlocked-icon-fam {
  display: flex;
  align-items: center;
}
.team-contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}
.team-contact-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
.team-contact-user-text {
  padding-left: 10px;
  padding-right: 10px;
}
.team-contact-placeholder {
  height: 50px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}
.team-call-update-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  /* position: relative; */
}
.team-call-icon-r {
  margin-left: 10px;
  margin-right: 30px;
  margin-top: 10px;
}
.team-forward-icon-r {
  margin-right: 30px;
}
.team-call-inside-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-email-admin-cont {
  width: 100px;
  height: 22px;
  /* padding-right: 20px; */
}
.team-address-admin {
  width: 214px;
  height: 66px;
}
.team-social-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}
.team-social-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
.team-social-user-text {
  padding-left: 10px;
  padding-right: 10px;
}
.team-social-placeholder {
  height: 50px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}

.team-snapchat-entry {
  width: 95px;
  height: 95px;

  background: #ffffff;
  box-shadow: 0px 1.69681px 5.09043px rgba(0, 0, 0, 0.1),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin: 5px;
}

.team-social-snap-chat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-social-snap-chat-icon img {
  margin-top: 30px;
}
.team-snap-chat-tag {
  margin-left: 5px;
  width: 34px;
  height: 20px;

  /* primary/deets green */

  background: #ebf6f6;
  opacity: 0.1;
  border-radius: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  /* identical to box height */

  display: flex;

  justify-content: center;
  align-items: center;
  /* primary/deets green */

  color: #86c9c8;

  opacity: 0.89;
}

.team-snap-chat-tag-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-social-media-big-entry {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.team-video-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}
.team-video-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
.team-video-user-text {
  padding-left: 10px;
  padding-right: 10px;
}
.team-video-placeholder {
  height: 50px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}

.team-links-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}
.team-links-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
.team-links-user-text {
  padding-left: 10px;
  padding-right: 10px;
}
.team-links-placeholder {
  height: 50px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}
.team-stats-cont {
  display: flex;
  justify-content: space-evenly;

  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin-top: 10px;
  /* margin-bottom: 50px; */
}

.team-stats-each-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.team-stats-number {
  width: 67px;
  height: 42px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Label */

  background: #eeeeee;

  border-radius: 10px;

  font-weight: 600;
  font-size: 14px;

  color: #333333;
}
.team-stats-label {
  font-weight: 400;
  font-size: 12px;

  color: #333333;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #e8e8e8;
    overflow-x: hiddens;
  }
  .team-profile-pics-cont {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 230px;

    background: #304457;
    border-radius: 0px 0px 40px 0px;
    padding-top: 10px;
    margin-top: -1px;
  }
  .team-containertt {
    background: #ffffff;
  }
  .team-backdrop-cont {
    position: absolute;
    margin-top: 0px;

    top: 5px;
    box-sizing: border-box;

    width: 95%;
    height: 150px;

    background: #ffffff;
    border-radius: 0px 0px 35px 35px;
    overflow: hidden;
  }
  .team-user-profile-pics {
    overflow: hidden;
    position: relative;

    top: 10px;
    margin-left: 240px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #304457;

    width: 87px;
    height: 87px;

    background: #304457;
    border: 2px solid #f2f2f2;
    border-radius: 50%;
  }
  .team-edit-profile-pics-family {
    position: absolute;
    /* top: 38px; */
    /* left: 258px; */
    top: 68px;
    right: -15px;
  }

  .team-deets-admin-logo {
    margin-left: 25px;
  }

  .team-uploaded-img img {
    margin-top: 16px;
    height: 100%;
    width: 100%;
  }

  .team-cont-all-avatar {
    position: relative;
    width: 320px;
    height: 90px;
    margin-right: 20px;
    /* padding-left: 100px; */
  }
  .members-navbar-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    height: 50px;

    background: #304457;
    /* position: fixed; */
  }

  .team-shareicon-cont {
    width: 30px;
    height: 30px;
    background: #171923;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-content: center;
    opacity: 0.7;
  }

  .team-share-iii {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .team-edit-backdrop-fam-c {
    position: absolute;
    height: 35px;
    top: 50px;
    right: 170px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(6px);
    /* Note: backdrop-filter has minimal browser support */
  }
  .team-edit-blackpencont {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .team-backdrop-img-main {
    width: 100%;
    height: 100%;
  }
  .team-user-profile-details {
    position: absolute;
    bottom: 5px;
    /* left: 0; */
    text-align: center;
  }

  .team-edit-profile-pics-icon {
    position: relative;

    width: 35px;
    height: 35px;

    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(6px);

    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .edit-profile-pics-icon input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }
  .user-profile-pics-text {
    margin-top: 10px;
  }

  .team-user-name {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;

    /* identical to box height, or 107% */

    color: #ffffff;
  }
  .team-user-title {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;

    /* Label */

    color: #c4c8ca;
  }
  .team-body-div {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team-prof-con-cont {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 20px;
  }
  .team-profile-button {
    /* padding: 16px 11px; */
    padding: 16px 5px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Poppins";

    font-style: normal;
    font-weight: 500;
    margin-right: 20px;
    font-size: 13px;

    /* Text color */

    color: #333333;

    /* height: 49px; */
    /* left: 189px;
top: 327px; */

    background: #ffffff;
    border-radius: 5px;
    border: 1px solid #333333;
    box-sizing: border-box;
  }
  .team-profile-button button {
    outline: none;
  }
  .team-connect-button {
    padding: 16px 5px;
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;

    /* Text color */

    color: #ffffff;

    border-radius: 5px;
    border: 0;
    outline: none;
  }

  /* .team-connect-button button {
    border: 1px;
    outline: none;

  } */
  .team-about-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }
  .team-edit-teamEditPenicon {
    margin-right: 20px;
    height: 35px;
    width: 35px;
    background: #f2f2f2;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .team-about-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .team-about-user-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team-about-placeholder {
    height: 130px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }

  .unlocked-icon {
    margin-right: 30px;
  }
  .unlocked-icon-fam {
    display: flex;
    align-items: center;
  }
  .team-contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }
  .team-contact-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .team-contact-user-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team-contact-placeholder {
    height: 50px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }
  .team-call-update-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    /* position: relative; */
  }
  .team-call-icon-r {
    margin-left: 10px;
    margin-right: 30px;
    margin-top: 10px;
  }
  .team-forward-icon-r {
    margin-right: 30px;
  }
  .team-call-inside-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .team-email-admin-cont {
    width: 100px;
    height: 22px;
    /* padding-right: 20px; */
  }
  .team-address-admin {
    width: 214px;
    height: 66px;
  }
  .team-social-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }
  .team-social-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .team-social-user-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team-social-placeholder {
    height: 50px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }

  .team-snapchat-entry {
    width: 95px;
    height: 95px;

    background: #ffffff;
    box-shadow: 0px 1.69681px 5.09043px rgba(0, 0, 0, 0.1),
      0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin: 5px;
  }

  .team-social-snap-chat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .team-social-snap-chat-icon img {
    margin-top: 30px;
  }
  .team-snap-chat-tag {
    margin-left: 5px;
    width: 34px;
    height: 20px;

    /* primary/deets green */

    background: #ebf6f6;
    opacity: 0.1;
    border-radius: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 10px;

    /* identical to box height */

    display: flex;

    justify-content: center;
    align-items: center;
    /* primary/deets green */

    color: #86c9c8;

    opacity: 0.89;
  }

  .team-snap-chat-tag-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .team-social-media-big-entry {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .team-video-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }
  .team-video-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .team-video-user-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team-video-placeholder {
    height: 50px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }

  .team-links-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }
  .team-links-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .team-links-user-text {
    padding-left: 10px;
    padding-right: 10px;
  }
  .team-links-placeholder {
    height: 50px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }
  .team-stats-cont {
    display: flex;
    justify-content: space-evenly;

    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-top: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    margin-top: 10px;
    /* margin-bottom: 50px; */
  }

  .team-stats-each-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .team-stats-number {
    width: 67px;
    height: 42px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    /* Label */

    background: #eeeeee;

    border-radius: 10px;

    font-weight: 600;
    font-size: 14px;

    color: #333333;
  }
  .team-stats-label {
    font-weight: 400;
    font-size: 12px;

    color: #333333;
  }
}

@media only screen and (max-width: 320px) {
  .team-profile-button {
    /* padding: 16px 11px; */
    padding: 16px 5px;
    width: 120px;

    font-size: 11px;
    box-sizing: border-box;
  }
  .team-connect-button {
    padding: 16px 5px;
    width: 120px;

    font-size: 11px;
  }

  .team-user-name {
    font-size: 15px;
  }
  .team-user-title {
    font-size: 10px;
    margin-top: 0px;
  }
}
