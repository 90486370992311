.inputFieldPersonalizePrefix {
  position: relative;
  width: 450px;
  height: 48px;

  /* margin-left: 10px;
      margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.inputFieldPersonalizePrefix input {
  padding: 8px 65px;
  padding-top: 25px;
  border: 1.5px solid #e2e8f0;
  /* border-bottom: 1px solid #cbd5e0; */
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 100%;
  height: 49px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: 18px;
  color: #333333;
  background: #f8fafc;
  box-sizing: border-box;
  /* box-sizing: border-box; */
}

.inputFieldPersonalizePrefix input:-webkit-autofill,
.inputFieldPersonalizePrefix input:-webkit-autofill:hover,
.inputFieldPersonalizePrefix input:-webkit-autofill:focus,
.inputFieldPersonalizePrefix input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f8fafc inset !important;
}

.inputFieldPersonalizePrefix input:focus {
  border: 1.8px solid #6b6b6b;
  outline: none;
  /* border: 1.5px solid #36a6a4; */
}
.inputFieldPersonalizePrefix span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 5%;
  left: 10px;
  top: 7px;
  -webkit-transform: translateY(-50%);
  /* transform: translateY(-50%); */
  cursor: text;

  font-weight: 500;
  font-size: 10px;

  /* Text color */

  color: #333333;

  transform: translateY(-10%) translateX(-8.5px);
  /* padding-left: 10%; */
}

/* .inputFieldPersonalizePrefix input:focus + span,
  .inputFieldPersonalizePrefix input:not(:placeholder-shown) + span {
    top: 7px;
   
    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 5%;
    font-size: 10px;

    
  } */
.inputFieldPersonalizePrefix input,
.inputFieldPersonalizePrefix span {
  /* -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

@media only screen and (max-width: 600px) {
  .inputFieldPersonalizePrefix {
    position: relative;
    width: 100vw;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    /* height: 48px; */
  }

  .inputFieldPersonalizePrefix input {
    padding: 8px 67px;
    padding-top: 25px;
    padding-right: 35px;

    border: 1.5px solid #e2e8f0;
    /* border-bottom: 1px solid #cbd5e0; */
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 88vw;
    height: 49px;
    box-sizing: border-box;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    -webkit-appearance: none;
    border-radius: 18px;
    color: #333333;
    background: #f8fafc;
  }

  .inputFieldPersonalizePrefix input:focus {
    border: 1.8px solid #6b6b6b;
    outline: none;
    /* border: 1.5px solid #36a6a4; */
  }
  .inputFieldPersonalizePrefix span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    /* padding-left: 35px; */
    left: 10px;
    top: 7px;
    -webkit-transform: translateY(-50%);
    /* transform: translateY(-50%); */

    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 10%;
    cursor: text;

    font-weight: 500;
    font-size: 10px;

    /* Text color */

    color: #333333;
  }

  /* .inputFieldPersonalizePrefix input:focus + span,
  .inputFieldPersonalizePrefix input:not(:placeholder-shown) + span {
    top: 7px;
  
    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 10%;
    font-size: 10px;
  } */
  .inputFieldPersonalizePrefix input,
  .inputFieldPersonalizePrefix span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }
}

@media only screen and (max-width: 510px) {
  .inputFieldPersonalizePrefix input {
    padding: 8px 70px;

    padding-top: 25px;
    padding-right: 35px;
    width: 90vw;
  }
}

@media only screen and (max-width: 410px) {
  .inputFieldPersonalizePrefix input {
    width: 92vw;
  }
}

@media only screen and (max-width: 383px) {
  .inputFieldPersonalizePrefix input {
    width: 93vw;
  }
}

@media only screen and (max-width: 342px) {
  .inputFieldPersonalizePrefix input {
    width: 94vw;
  }
}

@media only screen and (max-width: 320px) {
  .inputFieldPersonalizePrefix input {
    width: 96vw;
  }
}
