@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.no-auto-zoom {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  user-scalable: no;
  user-zoom: no;
}
.formLabel {
  margin-left: 20px;
}

.formLabelAs {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #ff0000;
  margin-right: 5px;
}

.inputLabelContain {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
  margin-top: 30px;
}

.formLabelText {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  color: #171923;
}

.form_containGen {
  width: 80%;
}
.formLabelMain_input {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}

.form_containGen input {
  padding: 15px 10px;
  outline: none;
  border: 1px solid #36a6a4;
  width: 100%;

  /* padding: 12px;
gap: 10px;

width: 272px;
height: 46px; */

  background: #f8fafc;

  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #333333;
  box-sizing: border-box;
  -webkit-appearance: none;
}
.form_containGen input::placeholder {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #333333;

  opacity: 0.3;
}
