@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResVideoBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.video-info-family {
  background: #fbfbfb;
  position: relative;
  min-height: 100vh;
}

.save-video-form {
  position: absolute;
  display: flex;
  align-items: center;
  width: 300px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  bottom: 10px;

  /* margin-top: 200px; */
  /* margin-bottom: 50px; */
}
.save-video-form input {
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 85vw;
  height: 49px;

  background: #36a6a4;
  opacity: 1;
  border-radius: 9px;
}

/* .save-video-form input:focus {
    opacity: 1;
  } */

.video-link-input-family {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .video-remove-icon img {
  position: absolute;
  top: -20px;
} */

.remove-video-icon {
  position: absolute;
  right: 15%;
  bottom: 15px;
  cursor: pointer;
}

.add-video-family {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height */

  display: flex;
  justify-content: flex-end;

  color: #36a6a4;
  /* margin-right: 12%; */
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}
.add-video-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 30px; */
  margin-top: 10px;
  cursor: pointer;
}
.add-video-cont img {
  margin-right: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .totalResVideoBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .video-info-family {
    background: #fbfbfb;
    /* padding-bottom: 50px; */
    min-height: 100vh;
    position: relative;
  }

  .totalResVideoBody {
    max-width: 100%;
  }
  .save-video-form {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    /* bottom: 30px; */

    margin-top: 200px;
    /* margin-bottom: 50px; */
  }
  .save-video-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 85vw;
    height: 49px;

    background: #36a6a4;
    opacity: 1;
    border-radius: 9px;
  }

  /* .save-video-form input:focus {
    opacity: 1;
  } */

  .video-link-input-family {
    position: relative;
  }

  /* 
  .remove-icon img {
    position: absolute;
    top: -20px;
  } */
  .remove-video-icon {
    position: absolute;
    right: 30px;
    bottom: 8px;
  }

  .remove-video-icon img {
    width: 19.5px;
    height: 19.5px;
  }
  .add-video-family {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height */

    display: flex;
    justify-content: flex-end;

    color: #36a6a4;
  }
  .add-video-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 30px; */
    margin-top: 10px;
  }
  .add-video-cont img {
    margin-right: 5px;
  }
}
