@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResPersonalBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}

.personal-info-family {
  background: #fbfbfb;
  padding-bottom: 50px;
  min-height: 100vh;
}

.inputFieldPersonal {
  position: relative;
  width: 550px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.personal-form-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
}

.select-cont-family {
  width: 85%;
  height: 40px;
}
.inputFieldPersonal select {
  font-family: "Poppins";
  width: 98%;
  height: 60px;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  outline: none;
  font-weight: 500;
  font-size: 14px;

  /* Text color */
  -webkit-appearance: none;
  border-radius: 16px;

  color: #333333;
  padding-left: 5px;
  /* padding-right: 10px; */
}
.inputFieldPersonal select:focus {
  border-bottom: 2px solid #36a6a4;
}

.custom-selectBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 450px;
  height: 52px;
  margin-top: 20px;

  /* Grays/Gray 50 */

  background: #f8fafc;
  /* Grays/Gray 200 */

  border: 1.5px solid #e2e8f0;
  border-radius: 18px;
  position: relative;
  cursor: pointer;
}

.custom-selectBtnText {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  color: #333333;
  margin-left: 20px;
}

.custom-selectAncor img {
  width: 10px;
  height: 15px;
  margin-right: 10px;
}

.personal-span-placeHolder {
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  color: #333333;
  opacity: 0.5;
  top: 5px;
  left: 5%;
}

.select-modal-body {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%; /* Full width */
  max-width: 500px;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.7);
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}

.select-modalBody-cont {
  position: absolute;
  bottom: 0;
  height: 73%;
  width: 100%;

  background: #f2f2f2;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;

  transition: transform 0.4s ease-in-out;

  /* transition: 0.3s ease-in-out 0s; */
  left: 0;
  right: 0;

  border-radius: 17px 17px 0px 0px;
  /* padding: 10px; */
  padding-bottom: 50px;
  /* padding-top: 10px; */
  box-sizing: border-box;
}

.open_selectModalBody {
  -webkit-animation-name: animateup;
  -webkit-animation-duration: 0.4s;
  animation-name: animateup;
  animation-duration: 0.4s;
}

.close_selectModalBody {
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;

  /* transform: translateY(0%); */
}
@-webkit-keyframes animateup {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animateup {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@-webkit-keyframes animatedown {
  from {
    bottom: 0px;
    opacity: 1;
  }
  to {
    bottom: -300px;
    opacity: 0;
  }
}

@keyframes animatedown {
  from {
    bottom: 0px;
    opacity: 1;
  }
  to {
    bottom: -300px;
    opacity: 0;
  }
}

.select-modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  height: 80px;
}

.select-modalHeader-text {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #171923;
  /* margin-left: 20px; */
}

.select-modalHeader-canBtn {
  /* width: 36px;
  height: 36px; */
  /* margin-right: 20px; */
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
}

.select-modalHeader-canBtn:hover {
  transform: scale(1.1);
}

.select-modal-option {
  width: 90%;
  height: 48px;

  /* Grays/Gray 50 */

  background: #f8fafc;
  /* Grays/Gray 200 */

  border: 1.5px solid #e2e8f0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 10px;
  margin-right: 10px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  z-index: 50000000000000;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.select-modal-option:hover {
  border: 1.5px solid #bcbcbd;
}

.select-modal-optionText {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  color: #171923;
  margin-left: 15px;
}

.select-modal-optionIcon img {
  width: 16px;
  height: 16px;
  /* margin-right: 15px; */
}

.select-modal-optionIcon {
  margin-right: 15px;
}
.select-doneBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 14px 11px; */

  /* position: absolute; */
  position: fixed;
  width: 90%;
  height: 49px;
  bottom: 5px;
  right: 0;
  left: 0%;
  margin: 0 auto;

  /* primary/deets green */

  background: #36a6a4;
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.select-doneBtn:hover {
  background: #248381;
}
.profile-link-header {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 30px;

  background: #333333;

  /* font-style: italic; */
  font-weight: 600;
  font-size: 9px;

  color: #f2f2f2;
}
.edit-link-container {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
  width: 450px;
  margin-right: auto;
  margin-left: auto;
}
.edit-link-container input {
  padding: 8px 20px;
  font-family: "Poppins";
  -webkit-appearance: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 75%;
  height: 40px;

  color: #333333;
}
.edit-link-container input:focus {
  border: none;
  outline: none;
  border-bottom: 2px solid #36a6a4;
}
.edit-link-container ::placeholder {
  color: #e9e9e9;
}
.link-prefix {
  /* margin-right: 10px;
  margin-left: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 35%; */
  text-align: right;
  /* width: 25%; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #333333;
}
.link-prefix-input {
  width: 45%;
}

.save-personal-form {
  margin-top: 70px;
  margin-bottom: 50px;
  width: 90%;

  display: flex;
  align-items: center;
  justify-content: center;
}
.save-personal-form input {
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 49px;

  background: #36a6a4;
  opacity: 1;
  border-radius: 9px;
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .totalResPersonalBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }

  .custom-selectBtn {
    width: 450px;
  }

  .select-modal-body {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }

  .totalResPersonalBody {
    max-width: 100%;
  }

  .personal-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    margin-bottom: 20px;
    overflow-x: hidden;
  }
  .personal-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .link-prefix {
    /* margin-left: 50px; */
  }

  .personal-info-header > .personal-info-text {
    margin-left: -30px;
  }
  .personal-info-family {
    background: #fbfbfb;
    padding-bottom: 50px;
  }

  .personal-form-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }

  .select-cont-family {
    width: 85%;
    height: 40px;
  }
  .inputFieldPersonal select {
    font-family: "Poppins";
    width: 92%;
    height: 60px;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    outline: none;
    font-weight: 500;
    font-size: 14px;

    /* Text color */
    -webkit-appearance: none;
    border-radius: 16px;

    color: #333333;
    padding-left: 5px;
    /* padding-right: 10px; */
  }
  .inputFieldPersonal select:focus {
    border-bottom: 2px solid #36a6a4;
  }

  .profile-link-header {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;

    background: #333333;

    /* font-style: italic; */
    font-weight: 600;
    font-size: 9px;

    color: #f2f2f2;
  }

  .edit-link-container {
    width: 90vw;
  }

  .edit-link-container input {
    padding: 8px 20px;
    font-family: "Poppins";
    -webkit-appearance: none;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 75%;

    height: 40px;

    color: #333333;
  }
  .edit-link-container input:focus {
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .edit-link-container ::placeholder {
    color: #e9e9e9;
  }

  .save-personal-form {
    margin-top: 30px;
    margin-bottom: 50px;
    width: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .save-personal-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 90vw;
    height: 49px;

    background: #36a6a4;
    opacity: 1;
    border-radius: 9px;
  }

  .select-modal-body {
    max-width: 100%;
  }

  .custom-selectBtn {
    width: 87.5vw;
    /* width: 100%; */
  }

  .custom-selectBtnText {
    margin-left: 15px;
  }

  /* .save-personal-form input:focus {
    opacity: 1;
  } */

  .personal-span-placeHolder {
    left: 4%;
  }
}

@media only screen and (max-width: 435px) {
  .custom-selectBtn {
    width: 90vw;
    /* width: 100%; */
  }
}

@media only screen and (max-width: 388px) {
  .custom-selectBtn {
    width: 91.5vw;
    /* width: 100%; */
  }
}

@media only screen and (max-width: 338px) {
  .custom-selectBtn {
    width: 93.5vw;
    /* width: 100%; */
  }
}

@media only screen and (max-width: 340px) {
  .link-prefix {
    font-size: 10px;
  }
}

@media only screen and (max-width: 298px) {
  .link-prefix {
    font-size: 8px;
  }
}
