.errorBodyGen {
  position: fixed;
  width: 500px;
  height: 50px;

  background: #ff0000;
  border-radius: 14px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  top: 20px;

  left: 50%;
  transform: translateX(-50%);
  z-index: 99999999999;
}

@media only screen and (max-width: 1400px) {
  .errorBodyGen {
    width: 300px;
  }
}

@media only screen and (max-width: 600px) {
  .errorBodyGen {
    width: 80%;
  }
}
