.totalResBodyTeamCustomInfo {
  max-width: 500px;
  margin: auto;
  position: relative;
}
.dfdfdfdf_tempCust {
  width: 100%;
}
.teamCustominfo-info-family {
  background: #f8fafc;
  /* padding-bottom: 100px; */
  height: 100vh;
  padding-bottom: 70px;
}

.backDrop-switchFam_tempCust {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.backdrop-btnGen_tempCust {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  width: 328px;
  height: 45px;

  background: #e2e8f0;
  border-radius: 8.91px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switcher-animation {
  width: 163px;
  height: 39px;
  position: absolute;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  /* left: 4px; */

  background: #f8fafc;
  /* border: 0.5px solid #e2e8f0; */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  font-weight: 700;
  font-size: 12px;
  color: #171923;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: 0.5s ease-in-out 0s; */
}

.switcher-animation_tempCust {
  width: 163px;
  height: 39px;
  position: absolute;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  /* left: 4px; */

  background: #f8fafc;
  /* border: 0.5px solid #e2e8f0; */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  font-weight: 700;
  font-size: 12px;
  color: #171923;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: 0.5s ease-in-out 0s; */
}

.useImageSwitch_tempCust {
  width: 45%;
  height: 39px;
  border-radius: 7px;
  font-style: normal;

  display: flex;
  color: #686c75;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
}

.useColorSwitch_tempCust {
  /* position: relative; */
  width: 45%;
  height: 39px;
  border-radius: 7px;
  color: #686c75;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  cursor: pointer;
}

.backdrop-button___tempCust {
  display: flex;
  /* padding: 30px 11px; */
  justify-content: center;
  align-items: center;
  /* padding: 14px 11px; */

  width: 328px;
  height: 49px;

  /* primary/deets green */

  /* background: #36a6a4; */
  border-radius: 12px;

  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  position: relative;
  /* margin-top: 20px;
  margin-bottom: 5px; */
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}

.backdrop-button___tempCust:hover {
  opacity: 0.8;
  cursor: pointer;
}
.center-upload-iiibtn___tempCust {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.backdrop-Btn_color_tempCust {
  width: 328px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.backdrop-BackBtn_color_tempCust {
  width: 328px;
  height: 49px;

  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  box-sizing: border-box;
  cursor: pointer;

  transition: 0.5s ease-in-out 0s;
  margin-right: auto;
  margin-left: auto;
}

.backdrop-BackBtn_color_tempCust:hover {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
}

.backdrop-img-preview_tempCust {
  position: relative;
  margin-top: 20px;
  width: 320px;
  height: 150px;

  /* background: #ebf6f6; */
  border-radius: 14px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px dashed #36a6a4; */

  /* Label */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.backdrop-preview-text_tempCust {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;

  letter-spacing: 0.01em;

  /* Text color */

  color: #8b8f8f;

  /* opacity: 0.21; */
}

.backdrop__textGen_tempCust {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.backdropText___tempCust {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  color: #333333;

  opacity: 0.6;
}

.backdrop-uploadImgF_tempCust {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 35px;
  height: 35px;

  /* background: rgba(255, 255, 255, 0.7); */

  backdrop-filter: blur(2px);

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;

  transition: 0.2s ease-in-out 0s;
}

.backdrop-uploadImgF_tempCust:hover {
  /* background: #e9e9e9; */
  transform: scale(1.1);
}

.backdrop-uploadImgF_tempCust img {
  width: 16px;
  height: 14px;
}

.cover-image-bg_tempCust input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}
.genIconCont_back_tempCust {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
}

.phone-icon-s_back_tempCust {
  /* margin-top: 5px; */
  width: 100%;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border-radius: 50%;
  opacity: 0.2;
}

.childIcon_came_tempCust {
  position: absolute;

  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.close-backdrop-img_tempCust {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
}

.close-backdrop-img_tempCust img {
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
}

.close-backdrop-img_tempCust:hover {
  transform: scale(1.1);
}

.childIcon_back_tempCust {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.backdrop-BtnContainerGen_tempCust {
  justify-content: space-between;
  width: 328px;
  /* width: 65%; */
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.backdrop-BackBtn_color_tempCust {
  width: 328px;
  height: 49px;

  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  box-sizing: border-box;
  cursor: pointer;

  transition: 0.5s ease-in-out 0s;
  margin-right: auto;
  margin-left: auto;
}
.backdrop-BackBtn_tempCust {
  /* padding: 14px 11px; */

  /* width: 130px; */
  width: 100%;
  height: 49px;

  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  box-sizing: border-box;
  cursor: pointer;

  transition: 0.5s ease-in-out 0s;
  margin-top: 20px;
}

.backdrop-BackBtn_color_tempCust:hover {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
}

.backdrop-BackBtn_tempCust:hover {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
}

.backdrop-upload-btn-center_tempCust {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.backdrop-button_tempCust {
  display: flex;
  /* padding: 30px 11px; */
  justify-content: center;
  align-items: center;
  /* padding: 14px 11px; */

  /* width: 192px; */
  width: 100%;
  height: 49px;

  /* primary/deets green */

  /* background: #36a6a4; */
  border-radius: 12px;

  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  position: relative;
  /* margin-top: 20px;
  margin-bottom: 5px; */
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}

.backdrop-button_tempCust:hover {
  opacity: 0.8;
  cursor: pointer;
}
@media only screen and (max-width: 1400px) {
  .totalResBodyTeamCustomInfo {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyTeamCustomInfo {
    max-width: 100%;
  }

  .backdrop-img-preview_tempCust {
    position: relative;
    margin-top: 20px;
    width: 320px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    background: #ebebeb;

    /* Label */
    /* border: 2px dashed #36a6a4; */
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .cover-image-bg_tempCust input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }

  .close-backdrop-img_tempCust {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .close-backdrop-img_tempCust img {
    width: 36px;
    height: 36px;
  }

  .backdrop-upload-btn-center_tempCust {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
