.wrapper {
  width: 80%;
  text-align: center;
  margin: 55px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
.wrapper {
  margin: 0 auto;
}
.pwd-checker-bar {
  -webkit-appearance: none;
  appearance: none;
  width: 90%;
  height: 10px;
  margin-right: 5px;
}
.pwd-checker-bar::-webkit-progress-bar {
  background-color: rgb(246, 241, 241);
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.pwd-label {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #000000;
}
.pwd-checker-bar::-webkit-progress-value {
  border-radius: 4px;
  background-size: 30px 18px, 100% 100%, 100% 100%;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.label {
  /* margin-top: 10px; */
  /* margin-bottom: 0px; */
}
.strength-Weak::-webkit-progress-value {
  background-color: #e20b07;
}
.strength-Fair::-webkit-progress-value {
  background-color: #ebbd04;
}
.strength-Medium::-webkit-progress-value {
  background-color: #36a6a4;
}
.strength-Good::-webkit-progress-value {
  background-color: #36a6a4;
}
.strength-Strong::-webkit-progress-value {
  background-color: #36a6a4;
}
.Weak span {
  color: #e20b07;
}
.strength-Fair span {
  color: #ebbd04;
}
.strength-Medium span {
  color: #ff7518;
}
.strength-Good span {
  color: #0b75ed;
}
.strength-Strong span {
  color: #01a917;
}
