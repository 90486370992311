@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyTeamTempSocial {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative;
  height: 100vh; */
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hidden;
  /* overflow-y: auto; */
}

.socialInfo-temp-info-header {
  width: 100%;
  height: 56px;

  background: #f8f8f8;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* margin-bottom: 20px; */
  overflow-x: hidden;
}
.socialInfo-temp-info-header img {
  margin-left: 30px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.socialInfo-temp-info-header > .socialInfo-temp-info-text {
  margin-left: -30px;
}

.socialInfo-temp-head-text {
  margin-top: 5px;
  margin-bottom: 20px;
  width: 100%;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b2b2b2;

  /* Label */

  background: #f5f5f5;

  font-style: normal;
  font-weight: 300;
  font-size: 12px;
}
.social-temp-fam {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: stretch;
  padding-left: 10px;
  /* padding-right: auto; */
}
.social-temp-cont {
  box-sizing: border-box;
  background: #e9e9e9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-left: 25px;
  margin-top: 30px;
}

.save-social-temp-info {
  position: absolute;
  bottom: 10px;
  margin: 0 auto;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.save-social-temp-info button {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  width: 400px;

  height: 49px;
  margin-top: 20px;

  /* background: #fcb816; */
  /* opacity: 0.3; */
  border-radius: 6px;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hidden;
    /* overflow-y: auto; */
  }

  .socialInfo-temp-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }
  .socialInfo-temp-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .socialInfo-temp-info-header > .socialInfo-temp-info-text {
    margin-left: -30px;
  }

  .socialInfo-temp-head-text {
    margin-top: 5px;
    margin-bottom: 20px;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #b2b2b2;

    /* Label */

    background: #f5f5f5;

    font-style: normal;
    font-weight: 300;
    font-size: 12px;
  }
  .social-temp-fam {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: stretch;
    padding-left: 10px;
    /* padding-right: auto; */
  }
  .social-temp-cont {
    box-sizing: border-box;
    background: #e9e9e9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-left: 25px;
    margin-top: 30px;
  }

  .save-social-temp-info {
    position: absolute;
    bottom: 10px;
    margin: 0 auto;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .save-social-temp-info button {
    border: none;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #ffffff;
    width: 90%;

    height: 49px;
    margin-top: 20px;

    /* background: #fcb816; */
    /* opacity: 0.3; */
    border-radius: 6px;
  }
}
