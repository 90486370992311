.personalize_genFam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 71px;
  border-radius: 14px;
  border: 0.3px solid #e2e8f0;
  background: #f8fafc;
  box-shadow: 0px 16px 10px 0px rgba(218, 218, 218, 0.3);
  /* padding-left: 10px;
  padding-right: 10px; */

  padding: 10px;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 20px;
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}

.personalize_genFam:hover {
  border-radius: 10px;
  /* background: #f8f8f8; */
  /* transform: translateX(-5%); */
  transform: scale(1.05);
}

.personalize_firstFlex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  width: 80%;
}

.personalize_IconCard {
  margin-right: 15px;
}
.personalize_lockIconTag {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 5px;
}

.personalize_headerCard_ {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.personalizeSubHeader__ {
  color: #838383;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

@media only screen and (max-width: 350px) {
  .temp_nameHText {
    font-size: 11px;
  }

  .temp_nameEditTextName {
    font-size: 11px;
  }
  .singleTeam_personText_ {
    font-size: 11px;
  }

  .singleTeam_personEditText__ {
    font-size: 11px;
    width: 111px;
  }

  .personalize_headerCard_ {
    font-size: 12px;
  }

  .personalizeSubHeader__ {
    font-size: 11px;
  }
}

@media only screen and (max-width: 600px) {
  .personalize_genFam:hover {
    box-shadow: none;
    transform: none;
  }
}
