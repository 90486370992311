.totalResBodyLeftNav {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  background: #f2f2f2;
  height: 100vh;
}

.navMargin-profile {
  border-bottom: 1px solid #dfdfdf;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.deetsLogoCont {
  cursor: pointer;
}

.top-navbar-rel {
  position: relative;
}
.sidemenu-family-cont {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #e8e8e8;
  /* padding-top: 40px; */
  z-index: 100000;
}
.sidemenu-user-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  /* background: #171923; */
  height: 60px;
}
.cancel-menu {
  margin-left: 40px;
  opacity: 0;
}

.profileNavDetails-left {
  display: flex;
  align-items: center;
}
.menu-avatar-cont {
  margin-right: 5px;
  margin-left: 15px;
  width: 49px;
  height: 48px;

  border-radius: 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
}

.menu-avatar-cont img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-top: -0.5px;
}
.nav-username {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;

  color: #333333;
}
.nav-email {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  margin-top: 1px;
  color: #333333;

  opacity: 0.7;
  width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.side-menu-option {
  display: flex;

  align-items: center;
  margin-right: auto;
  /* margin-left: auto; */
  /* width: 90%; */
  width: 100%;
  height: 47px;

  background: #f2f2f2;
  transition: 0.3s ease-in-out 0s;
  box-sizing: border-box;
  /* border-bottom: 1px solid #cbd5e0; */
}

.side-menu-option:hover {
  /* transform: scale(1.1); */
  /* border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf; */

  background: #dfdfdf;
}

.side-menu-option_active {
  background: #dfdfdf;
}

.logoutIcon-imgNav {
  width: 20px;
  height: 20px;
  margin-left: 20px;
}
.left-align-user-details {
  display: flex;
  align-items: center;
}

.details-left-cont {
  margin-left: 5px;
}
.added-to-fit {
  width: 41px;
  height: 40px;
  margin-right: 40px;
  background: green;
}

.options-img {
  margin-left: 20px;
  width: 20px;
  height: 20px;
}
.option-label {
  font-family: "Poppins";
  font-style: normal;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;

  /* Text color */

  color: #333333;
  margin-left: 20px;
  text-decoration: none;
}
.cont-main {
  position: relative;
  /* padding-bottom: 100px; */
  height: 600px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  /* padding-bottom: 50px; */
}
.logout-family-cont {
  cursor: pointer;
  display: flex;
  /* justify-content: center; */
  align-items: center;

  /* position: absolute; */
  /* width: 100%; */
  width: 100%;
  height: 55px;
  bottom: 100px;
  /* Primary/light */

  /* background: #f5f5f5; */
  /* border-radius: 6px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #333333;
  /* z-index: 10000000; */
  margin-top: 20px;
  transition: 0.3s ease-in-out 0s;
  opacity: 0.7;
}

.logout-family-cont:hover {
  /* transform: scale(1.1); */
  /* border-top: 1px solid #dfdfdf;
  border-bottom: 1px solid #dfdfdf; */

  background: #dfdfdf;
}
.logout-family-cont img {
  margin-right: 10px;
}
.logout-text {
  margin-top: -4px;
  margin-left: 20px;
}

.profileImageText-nav {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #171923;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyLeftNav {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyLeftNav {
    max-width: 100%;
  }

  .top-navbar-rel {
    position: relative;
  }

  .sidemenu-family-cont {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #e8e8e8;
    z-index: 100000;
  }
  .sidemenu-user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    height: 60px;
  }
  .cancel-menu {
    margin-left: 40px;
    opacity: 0;
  }
  .menu-avatar-cont {
    margin-right: 5px;
    margin-left: 15px;
    width: 49px;
    height: 48px;
    /* border: 3px solid #36a6a4; */
    border-radius: 12px;
    box-sizing: border-box;
  }

  .profileImageText-nav {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    color: #171923;
  }

  .menu-avatar-cont img {
    width: 100%;
    height: 102%;
    border-radius: 10px;
    margin-top: -0.5px;
  }
  .nav-username {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 15px;

    color: #333333;
  }
  .nav-email {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    margin-top: 1px;
    color: #333333;

    opacity: 0.7;
    width: 300px;

    overflow: hidden;
    text-overflow: ellipsis;
  }
  .side-menu-option {
    display: flex;

    align-items: center;
    margin-right: auto;
    /* margin-left: auto; */
    /* width: 90%; */
    width: 100%;

    height: 47px;

    /* White */

    /* background: #f2f2f2; */
  }

  .left-align-user-details {
    display: flex;
    align-items: center;
  }

  .details-left-cont {
    margin-left: 5px;
  }
  .added-to-fit {
    width: 41px;
    height: 40px;
    margin-right: 40px;
    background: green;
  }

  .options-img {
    margin-left: 20px;
  }
  .option-label {
    font-family: "Poppins";
    font-style: normal;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;

    /* Text color */

    color: #333333;
    margin-left: 20px;
    text-decoration: none;
  }
  .cont-main {
    position: relative;
    /* padding-bottom: 100px; */
    height: 600px;
    /* padding-bottom: 50px; */
  }
  .logout-family-cont {
    display: flex;
    align-items: center;

    /* width: 100%; */
    width: 100%;

    height: 55px;
    bottom: 100px;

    /* border-radius: 6px; */
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #333333;
    opacity: 0.7;
  }
  .logout-family-cont img {
    margin-right: 10px;
  }
  .logout-text {
    margin-top: -4px;
    margin-left: 20px;
  }
}
