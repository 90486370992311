.totalResSocialBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.custom-selectBtnSocial {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 130px;
  height: 46px;

  background: #f8fafc;
  /* Grays/Gray 200 */

  border-left: 1.5px solid #e2e8f0;

  border-top: 1.5px solid #e2e8f0;

  border-bottom: 1.5px solid #e2e8f0;
  border-radius: 18px 0px 0px 18px;
  margin-right: -2px;
  overflow: hidden;
}

.prefix_genSocial {
  position: absolute;
  left: 3%;
  top: 30%;
  z-index: 100;
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}

.custom-selectBtnSocial:hover {
  cursor: pointer;
}

.url_validSocial {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #ff0000;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.custom-selectBtnTextSocial {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #64748b;
  margin-left: 35px;
  margin-right: 5px;
}

.custom-selectBtnTextSocialSe {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #64748b;
  margin-left: 5px;
  margin-right: 5px;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-selectAncorSocial img {
  width: 10px;
  height: 15px;
  margin-right: 10px;
}

.select-modal-bodySocial {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%; /* Full width */
  max-width: 500px;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.3);
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}

.socialSelectIcon-famm {
  display: flex;
  align-items: center;
}

.socialSelect-Iconn {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.socialSelect-Iconn img {
  width: 100%;
  height: 100%;
}
.select-modalBody-contSocial {
  position: absolute;
  bottom: 0;
  height: 83%;
  width: 100%;

  background: #f2f2f2;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  left: 0;
  right: 0;

  border-radius: 17px 17px 0px 0px;
  /* padding: 10px; */
}

@-webkit-keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.select-modalHeaderSocial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 80px;
  margin-right: auto;
  margin-left: auto;
}

.select-modalHeader-textSocial {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #171923;
  /* margin-left: 20px; */
}

.select-modalHeader-canBtnSocial {
  /* width: 36px;
  height: 36px; */
  /* margin-right: 20px; */
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.select-modalHeader-canBtnSocial:hover {
  transform: scale(1.1);
}

.select-modal-optionSocial {
  width: 90%;
  height: 48px;

  /* Grays/Gray 50 */

  background: #f8fafc;
  /* Grays/Gray 200 */

  border: 1.5px solid #e2e8f0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 10px;
  margin-right: 10px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  z-index: 50000000000000;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.select-modal-optionSocial:hover {
  border: 1.5px solid #bcbcbd;
}
.select-modal-optionTextSocial {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  color: #171923;
  margin-left: 15px;
}

.select-modal-optionIconSocial {
  /* width: 16px;
  height: 16px; */
  margin-right: 15px;
}
.select-doneBtnSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 14px 11px; */

  /* position: absolute; */
  position: fixed;
  width: 90%;
  height: 49px;
  bottom: 5px;
  right: 0;
  left: 0%;
  margin: 0 auto;

  /* primary/deets green */

  background: #36a6a4;
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
}

.social-info-family {
  background: #fbfbfb;
  /* padding-bottom: 100px; */
  min-height: 100vh;
  position: relative;
}
.social-info-header {
  width: 100%;
  height: 56px;

  background: #f8f8f8;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
  overflow-x: hidden;
}
.social-info-header img {
  margin-left: 30px;
  cursor: pointer;
}

.social-info-header > .social-info-text {
  margin-left: -30px;
}

.inputFieldSocial {
  position: relative;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}
.social-select-field select:focus {
  border-bottom: 2px solid #36a6a4;
}

.social-select-field select {
  border: none;
  outline: none;
  background: #f5f5f5;
  border-bottom: 1px solid #cbd5e0;
  width: 100%;
  height: 60px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  -webkit-appearance: none;
  border-radius: 0;
}

.inputFieldSocial input {
  padding: 8px 20px;
  padding-top: 30px;
  border: none;

  border-bottom: 1.5px solid #e2e8f0;
  border-right: 1.5px solid #e2e8f0;
  border-top: 1.5px solid #e2e8f0;

  width: 83%;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  -webkit-appearance: none;

  border-radius: 0px 18px 18px 0px;
  color: #333333;
  height: 48px;
  background: #f8fafc;
}
.inputFieldSocial input::placeholder {
  opacity: 0;
}
.inputFieldSocial input:focus {
  /* border: 1.8px solid #6b6b6b; */
  border: none;
  outline: none;
  border-bottom: 2px solid #36a6a4;
}
.inputFieldSocial span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 16px;

  /* Text color */

  color: #333333;
}

.inputFieldSocial input:focus + span,
.inputFieldSocial input:not(:placeholder-shown) + span {
  top: 7px;
  -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  font-size: 14px;
}
.inputFieldSocial input,
.inputFieldSocial span {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.flex-inputs {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  /* align-items: center; */
}

.social-remove-section {
  /* margin-top: -10px; */
  position: relative;
}

.remove-social-icon {
  width: 50px;
  height: 40px;
  /* background: #f8fafc; */

  background: linear-gradient(
    269.96deg,
    rgba(248, 250, 252, 0.67) 69.38%,
    rgba(248, 250, 252, 0) 105.08%
  );
  z-index: 100000;
  position: absolute;
  right: -3px;
  top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 16px 16px 0px;
}
.custom-selectedOptionGen {
  display: flex;
  align-items: center;
}

.customSelected-IconGen {
  margin-right: 2px;
  margin-left: 8px;
  width: 25px;
  height: 25px;
}

.customSelected-IconGen img {
  width: 100%;
  height: 100%;
}

.add-social-family {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;

  display: flex;
  justify-content: flex-end;

  color: #333333;
  margin-right: 5%;
  width: 90%;
}
.add-social-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  margin-top: 10px;
  /* color: #36a6a4; */
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
}
.add-social-cont {
  margin-right: 5px;
  cursor: pointer;
}

.save-social-info {
  margin-top: 40px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 10px;
  width: 300px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
}
.save-social-info input {
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  height: 49px;

  background: #36a6a4;
  opacity: 1;
  border-radius: 9px;
}

@media only screen and (max-width: 1400px) {
  .totalResSocialBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  .custom-selectBtnSocial {
    width: 130px;
  }

  .select-modal-bodySocial {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .custom-selectBtnSocial {
    width: 35vw;
  }

  .custom-selectBtnTextSocialSe {
    width: 90px;
  }

  .totalResSocialBody {
    max-width: 100%;
  }
  .url_validSocial {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #ff0000;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  .social-info-family {
    background: #fbfbfb;
    padding-bottom: 100px;
  }
  .social-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    margin-bottom: 20px;
    overflow-x: hidden;
  }
  .social-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .social-info-header > .social-info-text {
    margin-left: -30px;
  }

  .inputFieldSocial {
    position: relative;
    width: 70%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  .social-select-field select:focus {
    border-bottom: 2px solid #36a6a4;
  }

  .social-select-field select {
    border: none;
    outline: none;
    background: #f5f5f5;
    border-bottom: 1px solid #cbd5e0;
    width: 100%;
    height: 60px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    -webkit-appearance: none;
    border-radius: 0;
  }

  .inputFieldSocial input {
    padding: 8px 20px;
    padding-top: 30px;
    border: none;
    border-bottom: 1.5px solid #e2e8f0;
    border-right: 1.5px solid #e2e8f0;
    border-top: 1.5px solid #e2e8f0;

    width: 75%;

    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    -webkit-appearance: none;

    border-radius: 0px 18px 18px 0px;
    color: #333333;
    height: 18px;
    background: #f8fafc;
  }
  .inputFieldSocial input::placeholder {
    opacity: 0;
  }
  .inputFieldSocial input:focus {
    /* border: 1.8px solid #6b6b6b; */
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .inputFieldSocial span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 16px;

    /* Text color */

    color: #333333;
  }

  .inputFieldSocial input:focus + span,
  .inputFieldSocial input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .inputFieldSocial input,
  .inputFieldSocial span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .flex-inputs {
    display: flex;
    justify-content: center;
  }

  .social-remove-section {
    position: relative;
  }

  .remove-social-icon {
    z-index: 100000;
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .add-social-family {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;

    /* identical to box height */

    display: flex;
    justify-content: flex-end;

    color: #333333;
    width: 90%;
  }
  .add-social-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10%;
    margin-top: 10px;
  }
  .add-social-cont {
    margin-right: 5px;
  }

  .save-social-info {
    margin-top: 40px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .save-social-info input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 89vw;
    height: 49px;

    background: #36a6a4;
    opacity: 1;
    border-radius: 9px;
  }

  .select-modal-bodySocial {
    max-width: 100%;
  }
}
@media only screen and (max-width: 470px) {
  .custom-selectBtnTextSocialSe {
    width: 70px;
  }
}

@media only screen and (max-width: 416px) {
  .custom-selectBtnTextSocialSe {
    width: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .custom-selectBtnTextSocialSe {
    width: 50px;
  }
}
@media only screen and (max-width: 361px) {
  .customSelected-IconGen {
    margin-right: 2px;
    margin-left: 3px;
  }

  .custom-selectBtnTextSocial {
    margin-left: 2px;
    margin-right: 2px;
  }

  .custom-selectBtnTextSocialSe {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media only screen and (max-width: 350px) {
  .custom-selectBtnTextSocialSe {
    width: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .custom-selectBtnTextSocialSe {
    width: 30px;
  }
}

@media only screen and (max-width: 295px) {
  .custom-selectBtnTextSocialSe {
    width: 20px;
  }
}
