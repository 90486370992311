.sectional-info-header {
  width: 100%;
  height: 56px;

  background: #f1f5f9;
  border-bottom: 1px solid rgba(51, 51, 51, 0.06);

  /* identical to box height, or 157% */

  /* Text color */

  /* padding-left: 30px; */
  /* padding-right: 30px; */

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow-x: hidden;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #171923;
}
.sectional-info-header img {
  margin-left: 30px;
  cursor: pointer;
}

.arrowImg-cont {
  transition: 0.5s ease-in-out 0s;
}

.arrowImg-cont:hover {
  transform: translateX(-5px);
  cursor: pointer;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.sectional-info-header > .sectional-info-text {
  margin-left: -50px;
}

@media only screen and (max-width: 600px) {
  .sectional-info-family {
    background: #f8f8f8;
    padding-bottom: 100px;
  }
  .sectional-info-header {
    width: 100vw;
    height: 56px;

    background: #f1f5f9;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    margin-bottom: 20px;
    overflow-x: hidden;
  }
  .sectional-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .sectional-info-header > .sectional-info-text {
    margin-left: -40px;
  }
}
