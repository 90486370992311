@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyTeamLink {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
}

body {
  background: #e8e8e8;
}
.team-editLink-info-header {
  width: 100%;
  height: 56px;

  background: #f8f8f8;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  margin-bottom: 20px;
  overflow-x: hidden;
}
.team-editLink-info-header img {
  margin-left: 30px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.team-editLink-info-header > .team-editLink-info-text {
  margin-left: -30px;
}

.team-editLink-info-family {
  background: #f8f8f8;
  /* padding-bottom: 50px; */
  height: 100%;
  position: relative;
  padding-bottom: 70px;
}

.team-inputFieldEdit {
  position: relative;
  width: 100%;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.team-inputFieldEdit input {
  padding: 8px 10px;
  padding-top: 20px;
  padding-right: 50px;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 85%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
  -webkit-appearance: none;
  border-radius: 0;
}

.team-inputFieldEdit input:focus {
  /* border: 1.8px solid #6b6b6b; */
  border: none;
  outline: none;
  border-bottom: 2px solid #36a6a4;
}
.team-inputFieldEdit span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 30px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.team-inputFieldEdit input:focus + span,
.team-inputFieldEdit input:not(:placeholder-shown) + span {
  top: 7px;
  -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  font-size: 14px;
}
.team-inputFieldEdit input,
.team-inputFieldEdit span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}
.team-link-img-family {
  width: 54px;
  height: 50px;

  background: #ffffff;
  border-radius: 14px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.team-img-edit-icon {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 7px;
  margin-bottom: 7px;
}
.team-img-edit-icon img {
  position: absolute;
  /* top: 0; */
}

.team-link-img-upload-cont-flex {
  display: flex;
  justify-content: space-between;
  position: relative;
}

/* .remove-icon {
    margin-top: -30px;
  } */

.team-save-link-form {
  /* position: absolute; */
  display: flex;
  align-items: center;
  width: 100vw;
  justify-content: center;
  bottom: 30px;

  margin-top: 60px;
  margin-bottom: 50px;
}
.team-save-link-form input {
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 92vw;
  height: 49px;

  background: #36a6a4;
  opacity: 1;
  border-radius: 9px;
}
.team-link-img-family {
  position: relative;
}
.team-links-bg input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  body {
    background: #e8e8e8;
  }
  .team-editLink-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    margin-bottom: 20px;
    overflow-x: hidden;
  }
  .team-editLink-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .team-editLink-info-header > .team-editLink-info-text {
    margin-left: -30px;
  }

  .team-editLink-info-family {
    background: #f8f8f8;
    /* padding-bottom: 50px; */
    height: 100%;
    position: relative;
    padding-bottom: 70px;
  }

  .team-inputFieldEdit {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .team-inputFieldEdit input {
    padding: 8px 10px;
    padding-top: 20px;
    padding-right: 50px;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 77%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #333333;
    -webkit-appearance: none;
    border-radius: 0;
  }

  .team-inputFieldEdit input:focus {
    /* border: 1.8px solid #6b6b6b; */
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .team-inputFieldEdit span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 30px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .team-inputFieldEdit input:focus + span,
  .team-inputFieldEdit input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .team-inputFieldEdit input,
  .team-inputFieldEdit span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  .team-link-img-family {
    width: 54px;
    height: 50px;

    background: #ffffff;
    border-radius: 14px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .team-img-edit-icon {
    position: relative;
    width: 10px;
    height: 10px;
    margin-right: 7px;
    margin-bottom: 7px;
  }
  .team-img-edit-icon img {
    position: absolute;
    /* top: 0; */
  }

  .team-link-img-upload-cont-flex {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  /* .remove-icon {
    margin-top: -30px;
  } */

  .team-save-link-form {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    bottom: 30px;

    margin-top: 60px;
    margin-bottom: 50px;
  }
  .team-save-link-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 92vw;
    height: 49px;

    background: #36a6a4;
    opacity: 1;
    border-radius: 9px;
  }
  .team-link-img-family {
    position: relative;
  }
  .team-links-bg input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }

  /* .save-link-form input:focus {
    opacity: 1;
  } */
}
