@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.edit-bluepenicon {
  height: 35px;

  width: 35px;

  /* background: #ffffff; */
  /* box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.2),
    0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.1); */
  border-radius: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.2s ease-in-out 0s;
  cursor: pointer;
}

.edit-bluepenicon:hover {
  transform: scale(1.2);
}
@media only screen and (max-width: 600px) {
  .edit-bluepenicon {
    height: 35px;

    width: 35px;

    /* background: #ffffff; */
    /* box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.2),
      0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.1); */
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
