.modal-wrapperTes {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.5);
  transition: height 0.3s;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  z-index: 9999;
}

.modal-contentTes {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
}

.modal-wrapperTes.open {
  height: 100vh;
}

.modal-contentTes.open {
  transform: translateY(0);
  transition: transform 0.3s;
}

.modal-contentTes.closed {
  transform: translateY(100%);
  transition: transform 0.3s;
}
