.teamTempNavbar_Gen {
  width: 100%;
  height: 58px;

  border-bottom: 0.5px solid #e2e8f0;
  background: #f1f5f9;
}

.team_leftvvvvv {
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  padding-top: 7px;
}

.teamLeftDiv {
  width: 36px;
  height: 36px;
  margin-left: 20px;
}

.teamTextTemp {
  color: #171923;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
}

.teamIconTemp {
  margin-right: 20px;
}

.teamIconTemp {
  transition: 0.2s ease-in-out 0s;
  cursor: pointer;
}

.teamIconTemp:hover {
  transform: scale(1.1);
}
