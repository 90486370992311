.about-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 90%;
  margin-right: auto;
  margin-left: auto;
  height: 42px;
  margin-top: 20px;
}
.about-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 17px;

  color: #333333;
}

@media only screen and (max-width: 600px) {
  .about-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    height: 42px;
    margin-top: 20px;
  }
  .about-header-text {
    font-weight: 700;
    font-size: 17px;

    color: #333333;
  }
}
