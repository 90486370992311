@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyConnect {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  background: #fbfbfb;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}

.sectiinSearch {
  padding-top: -10px;
  margin-top: -7px;
  min-height: 75vh;
  padding-bottom: 50px;
}
.connect-searchWrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.connect-searchIcon {
  height: 20px;
  width: 20px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.connect-searchIcon img {
  width: 100%;
  height: 100%;
}

.connect-searchWrapper input {
  width: 100%;
  height: 48px;

  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 10px;
  border-radius: 16px;

  background: #f8fafc;
  /* Grays/Gray 200 */

  border: 1.5px solid #e2e8f0;
  font-family: "Poppins";
  font-size: 16px;

  color: #64748b;
  font-style: normal;
  font-weight: 400;
  outline: none;
}

.connect-blurBackground {
  background: rgba(249, 249, 249, 0.85);
  backdrop-filter: blur(3px);
  padding-bottom: 27px;
  padding-top: 0px;
  margin-top: -150px;
  z-index: 700;
  position: fixed;
  max-width: 500px;
  width: 100%;
}

.connectionList-Section {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.connectionList-HeadLetter {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #929298;
  margin-top: 30px;
  margin-bottom: 15px;
}

.connectionList-flex {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.connectionList-flex:hover {
  background: #e9e9e9;
}

.connectionList-imgEach {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background: #171923;

  border: 3px solid #b0b1b4;
  border-radius: 7px;
  position: relative;
}

.connectionList-nameEach {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #171923;
}

.connects_clickBody {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyConnect {
    max-width: 500px;
  }

  .connect-blurBackground {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyConnect {
    max-width: 100%;
  }
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }

  .connect-blurBackground {
    max-width: 100%;
  }
}
