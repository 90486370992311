.totalResBodyConnectionList {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  /* height: 100vh; */
}

.connections-list-user-gene {
  width: 90%;
  /* height: 100px; */
  display: flex;
  align-items: center;

  background: #ffffff;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.connections-list-user-img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #f9f9f9;
}

.connections-list-user-sub2 {
  width: 80%;
  height: 100%;
}
.connections-list-exchan-detais {
  width: 90%;
  height: 28px;

  background: #ffffff;

  border-top: 0.2px solid #cbd5e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}
.connections-list-user-cvbvh {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.connections-list-exchanioii {
  margin-right: 10px;
}

.connections-list-user-sub1 {
  width: 20%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connections-list-note-status {
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: #fff8e8;
  /* opacity: 0.1; */
  margin-right: 10px;
  border-radius: 50%;
}

.connections-list-user-namee {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #333333;
  margin-bottom: 5px;
}

.connections-list-positiondd {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;

  color: #333333;

  opacity: 0.8;
  /* margin-top: 2px; */
}

.connections-list-company-name {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  color: #333333;

  opacity: 0.7;
  margin-top: 2px;
}

.connections-list-emailll {
  font-weight: 400;
  font-size: 10px;

  color: #333333;

  opacity: 0.5;
  margin-top: 2px;
}

.connections-list-numberrr {
  font-style: normal;
  font-weight: 500;
  font-size: 9px;

  color: #333333;

  opacity: 0.8;
  margin-top: 10px;
  margin-bottom: 10px;
}

.connections-list-exchan-namee {
  font-style: normal;
  font-weight: 700;
  font-size: 10px;

  color: #36a6a4;

  opacity: 0.98;
  margin-left: 20%;
}

.connections-list-exchan-statusss {
  font-style: normal;
  font-weight: 500;
  font-size: 8px;

  color: #333333;

  opacity: 0.66;
}

.connections-list-exchan-date {
  font-style: normal;
  font-weight: 700;
  font-size: 8px;

  color: #333333;

  opacity: 0.7;
}

@media only screen and (max-width: 600px) {
  .connections-list-user-gene {
    width: 90%;
    /* height: 100px; */
    display: flex;
    align-items: center;

    background: #ffffff;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .connections-list-user-img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #f9f9f9;
  }

  .connections-list-user-sub2 {
    width: 80%;
    height: 100%;
  }
  .connections-list-exchan-detais {
    width: 90%;
    height: 28px;

    background: #ffffff;

    border-top: 0.2px solid #cbd5e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
  .connections-list-user-cvbvh {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .connections-list-exchanioii {
    margin-right: 10px;
  }

  .connections-list-user-sub1 {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .connections-list-note-status {
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #fff8e8;
    /* opacity: 0.1; */
    margin-right: 10px;
    border-radius: 50%;
  }

  .connections-list-user-namee {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    color: #333333;
    margin-bottom: 5px;
  }

  .connections-list-positiondd {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;

    color: #333333;

    opacity: 0.8;
    /* margin-top: 2px; */
  }

  .connections-list-company-name {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;

    color: #333333;

    opacity: 0.7;
    margin-top: 2px;
  }

  .connections-list-emailll {
    font-weight: 400;
    font-size: 10px;

    color: #333333;

    opacity: 0.5;
    margin-top: 2px;
  }

  .connections-list-numberrr {
    font-style: normal;
    font-weight: 500;
    font-size: 9px;

    color: #333333;

    opacity: 0.8;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .connections-list-exchan-namee {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;

    color: #36a6a4;

    opacity: 0.98;
    margin-left: 20%;
  }

  .connections-list-exchan-statusss {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;

    color: #333333;

    opacity: 0.66;
  }

  .connections-list-exchan-date {
    font-style: normal;
    font-weight: 700;
    font-size: 8px;

    color: #333333;

    opacity: 0.7;
  }
}
