.totalResBodyFPassOtp {
  max-width: 500px;
  margin: auto;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}

.fPass-info-familyOtp {
  background: #fbfbfb;
  /* padding-bottom: 100px; */
  min-height: 100vh;
}

.fPass-info-header_auth {
  width: 100%;
  height: 56px;

  background: #fbfbfb;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 12px;
  overflow-x: hidden;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.otp_subHeading_timer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 20px;
}

.otp_subHeading_timerText {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;

  /* Text color Faded */

  color: #838383;
  margin-right: 5px;
}

.otp_subHeading_timerCounter {
  font-style: normal;
  font-weight: 300;
  font-size: 12px;

  color: #f43f5e;
}

.otp_subHeading_verify {
  font-family: "Poppins", sans-serif;
  margin-top: 20px;
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 80%;
  height: 49px;
  background: #36a6a4;
  border-radius: 12px;
  box-sizing: border-box;
  transition: 0.3s ease all;
  margin-left: auto;
  margin-right: auto;
}

.otp_subHeading_verify:hover {
  opacity: 0.8;
}

.otp_subHeading_resend {
  font-family: "Poppins", sans-serif;
  margin-top: 10px;
  cursor: pointer;
  /* border: none; */
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #838383;
  width: 80%;
  height: 49px;
  background: #fbfbfb;
  border-radius: 12px;
  box-sizing: border-box;
  transition: 0.3s ease all;
  margin-left: auto;
  margin-right: auto;
}

.otp_subHeading_resend:hover {
  opacity: 0.8;
}
.fPass-info-header_auth img {
  margin-left: 20px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.fPass-info-header_auth > .fPass-info-text_auth {
  margin-left: -30px;
}

.inputStyle {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputStyle input {
  background: #fbfbfb;
  /* Grays/Gray 300 */

  border: 1px solid #cbd5e1;
  border-radius: 6px;
  outline: none;
}

.inputStyle input:focus {
  border: 1px solid #36a6a4;
}

.input-focused {
  border: 1px solid #36a6a4;
}

.otp_subHeading_text {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #878787;
  text-align: center;
  margin-bottom: 20px;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyFPassOtp {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyFPassOtp {
    max-width: 100%;
  }
  .fPass-info-header_auth {
    width: 100vw;
    height: 56px;

    background: #fbfbfb;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  }
  .fPass-info-header_auth img {
    margin-left: 20px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .fPass-info-header_auth > .fPass-info-text_auth {
    margin-left: -30px;
  }
}
