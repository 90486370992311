@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hidden;
  /* overflow-y: auto; */
}
.totalResBodyMemPer {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100vh;
}
.personalInfo-teamCard-info-header {
  width: 100%;
  height: 56px;

  background: #f8f8f8;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* margin-bottom: 20px; */
  overflow-x: hidden;
}
.personalInfo-teamCard-info-header img {
  margin-left: 30px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.personalInfo-teamCard-info-header > .personalInfo-teamCard-info-text {
  margin-left: -50px;
}

.create-teamPersonal-name {
  position: relative;
  width: 100%;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
}

.create-teamPersonal-name input {
  padding: 8px 20px;
  padding-top: 30px;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 90%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  -webkit-appearance: none;
  border-radius: 0;

  color: #333333;
}

.create-teamPersonal-name input:focus {
  /* border: 1.8px solid #6b6b6b; */
  border: none;
  outline: none;
  border-bottom: 2px solid #36a6a4;
}
.create-teamPersonal-name span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.create-teamPersonal-name input:focus + span,
.create-teamPersonal-name input:not(:placeholder-shown) + span {
  top: 7px;
  -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  font-size: 14px;
}
.create-teamPersonal-name input,
.create-teamPersonal-name span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.save-create-teamPersonal-info {
  width: 90%;
  height: 49px;

  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: auto;
  margin-right: auto; */
  position: absolute;
  bottom: 10px;
  margin: 0 auto;
  left: 0;
  right: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #ffffff;
}
.save-create-teamPersonal-info button {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  width: 100%;

  height: 49px;

  /* background: #fcb816; */
  /* opacity: 0.3; */
  border-radius: 6px;
  background: #36a6a4;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hidden;
    /* overflow-y: auto; */
  }

  .personalInfo-teamCard-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }
  .personalInfo-teamCard-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .personalInfo-teamCard-info-header > .personalInfo-teamCard-info-text {
    margin-left: -50px;
  }

  .create-teamPersonal-name {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }

  .create-teamPersonal-name input {
    padding: 8px 20px;
    padding-top: 30px;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 80%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    -webkit-appearance: none;
    border-radius: 0;

    color: #333333;
  }

  .create-teamPersonal-name input:focus {
    /* border: 1.8px solid #6b6b6b; */
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .create-teamPersonal-name span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .create-teamPersonal-name input:focus + span,
  .create-teamPersonal-name input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .create-teamPersonal-name input,
  .create-teamPersonal-name span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .save-create-teamPersonal-info {
    width: 90%;
    height: 49px;

    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: auto;
  margin-right: auto; */
    position: absolute;
    bottom: 10px;
    margin: 0 auto;
    left: 0;
    right: 0;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    color: #ffffff;
  }
  .save-create-teamPersonal-info button {
    border: none;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #ffffff;
    width: 100%;

    height: 49px;

    /* background: #fcb816; */
    /* opacity: 0.3; */
    border-radius: 6px;
    background: #36a6a4;
  }
}
