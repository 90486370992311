.default-BodyGen {
  width: 26%;
  /* height: 172px; */
  border-radius: 20px;

  padding: 10px;
  cursor: pointer;
}
.default-BodyGen:hover {
  opacity: 0.9;
  /* transition: 0.5s; */
}

.default-BodyGen:hover .default-shareForward img {
  transform: translateX(5px);

  transition: 0.5s ease-in-out 0s;
}

.default-share-icon {
  width: 73px;
  height: 61px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.default-shareForward {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 5px;
}

.default-shareName {
  font-style: normal;
  font-weight: 700;
  font-size: 13px;

  color: #171923;
}

.default-forIcon img {
  width: 4px;
  height: 8px;
}

.defaultDes-gen {
  width: 80%;
  word-wrap: break-word;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  color: #171923;
}
