.about-placeholder {
  height: 48px;
  /* background: red; */
  text-align: left;
  padding-top: 2px;
  color: #333333;
  font-weight: 400;
  font-size: 12px;
  opacity: 0.5;

  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  .about-placeholder {
    height: 48px;
    /* background: red; */
    text-align: left;
    padding-top: 2px;
    color: #333333;
    font-weight: 400;
    font-size: 12px;
    opacity: 0.5;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
