.no-auto-zoom {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  user-scalable: no;
  user-zoom: no;
}

.defaultPass_GenCont {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}
.defaultPass_flex_hide {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.defaultPass_flex_hide img {
  position: absolute;
  right: 2%;
}

.defaultPass_ContainGenn {
  width: 80%;
}

.passwordLabelContain {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: -10px;
  margin-top: 30px;
}
.passwordErrorContain {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.passwordformLabelAs {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #ff0000;
  margin-right: 5px;
}

.passwordformLabelText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  color: #171923;
}

.defaultPass-inputField {
  position: relative;
  width: 100%;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-top: 20px;
}

.defaultPassEye {
  cursor: pointer;
}

.defaultPass-inputField input {
  padding: 15px 10px;
  outline: none;
  border: 1px solid #36a6a4;
  width: 100%;

  /* padding: 12px;
gap: 10px;

width: 272px;
height: 46px; */

  background: #f8fafc;

  border-radius: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;

  color: #333333;
  box-sizing: border-box;
  -webkit-appearance: none;
}
