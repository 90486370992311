.totalResBodySignup {
  max-width: 500px;
  margin: auto;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}
.signup-info-family {
  background: #fbfbfb;
  padding-bottom: 50px;
  position: relative;
}

.signup-save-form {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
}

.signup_btn-save {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.signup_btn-save input {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 15px 10px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 80%;
  height: 49px;

  background: #36a6a4;
  border-radius: 12px;
  box-sizing: border-box;
  transition: 0.3s ease all;
}
.signup_btn-save input:hover {
  background: #248381;
}
.signupValidCompo {
  margin-top: 20px;
}

.signUpValidGen {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  width: 80%;
}

.signUpValidGen {
  margin-top: 5px;
}

.signUpValiGen_img {
  margin-right: 5px;
}

.signUpValiText {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #838383;
}

@media only screen and (max-width: 1400px) {
  .totalResBodySignup {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodySignup {
    max-width: 100%;
  }

  .signup-save-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 10px 15px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 80%;
    height: 49px;

    background: #36a6a4;
    border-radius: 12px;
    box-sizing: border-box;
  }
}
