/* The Modal (background) */
.success-temp-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */

  background: rgba(23, 25, 35, 0.8);
  backdrop-filter: blur(20px);
}

/* Modal Content */
.success-temp-content {
  position: relative;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
  text-align: center;
  /* background-color: #fefefe; */
  /* margin: auto; */
  padding: 0;
  /* border: 1px solid #888; */
  width: 90%;

  /* width: 267px; */
  height: 90%;

  background: transparent;

  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.4s;
  animation-name: animateright;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  border-radius: 12px;
}

/* Add Animation */
@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

/* .success-temp-body {
  padding: 2px 16px;
} */

.success-temp-text-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
  color: #333333;

  display: flex;
  justify-content: center;
  align-items: center;
}

.success-temp-text-2 img {
  width: 117px;
  height: 115px;
}

.success-temp-fulltext {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.02em;

  color: #ffffff;
  margin-top: 30px;
}
.success-temp-continue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  width: 100%;

  background: #fcb816;
  border-radius: 3px;
  margin-top: 30px;

  font-weight: 700;
  font-size: 14px;

  color: #e9e9e9;
}
