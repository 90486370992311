body {
  background: #f2f2f2;
}

.totalResBodyTeamTempp {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100vh;
}
.active-temp-info-header {
  width: 100%;
  height: 56px;

  background: #304457;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* margin-bottom: 20px; */
  overflow-x: hidden;
}
.active-temp-info-header img {
  margin-left: 15px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.active-temp-info-header > .active-temp-info-text img {
  margin-left: 0px;
}

.active-temp-label {
  width: 100%;
  height: 38px;

  background: #d9d9d9;
  opacity: 0.2;

  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  display: flex;
  justify-content: center;
  align-items: center;

  /* Text color */

  color: #333333;
}
.no-active-temp-dis {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
}

.add-temp-btn {
  width: 90%;
  height: 49px;

  background: #36a6a4;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-left: auto;
  margin-right: auto; */
  position: absolute;
  bottom: 10px;
  margin: 0 auto;
  left: 0;
  right: 0;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #ffffff;
}

@media only screen and (max-width: 600px) {
  .active-temp-info-header {
    width: 100vw;
    height: 56px;

    background: #304457;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }
  .active-temp-info-header img {
    margin-left: 15px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .active-temp-info-header > .active-temp-info-text img {
    margin-left: 0px;
  }

  .active-temp-label {
    width: 100%;
    height: 38px;

    background: #d9d9d9;
    opacity: 0.2;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;

    display: flex;
    justify-content: center;
    align-items: center;

    /* Text color */

    color: #333333;
  }
  .no-active-temp-dis {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
  }

  .add-temp-btn {
    width: 90%;
    height: 49px;

    background: #36a6a4;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: auto;
  margin-right: auto; */
    position: absolute;
    bottom: 10px;
    margin: 0 auto;
    left: 0;
    right: 0;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    color: #ffffff;
  }
}
