.connectionCard_bodyGen_member {
  width: 100%;
  height: 93px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  background: #fff;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;

  border-radius: 20px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
}

/* .connectionCard_bodyGen_member:hover {
  transform: scale(1.05);
} */

.connectionCard_SubBody_member {
  display: flex;
  align-items: center;
  /* margin-top: 7px; */
  margin-left: 10px;
}

.connectionCard_img_member {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid #e9e9e9;
  text-transform: capitalize;
  color: #333;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  width: 50px;
  height: 50px;
}

.connectModal_viewconnect_member {
  margin-top: 25px;
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.connectModal_moveconnect_member {
  margin-top: 25px;

  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.connectModal_removeconnect_member {
  margin-top: 25px;

  color: #838383;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.connectionCard_Right_member {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.connectionCard_RightName_member {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #171923;
}

.connects_detailFlex_member {
  display: flex;
  align-items: center;
}

.connects_detailFlex_membertitle {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.connects_detailsIcon_member {
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.connectionCard_RightDesc_member {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #838383;
  /* width: 100%; */
  width: 150px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
}

.connectionCard_date_member {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  color: #ababab;
  margin-right: 25px;
  /* position: absolute; */
  /* right: 10px;
  top: 7px; */
}

.connections_optIcon_member {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.connections_optIcon_member_iconFam {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  filter: drop-shadow(
      0px 16.96808624267578px 16.96808624267578px rgba(0, 0, 0, 0.04)
    )
    drop-shadow(0px 33.93617248535156px 42.42021560668945px rgba(0, 0, 0, 0.15));
}

.connections_optIcon_member img {
  cursor: pointer;
}

.connections_dateOpt_member {
  width: fit-content;
  /* position: absolute;
  bottom: 0px; */
  /* width: 100%; */
  /* display: flex;
  flex-direction: column; */
  margin-top: 30%;
}

.menu_genBody_member {
  width: 175px;
  /* height: 157px; */

  height: fit-content;
  padding-bottom: 25px;
  position: absolute;
  right: -15px;
  top: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  z-index: 10000;
  padding-left: 10px;
}

.connectModal_mailGen_member {
  /* display: flex; */
  /* align-items: flex-start; */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.connectModal_mailIcon_member {
  margin-right: 5px;
}

.connectModal_textHead_member {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #000000;
}

.connectModalMail_text_member {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #838383;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.conectModal_phoneGen_member {
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}

.connectModalPhone_icon_member {
  margin-right: 5px;
}

.connectModalPhone_text_member {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #838383;

  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.connections_status_pend {
  color: #f59e0b;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  margin-top: 15px;
}
@media only screen and (max-width: 600px) {
  .connectionCard_bodyGen_member:hover {
    transform: none;
    box-shadow: none;
  }
}

@media only screen and (max-width: 425px) {
  .connectionCard_date_member {
    font-size: 8px;

    color: #ababab;
    margin-right: 8px;
  }

  /* .connectionCard_RightDesc_member {
    width: 120px;
  } */

  /* .connectionCard_RightName_member {
    width: 120px;
  } */
}

@media only screen and (max-width: 400px) {
  .connectionCard_date_member {
    font-size: 8px;

    color: #ababab;
    margin-right: 8px;
  }

  .connectionCard_RightDesc_member {
    width: 120px;
  }

  .connectionCard_RightName_member {
    width: 120px;
  }
}

@media only screen and (max-width: 350px) {
  .connectionCard_RightDesc_member {
    width: 90px;
  }

  .connectionCard_RightName_member {
    width: 100px;
  }
}
