.totalResBodyTeamHome {
  max-width: 500px;
  margin: auto;
  position: relative;
}

.Indexmodal-container {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 500px;
  height: 100%;
  /* margin-left: auto;
  margin-right: auto; */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
}

.Indexmodal-content {
  position: relative;
  width: 70%;
  /* height: 100px; */
  border-radius: 14px;
  background: #fbfbfb;
  padding: 20px;
  /* border-radius: 8px; */
  animation: slide-in 0.3s ease-in-out;
}

@keyframes slide-in {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
.teamHome-info-header {
  width: 100%;
  height: 56px;

  background: #171923;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
  overflow-x: hidden;
}

.teamHome-info-header > .teamHome-info-text {
  margin-left: 0px;
}

.teamHome-info-family {
  background: #f8fafc;
  /* padding-bottom: 100px; */
  height: 100vh;
  margin-top: 40px;
  padding-top: 10px;
}

.no_teamTemp_header {
  color: #1e293b;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.no_teamTemp_sub {
  color: #64748b;
  text-align: center;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.create_team_btn {
  position: absolute;
  bottom: 70px;
  border-radius: 14px;
  background: #36a6a4;
  height: 45px;
  width: 80%;
  outline: none;
  border: none;
  transition: 0.5s ease-in-out 0s;
  margin-top: 25px;

  color: #fff;
  font-family: Poppins;

  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}

.create_team_btn:hover {
  border-radius: 10px;
  opacity: 0.8;
}
.no_team_tempGen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

.no_teamTemp_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.true_team_tempGen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.index_heading_parent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.index_heading {
  color: #333;

  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}

.index_para {
  color: #616161;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 10px;
}
.index_heading_close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit_companyNameGenFam {
  width: 100%;
  background: #f1f5f9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 20px;
}

.companyName_headerFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.company_nameHText {
  color: #333;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}

.company_nameEditTextName {
  color: #36a6a4;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

.companyName___ {
  display: flex;
  width: 80%;
  height: 48px;
  padding-left: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;

  border-radius: 16px;
  border: 1.5px solid #e2e8f0;
  background: #e2e8f0;

  color: #94a3b8;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
}

.team_templatehead_section {
  /* width: 80%;
  margin-left: auto;
  margin-right: auto; */
  width: 100%;
  color: #36a6a4;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyTeamHome {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyTeamHome {
    max-width: 100%;
  }
  .teamHome-info-header {
    width: 100vw;
    height: 56px;

    background: #171923;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;
    overflow-x: hidden;
  }

  .teamHome-info-header > .teamHome-info-text {
    margin-left: 0px;
  }

  .teamHome-info-family {
    background: #f8fafc;
    /* padding-bottom: 100px; */
  }

  .Indexmodal-container {
    width: 100%;
  }
}
