.totalResSocialBody__teamSocial {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.social-info-family__teamSocial {
  background: #fbfbfb;
  /* padding-bottom: 100px; */
  min-height: 100vh;
  position: relative;
}

.flex-inputs__teamSocial {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  /* align-items: center; */
}

.custom-selectBtnSocial__teamSocial {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 130px;
  height: 46px;

  background: #f8fafc;
  /* Grays/Gray 200 */

  border-left: 1.5px solid #e2e8f0;

  border-top: 1.5px solid #e2e8f0;

  border-bottom: 1.5px solid #e2e8f0;
  border-radius: 18px 0px 0px 18px;
  margin-right: -2px;
  overflow: hidden;
}

.custom-selectBtnSocial__teamSocial:hover {
  cursor: pointer;
}

.custom-selectBtnTextSocial__teamSocial {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #64748b;
  margin-left: 35px;
  margin-right: 5px;
}

.custom-selectedOptionGen__teamSocial {
  display: flex;
  align-items: center;
}

.customSelected-IconGen__teamSocial {
  margin-right: 2px;
  margin-left: 8px;
  width: 25px;
  height: 25px;
}

.custom-selectBtnTextSocialSe__teamSocial {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  color: #64748b;
  margin-left: 5px;
  margin-right: 5px;
  width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-selectAncorSocial__teamSocial img {
  width: 10px;
  height: 15px;
  margin-right: 10px;
}

.social-remove-section__teamSocial {
  /* margin-top: -10px; */
  position: relative;
}

.prefix_genSocial__teamSocial {
  position: absolute;
  left: 3%;
  top: 30%;
  z-index: 100;
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}

.remove-social-icon__teamSocial {
  width: 50px;
  height: 40px;
  /* background: #f8fafc; */

  background: linear-gradient(
    269.96deg,
    rgba(248, 250, 252, 0.67) 69.38%,
    rgba(248, 250, 252, 0) 105.08%
  );
  z-index: 100000;
  position: absolute;
  right: -3px;
  top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 16px 16px 0px;
}

.select-modal-bodySocial__teamSocial {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%; /* Full width */
  max-width: 500px;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.3);
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}

.select-modalBody-contSocial__teamSocial {
  position: absolute;
  bottom: 0;
  height: 83%;
  width: 100%;

  background: #f2f2f2;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  left: 0;
  right: 0;

  border-radius: 17px 17px 0px 0px;
  /* padding: 10px; */
}

.url_validSocial__teamSocial {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #ff0000;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}
.select-modalHeaderSocial__teamSocial {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 80px;
  margin-right: auto;
  margin-left: auto;
}

.select-modalHeader-textSocial__teamSocial {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #171923;
  /* margin-left: 20px; */
}

.select-modalHeader-canBtnSocial__teamSocial {
  /* width: 36px;
  height: 36px; */
  /* margin-right: 20px; */
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.select-modalHeader-canBtnSocial__teamSocial:hover {
  transform: scale(1.1);
}

.select-modal-optionSocial__teamSocial {
  width: 90%;
  height: 48px;

  /* Grays/Gray 50 */

  background: #f8fafc;
  /* Grays/Gray 200 */

  border: 1.5px solid #e2e8f0;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 10px;
  margin-right: 10px; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  z-index: 50000000000000;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.select-modal-optionSocial__teamSocial:hover {
  border: 1.5px solid #bcbcbd;
}

.socialSelectIcon-famm__teamSocial {
  display: flex;
  align-items: center;
}

.socialSelect-Iconn__teamSocial {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.socialSelect-Iconn__teamSocial img {
  width: 100%;
  height: 100%;
}

.select-modal-optionTextSocial__teamSocial {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;

  color: #171923;
  margin-left: 15px;
}

.select-modal-optionIconSocial__teamSocial {
  /* width: 16px;
  height: 16px; */
  margin-right: 15px;
}

.add-social-family__teamSocial {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;

  /* identical to box height */

  display: flex;
  justify-content: flex-end;

  color: #333333;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.add-social-cont__teamSocial {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  margin-top: 10px;
  /* color: #36a6a4; */
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  cursor: pointer;
}
.add-social-cont__teamSocial {
  margin-right: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .totalResSocialBody__teamSocial {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  .custom-selectBtnSocial__teamSocial {
    width: 130px;
  }

  .select-modal-bodySocial__teamSocial {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .custom-selectBtnSocial__teamSocial {
    width: 35vw;
  }
  .totalResSocialBody__teamSocial {
    max-width: 100%;
  }

  .social-info-family__teamSocial {
    background: #fbfbfb;
    padding-bottom: 100px;
  }

  .flex-inputs__teamSocial {
    display: flex;
    justify-content: center;
  }

  .custom-selectBtnTextSocialSe__teamSocial {
    width: 90px;
  }

  .url_validSocial__teamSocial {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #ff0000;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .add-social-family__teamSocia {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 10px;

    display: flex;
    justify-content: flex-end;

    color: #333333;
    margin-right: 5%;
  }
  .remove-social-icon__teamSocial {
    z-index: 100000;
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .select-modal-bodySocial__teamSocial {
    max-width: 100%;
  }
}

@media only screen and (max-width: 470px) {
  .custom-selectBtnTextSocialSe__teamSocial {
    width: 70px;
  }
}

@media only screen and (max-width: 416px) {
  .custom-selectBtnTextSocialSe__teamSocial {
    width: 60px;
  }
}

@media only screen and (max-width: 400px) {
  .custom-selectBtnTextSocialSe__teamSocial {
    width: 50px;
  }
}

@media only screen and (max-width: 361px) {
  .customSelected-IconGen__teamSocial {
    margin-right: 2px;
    margin-left: 3px;
  }
  .custom-selectBtnTextSocial__teamSocial {
    margin-left: 2px;
    margin-right: 2px;
  }

  .custom-selectBtnTextSocialSe__teamSocial {
    margin-left: 2px;
    margin-right: 2px;
  }
}

@media only screen and (max-width: 350px) {
  .custom-selectBtnTextSocialSe__teamSocial {
    width: 40px;
  }
}

@media only screen and (max-width: 320px) {
  .custom-selectBtnTextSocialSe__teamSocial {
    width: 30px;
  }
}

@media only screen and (max-width: 295px) {
  .custom-selectBtnTextSocialSe__teamSocial {
    width: 20px;
  }
}
