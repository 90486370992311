.inputFieldPersonalize {
  position: relative;
  /* width: 550px; */

  width: 450px;
  height: 48px;

  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.inputFieldPersonalize input {
  padding: 8px 20px;
  padding-top: 25px;
  border: 1.5px solid #e2e8f0;
  /* border-bottom: 1px solid #cbd5e0; */
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 90%;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: 18px;
  color: #333333;
  background: #f8fafc;
}

.inputFieldPersonalize input:-webkit-autofill,
.inputFieldPersonalize input:-webkit-autofill:hover,
.inputFieldPersonalize input:-webkit-autofill:focus,
.inputFieldPersonalize input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f8fafc inset !important;
}

.inputFieldPersonalize input:focus {
  /* border: 1.8px solid #6b6b6b; */
  outline: none;
  border: 1.5px solid #36a6a4;
}
.inputFieldPersonalize span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.inputFieldPersonalize input:focus + span,
.inputFieldPersonalize input:not(:placeholder-shown) + span {
  top: 7px;
  /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
  /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
  transform: translateY(-10%) translateX(-8.5px);
  padding-left: 5%;
  font-size: 10px;
}
.inputFieldPersonalize input,
.inputFieldPersonalize span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.inputFieldPersonalize-half {
  position: relative;
  width: 250px;
  height: 58px;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-top: 20px; */
}

.inputFieldPersonalize-half input {
  padding: 8px 20px;
  padding-top: 10px;
  border: 1.5px solid #e2e8f0;
  /* border-bottom: 1px solid #cbd5e0; */
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: calc(90% - 40px);
  /* width: 90%; */

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: 16px;
  color: #333333;
  background: #f8fafc;
}

.inputFieldPersonalize-half input:focus {
  /* border: 1.8px solid #6b6b6b; */
  outline: none;
  border: 1.5px solid #36a6a4;
}
.inputFieldPersonalize-half span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.inputFieldPersonalize-half input:focus + span,
.inputFieldPersonalize-half input:not(:placeholder-shown) + span {
  top: 7px;
  /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
  /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
  transform: translateY(-10%) translateX(-8.5px);
  padding-left: 10%;
  font-size: 10px;
}
.inputFieldPersonalize-half input,
.inputFieldPersonalize-half span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

/* .inputFieldPersonalize-halfSocial {
  position: relative;
  width: 350px;

} */

/* .inputFieldPersonalize-halfSocial input:-webkit-autofill,
.inputFieldPersonalize-halfSocial input:-webkit-autofill:hover,
.inputFieldPersonalize-halfSocial input:-webkit-autofill:focus,
.inputFieldPersonalize-halfSocial input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f8fafc inset !important;
} */

/* .inputFieldPersonalize-halfSocial input {
  padding-top: 10px;
  padding-bottom: 4px;
  border: none;
  border-top: 1.5px solid #e2e8f0;
  border-right: 1.5px solid #e2e8f0;
  border-bottom: 1.5px solid #e2e8f0;

  width: 100%;
  height: 32px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  -webkit-appearance: none;
  border-radius: 0px 16px 16px 0px;
  color: #333333;
  background: #f8fafc;
} */

/* .inputFieldPersonalize-halfSocial input:focus {
  outline: none;
}
.inputFieldPersonalize-halfSocial span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;


  color: #333333;
} */
/* 
.inputFieldPersonalize-halfSocial input:focus + span,
.inputFieldPersonalize-halfSocial input:not(:placeholder-shown) + span {
  top: 7px;

  transform: translateY(-10%) translateX(-8.5px);
  padding-left: 10%;
  font-size: 10px;
}
.inputFieldPersonalize-halfSocial input,
.inputFieldPersonalize-halfSocial span {

  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
} */

/* .inputFieldPersonalize-halfLink {
  position: relative;
  width: 450px;

} */

/* .inputFieldPersonalize-halfLink input {
  padding-top: 14px;
  padding-bottom: 4px;
  border: 1.5px solid #e2e8f0;


  width: 100%;
  height: 32px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  -webkit-appearance: none;
  border-radius: 16px;
  color: #333333;
  background: #f8fafc;
} */

/* .inputFieldPersonalize-halfLink input:-webkit-autofill,
.inputFieldPersonalize-halfLink input:-webkit-autofill:hover,
.inputFieldPersonalize-halfLink input:-webkit-autofill:focus,
.inputFieldPersonalize-halfLink input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f8fafc inset !important;
} */

/* .inputFieldPersonalize-halfLink input:focus {
  outline: none;
} */
/* .inputFieldPersonalize-halfLink span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;


  color: #333333;
} */

/* .inputFieldPersonalize-halfLink input:focus + span,
.inputFieldPersonalize-halfLink input:not(:placeholder-shown) + span {
  top: 7px;
 
  transform: translateY(-10%) translateX(-8.5px);
  padding-left: 10%;
  font-size: 10px;
} */
/* .inputFieldPersonalize-halfLink input,
.inputFieldPersonalize-halfLink span {

  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
} */

.default-save-form {
  margin-top: 20px;
  margin-bottom: 50px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in-out 0s;
}
.default-save-form input {
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  height: 49px;

  background: #36a6a4;
  opacity: 1;
  border-radius: 12px;
  cursor: not-allowed;
  transition: 0.5s ease-in-out 0s;
}

/* .default-save-form input:hover {
  background: #248381;
} */

@media only screen and (max-width: 1400px) {
  /* .totalResContactBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  } */
  .default-save-form {
    width: 100%;
  }
  .inputFieldPersonalize {
    width: 450px;
  }

  .inputFieldPersonalize-half {
    width: 200px;
  }

  /* .inputFieldPersonalize-halfSocial {
    width: 300px;
  } */

  .inputFieldPersonalize-halfLink {
    position: relative;
    width: 370px;
  }
}

@media only screen and (max-width: 600px) {
  .inputFieldPersonalize {
    position: relative;
    width: 100vw;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    height: 48px;
  }

  .inputFieldPersonalize input {
    padding: 8px 20px;
    padding-top: 25px;
    padding-right: 35px;

    border: 1.5px solid #e2e8f0;
    /* border-bottom: 1px solid #cbd5e0; */
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 77%;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    -webkit-appearance: none;
    border-radius: 18px;
    color: #333333;
    background: #f8fafc;
  }

  .inputFieldPersonalize input:focus {
    /* border: 1.8px solid #6b6b6b; */
    outline: none;
    border: 1.5px solid #36a6a4;
  }
  .inputFieldPersonalize span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 35px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .inputFieldPersonalize input:focus + span,
  .inputFieldPersonalize input:not(:placeholder-shown) + span {
    top: 7px;
    /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 10%;
    font-size: 10px;
  }
  .inputFieldPersonalize input,
  .inputFieldPersonalize span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .inputFieldPersonalize-half {
    position: relative;
    width: 100%;
    height: 58px;

    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 20px; */
  }

  .inputFieldPersonalize-half input {
    padding: 8px 20px;
    padding-top: 10px;
    border: 1.5px solid #e2e8f0;
    /* border-bottom: 1px solid #cbd5e0; */
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 80%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    -webkit-appearance: none;
    border-radius: 16px;
    color: #333333;
    background: #f8fafc;
  }

  .inputFieldPersonalize-half input:focus {
    /* border: 1.8px solid #6b6b6b; */
    outline: none;
    border: 1.5px solid #36a6a4;
  }
  .inputFieldPersonalize-half span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .inputFieldPersonalize-half input:focus + span,
  .inputFieldPersonalize-half input:not(:placeholder-shown) + span {
    top: 7px;
    /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 10%;
    font-size: 10px;
  }
  .inputFieldPersonalize-half input,
  .inputFieldPersonalize-half span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  /* .inputFieldPersonalize-halfSocial {
    position: relative;
    width: 80%;

  } */

  /* .inputFieldPersonalize-halfSocial input {
    padding: 4px 20px;
    padding-top: 10px;
    border: none;
    border-top: 1.5px solid #e2e8f0;
    border-right: 1.5px solid #e2e8f0;
    border-bottom: 1.5px solid #e2e8f0;

    width: 100%;
    height: 32px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    -webkit-appearance: none;
    border-radius: 0px 16px 16px 0px;
    color: #333333;
    background: #f8fafc;
  } */

  /* .inputFieldPersonalize-halfSocial input:focus {
    outline: none;
  
  } */
  /* .inputFieldPersonalize-halfSocial span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;


    color: #333333;
  } */

  /* .inputFieldPersonalize-halfSocial input:focus + span,
  .inputFieldPersonalize-halfSocial input:not(:placeholder-shown) + span {
    top: 7px;
  
    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 10%;
    font-size: 10px;
  } */
  /* .inputFieldPersonalize-halfSocial input,
  .inputFieldPersonalize-halfSocial span {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  } */

  .inputFieldPersonalize-halfLink {
    position: relative;

    width: 69vw;
    /* box-sizing: border-box; */

    /* height: 58px; */

    /* margin-left: 10px;
    margin-right: auto; */
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    /* margin-top: 20px; */
  }

  .inputFieldPersonalize-halfLink input {
    /* box-sizing: border-box; */
    padding: 4px;
    padding-top: 10px;
    border: 1.5px solid #e2e8f0;

    width: 100%;
    height: 32px;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    -webkit-appearance: none;
    border-radius: 16px;
    color: #333333;
    background: #f8fafc;
  }

  .inputFieldPersonalize-halfLink input:focus {
    outline: none;
    /* border: 1.5px solid #36a6a4; */
  }
  .inputFieldPersonalize-halfLink span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .inputFieldPersonalize-halfLink input:focus + span,
  .inputFieldPersonalize-halfLink input:not(:placeholder-shown) + span {
    top: 7px;
    /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 10%;
    font-size: 10px;
  }
  .inputFieldPersonalize-halfLink input,
  .inputFieldPersonalize-halfLink span {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .default-save-form {
    margin-top: 20px;
    margin-bottom: 50px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .default-save-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 90vw;
    height: 49px;

    background: #36a6a4;
    opacity: 1;
    border-radius: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .inputFieldPersonalize span {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 430px) {
  .inputFieldPersonalize span {
    padding-left: 20px;
  }
}
