.sectionHeader-CanFam {
  width: 100%;
  height: 56px;

  /* background: #171923; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  box-sizing: border-box;
}

/* .sectionHeader-CanFam:hover {
  color: #e9e9e9;
} */
.sectionHeader-CanFam-btn {
  cursor: pointer;
}

.sectionHeader-CanFam-btn:hover {
  color: #d1cdcd;
}
