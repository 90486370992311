.team_switchButtonGen_ {
  border-radius: 21px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.08);
  height: 45px;
  background: #fff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  box-sizing: border-box;
}

/* .react-switcher {
  vertical-align: middle;
  margin-left: 4px;
} */

.team_switchButton_lockText {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.28px;
  margin-left: 10px;
}

.team_switchlogin_switch_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.react-teamswitch {
  margin-right: 5px;
}
/* .react-teamswitch > .react-switch-handle {
  width: 25px !important;
  height: 25px !important;
  top: 2px !important;
} */
