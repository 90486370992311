.totalResBodyTeamLeftNav {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
}

.team-top-navbar-rel {
  position: relative;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.team-sidemenu-family-cont {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #ffffff;
  padding-top: 40px;
  z-index: 100000;
  /* overflow-y: hidden; */
}
.team-sidemenu-user-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.team-cancel-menu {
  margin-left: 40px;
  opacity: 0;
}
.team-menu-avatar-cont {
  margin-right: 40px;
}
.team-nav-username {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #333333;
}
.team-nav-email {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin-top: 1px;
  color: #36a6a4;

  opacity: 0.7;
}
.team-side-menu-option {
  display: flex;

  align-items: center;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  height: 47px;

  /* White */

  background: #f5f5f5;
  border-bottom: 1px solid #cbd5e0;
}

.team-left-align-user-details {
  display: flex;
  align-items: center;
}

.team-details-left-cont {
  margin-left: 70px;
}
.team-added-to-fit {
  width: 41px;
  height: 40px;
  margin-right: 40px;
  background: green;
}

.team-options-img {
  margin-left: 20px;
  width: 25px;
  height: 25px;
}
.team-option-label {
  font-family: "Poppins";
  font-style: normal;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;

  /* Text color */

  color: #333333;
  margin-left: 70px;
  text-decoration: none;
}
.team-cont-main {
  position: relative;
  /* padding-bottom: 100px; */
  height: 600px;
  /* padding-bottom: 50px; */
}
.team-logout-family-cont {
  margin-top: 30px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;

  /* position: absolute; */
  width: 90%;
  height: 55px;
  bottom: 100px;
  /* Primary/light */

  background: #f5f5f5;
  border-radius: 6px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #fa1414;
  /* z-index: 10000000; */
}
.team-logout-family-cont img {
  margin-right: 10px;
  /* height: 18px;
    width: 18px; */
}
.team-logout-text {
  margin-top: -4px;
}

@media only screen and (max-width: 600px) {
  .team-top-navbar-rel {
    position: relative;
  }
  .team-sidemenu-family-cont {
    /* display: none; */
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #ffffff;
    padding-top: 40px;
    z-index: 100000;
    /* overflow-y: hidden; */
  }
  .team-sidemenu-user-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
  }
  .team-cancel-menu {
    margin-left: 40px;
    opacity: 0;
  }
  .team-menu-avatar-cont {
    margin-right: 40px;
  }
  .team-nav-username {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    color: #333333;
  }
  .team-nav-email {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 1px;
    color: #36a6a4;

    opacity: 0.7;
  }
  .team-side-menu-option {
    display: flex;

    align-items: center;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
    height: 47px;

    /* White */

    background: #f5f5f5;
    border-bottom: 1px solid #cbd5e0;
  }

  .team-left-align-user-details {
    display: flex;
    align-items: center;
  }

  .team-details-left-cont {
    margin-left: 70px;
  }
  .team-added-to-fit {
    width: 41px;
    height: 40px;
    margin-right: 40px;
    background: green;
  }

  .team-options-img {
    margin-left: 20px;
    width: 25px;
    height: 25px;
  }
  .team-option-label {
    font-family: "Poppins";
    font-style: normal;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;

    /* Text color */

    color: #333333;
    margin-left: 70px;
    text-decoration: none;
  }
  .team-cont-main {
    position: relative;
    /* padding-bottom: 100px; */
    height: 600px;
    /* padding-bottom: 50px; */
  }
  .team-logout-family-cont {
    margin-top: 30px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    align-items: center;

    /* position: absolute; */
    width: 100%;
    height: 55px;
    bottom: 100px;
    /* Primary/light */

    background: #f5f5f5;
    border-radius: 6px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;

    color: #fa1414;
    /* z-index: 10000000; */
  }
  .team-logout-family-cont img {
    margin-right: 10px;
    /* height: 18px;
    width: 18px; */
  }
  .team-logout-text {
    margin-top: -4px;
  }
}
