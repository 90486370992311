.remove-default-icon {
  width: 50px;
  height: 40px;
  /* background: #f8fafc; */

  background: linear-gradient(
    269.96deg,
    rgba(248, 250, 252, 0.67) 69.38%,
    rgba(248, 250, 252, 0) 105.08%
  );
  z-index: 100000;
  position: absolute;
  right: -3px;
  top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 16px 16px 0px;
}

.remove-defaultInput-icon {
  width: 50px;
  height: 40px;
  /* background: #f8fafc; */

  background: linear-gradient(
    269.96deg,
    rgba(248, 250, 252, 0.67) 69.38%,
    rgba(248, 250, 252, 0) 105.08%
  );
  z-index: 100000;
  position: absolute;

  right: 12%;
  top: 25px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 16px 16px 0px;
}

.remove-defaultSecond-icon {
  width: 50px;
  height: 40px;
  /* background: #f8fafc; */

  background: linear-gradient(
    269.96deg,
    rgba(248, 250, 252, 0.67) 69.38%,
    rgba(248, 250, 252, 0) 105.08%
  );
  z-index: 100000;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 16px 16px 0px;
}

@media only screen and (max-width: 600px) {
  .remove-defaultInput-icon {
    right: 8%;
  }

  .remove-defaultSecond-icon {
    right: 10%;
  }
}

/* @media only screen and (max-width: 460px) {
  .remove-defaultSecond-icon {
    right: 10px;
  }
} */
