.flexWordsBullet {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.backdropBullet {
  margin-right: 5px;
  margin-top: -5px;
}
.backdropBullet img {
  width: 10px;
  height: 10px;
}

.backdropBullet-text {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  color: #333333;
}
