.totalResBodyFeedback {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  background: #f2f2f2;
  position: relative;
}

.feedbackBody-padding {
  /* padding-right: 20px;
  padding-left: 20px; */
  height: 100vh;
  padding-top: 10px;
  margin-top: 50px;
}

.feedbackBody-head {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
}
.feedbackBody-headImg {
  margin-right: 10px;
}

.feedbackBody-headText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* color: #36a6a4; */
}

.feedback-textQues {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
  margin-top: 10px;
  margin-bottom: 40px;
  margin-left: 20px;
  margin-right: 20px;
}

.feedbackGenFlexOne {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* width: 100%; */
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
}

.feedbackMarginLine {
  border-bottom: 1px solid #dfdfdf;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.feedbackMarginText {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;

  color: #171923;
  /* margin-left: 20px; */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.feedBack-form-cont {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyFeedback {
    max-width: 500px;
  }

  .connect-blurBackground {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }

  .connect-blurBackground {
    max-width: 100%;
  }

  .totalResBodyFeedback {
    max-width: 100%;
  }
}

@media only screen and (max-width: 385px) {
  .feedback-textQues {
    font-size: 12px;
  }
}

@media only screen and (max-width: 333px) {
  .feedback-textQues {
    font-size: 10px;
  }
}
