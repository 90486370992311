.totalResBodyLog_modalGenDelete {
  width: 500px;
  margin-right: auto;
  margin-left: auto;
}
.logoutGen-modalGenDelete {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%; /* Full width */
  max-width: 500px;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.6);

  /* opacity: 0.7; */
  backdrop-filter: blur(3px);
}

.logoutModalGen-content_modalGenDelete {
  position: absolute;
  bottom: 0;
  height: fit-content;
  width: 100%;

  background: #f2f2f2;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;

  /* transition: 0.3s ease-in-out 0s; */
  left: 0;
  right: 0;

  border-radius: 17px 17px 0px 0px;
  /* padding: 10px; */
}

@-webkit-keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.logoutModal-cancelFlex_modalGenDelete {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}

.logout-modal-btnText_modalGenDelete {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  color: #ff0000;
}

.close_logout_btn_modalGenDelete {
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
}

.close_logout_btn_modalGenDelete img {
  width: 36px;
  height: 36px;
}

.close_logout_btn_modalGenDelete:hover {
  transform: scale(1.1);
}

.logout-preTextF_modalGenDelete {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #171923;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  margin-top: 5px;
}

.logout-Mtext_modalGenDelete {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;

  opacity: 0.77;

  width: 90%;
  text-align: left;
  word-wrap: break-word;
  margin-left: 20px;
  margin-right: 20px;
  /* margin-top: 10px; */
  margin-bottom: 10px;
}

.logout-btnsFlex_modalGenDelete {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 50px;
}

.logout-Admincontinue-btn_modalGenDelete {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  height: 49px;

  /* background: #36a6a4; */
  border-radius: 14px;
  margin-top: 20px;
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}

.logout-Admincontinue-btn_modalGenDelete:hover {
  /* background: #248381; */

  opacity: 0.8;
  border-radius: 10px;
}

.logout-Admincancel-btn_modalGenDelete {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  height: 49px;

  background: #f2f2f2;
  border-radius: 14px;
  box-sizing: border-box;
  border: 1px solid #171923;
  margin-top: 10px;
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}
.logout-Admincancel-btn_modalGenDelete:hover {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
  border-radius: 10px;
}

@media only screen and (max-width: 1400px) {
  .logoutGen-modalGenDelete {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .logoutGen-modalGenDelete {
    max-width: 100%;
  }
}
