.preAcct_getCard {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 14px 11px; */
  margin-left: auto;
  margin-right: auto;

  width: 90%;
  height: 49px;

  background: #36a6a4;
  border-radius: 12px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  transition: 0.3s ease all;
  cursor: pointer;
}

.preAcct_getCard:hover {
  background: #248381;
}
