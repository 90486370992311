.totalResBodyChangePass {
  max-width: 500px;
  margin: auto;
  position: relative;
}

.change_password-info-family {
  background: #f2f2f2;
  padding-bottom: 100px;
  height: 100vh;
}
.change_password-info-header {
  width: 100%;
  height: 56px;

  /* background: #171923; */

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
  overflow-x: hidden;
  position: fixed;
  width: 500px;
  z-index: 9000;
  margin-top: -50px;
}

.password_change-input-family {
  margin-top: 50px;
  padding-top: 20px;
}

.chgPassValidCompo {
  margin-top: 20px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.chgPassValidGen {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;

  width: 100%;
}

.chgPassValidGen {
  margin-top: 5px;
}

.chgPassValiGen_img {
  margin-right: 5px;
}

.chgPassValiText {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #838383;
}

.confirmPass_matchGen {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  color: #ff0000;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.change_password-info-header > .change_password-info-text {
  margin-left: 0px;
}

.password_text_heading {
  width: 100%;
  height: 31px;

  background: #e9e9e9;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
}

.changePasswordForm-gen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /* width: 90%;
  margin-left: auto;
  margin-right: auto; */
}
.change_pass-inputDefault {
  position: relative;
  width: 92%;
  height: 48px;

  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-right: auto;
  margin-left: auto;
}

.change_pass-inputDefault input {
  padding: 8px 40px;
  padding-top: 25px;
  border: 1.5px solid #e2e8f0;
  /* border-bottom: 1px solid #cbd5e0; */
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 80%;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: 18px;
  color: #333333;
  background: #f8fafc;
}

.change_pass-inputDefault input:focus {
  /* border: 1.8px solid #6b6b6b; */
  outline: none;
  border: 1.5px solid #6b6b6b;
}
.change_pass-inputDefault span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 25px;
  top: 54%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.change_pass-inputDefault input:focus + span,
.change_pass-inputDefault input:not(:placeholder-shown) + span {
  top: 7px;
  /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
  /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
  transform: translateY(-10%) translateX(-8.5px);
  padding-left: 5%;
  font-size: 10px;
}
.change_pass-inputDefault input,
.change_pass-inputDefault span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.change_password-inputField {
  position: relative;
  width: 100%;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.change_password-inputField input {
  padding: 8px 10px;
  padding-top: 20px;
  padding-right: 50px;
  border: none;
  border-bottom: 1px solid #cbd5e0;

  width: 85%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
  -webkit-appearance: none;
  border-radius: 0;
}

.change_password-inputField input:focus {
  border: none;
  outline: none;
  border-bottom: 2px solid #36a6a4;
}
.change_password-inputField span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.change_password-inputField input:focus + span,
.change_password-inputField input:not(:placeholder-shown) + span {
  top: 7px;
  -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  font-size: 14px;
}
.change_password-inputField input,
.change_password-inputField span {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.password_input_flex_hide {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.password_input_flex_hide img {
  position: absolute;
  right: 10%;
  cursor: pointer;
}

.password_input_flex_lock {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.passwordLock-img img {
  position: absolute;
  left: 7%;
  cursor: pointer;
  z-index: 1000;
}

/* .passwordLock-img img {
  position: absolute;
  left: 5%;
} */

.change-password-form {
  /* position: absolute; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  /* bottom: 30px; */

  margin-top: 100px;
  /* margin-bottom: 50px; */
}
.change-password-form input {
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 85%;
  height: 49px;

  background: #36a6a4;
  opacity: 0.3;
  border-radius: 9px;
}

.change-password-form input:focus {
  opacity: 1;
}

.passwordVisible-animation {
  transition: 0.5s ease-in-out 0s;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyChangePass {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  .change_password-inputField {
    width: 450px;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyChangePass {
    max-width: 100%;
  }

  .change_password-inputField {
    position: relative;
    width: 100vw;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    height: 48px;
  }

  .change_password-inputField input {
    padding: 8px 20px;
    padding-top: 25px;
    padding-right: 35px;

    border: 1.5px solid #e2e8f0;
    /* border-bottom: 1px solid #cbd5e0; */
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 77%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    -webkit-appearance: none;
    border-radius: 18px;
    color: #333333;
    background: #f8fafc;
  }

  .change_password-inputField input:focus {
    /* border: 1.8px solid #6b6b6b; */
    outline: none;
    border: 1.5px solid #36a6a4;
  }
  .change_password-inputField span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 35px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .change_password-inputField input:focus + span,
  .change_password-inputField input:not(:placeholder-shown) + span {
    top: 7px;
    /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 10%;
    font-size: 10px;
  }
  .change_password-inputField input,
  .change_password-inputField span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .change_password-info-family {
    background: #f2f2f2;
    padding-bottom: 100px;
  }
  .change_password-info-header {
    width: 100vw;
    height: 56px;

    /* background: #171923; */

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;
    overflow-x: hidden;
  }

  .change_password-info-header > .change_password-info-text {
    margin-left: 0px;
  }

  .password_text_heading {
    width: 100%;
    height: 31px;

    /* Label */

    background: #e9e9e9;

    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    /* identical to box height */

    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
  }

  .change_password-inputField {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .change_password-inputField input {
    padding: 8px 10px;
    padding-top: 20px;
    padding-right: 50px;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 80%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #333333;
    -webkit-appearance: none;
    border-radius: 0;
  }

  .change_password-inputField input:focus {
    /* border: 1.8px solid #6b6b6b; */
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .change_password-inputField span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .change_password-inputField input:focus + span,
  .change_password-inputField input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .change_password-inputField input,
  .change_password-inputField span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .password_input_flex_hide {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
  .password_input_flex_hide img {
    position: absolute;
    right: 7%;
  }

  .change-password-form {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    /* bottom: 30px; */

    margin-top: 100px;
    /* margin-bottom: 50px; */
  }
  .change-password-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 93vw;
    height: 49px;

    background: #36a6a4;
    opacity: 0.3;
    border-radius: 9px;
  }

  .change-password-form input:focus {
    opacity: 1;
  }
}

@media only screen and (max-width: 470px) {
  .password_input_flex_hide img {
    position: absolute;
    right: 6%;
  }
}
