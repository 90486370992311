.errorFamGen {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  flex-direction: column;
}
.errorFamGen-Img {
  margin-bottom: 10px;
  margin-top: -30px;
}
.errorFamGen-text {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
