.no-auto-zoom {
  touch-action: manipulation;
  -ms-touch-action: manipulation;
  user-scalable: no;
  /* user-zoom: no; */
}

.inputFieldPersonalArea {
  position: relative;
  width: 450px;

  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.inputFieldPersonalArea textarea {
  font-family: "Poppins";
  -webkit-appearance: none;
  border-radius: 16px;

  width: 90%;
  height: 120px;
  /* border: none; */
  /* border-bottom: 1px solid #cbd5e0; */
  border: 1.5px solid #e2e8f0;

  outline: none;
  font-weight: 400;
  font-size: 16px;

  /* Text color */

  color: #333333;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: #f8fafc;
  /* padding-right: 10px; */
}
.inputFieldPersonalArea textarea:focus {
  /* border-bottom: 2px solid #36a6a4; */
  border: 1.5px solid #6b6b6b;
}

.inputFieldPersonalArea textarea::placeholder {
  /* padding-top: 10px; */
  /* padding-left: 5px; */
  color: #64748b;
}

.feedBack-submitBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 49px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  /* background: #36a6a4; */
  border-radius: 12px;

  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  transition: 0.5s ease-in-out 0s;
}

.feedBack-submitBtn:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 1400px) {
  .inputFieldPersonalArea {
    width: 450px;
  }
}

@media only screen and (max-width: 600px) {
  .inputFieldPersonalArea {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .inputFieldPersonalArea textarea {
    font-family: "Poppins";
    -webkit-appearance: none;
    border-radius: 16px;

    width: 80.5%;
    height: 120px;

    border: 1.5px solid #e2e8f0;

    outline: none;
    font-weight: 400;
    font-size: 16px;

    /* Text color */

    color: #333333;

    background: #f8fafc;
  }
  .inputFieldPersonalArea textarea:focus {
    border: 1.5px solid #6b6b6b;
  }
}

@media only screen and (max-width: 380px) {
  .inputFieldPersonalArea textarea {
    width: 81%;
  }
}
