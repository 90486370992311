@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResLinkBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

body {
  background: #e8e8e8;
}

.prefix_genLink {
  position: absolute;
  left: 3%;
  top: 47%;
  z-index: 100;
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}

.editLink-flexxBodyGen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.editLink-info-header {
  width: 100%;
  height: 56px;

  background: #f8f8f8;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  margin-bottom: 20px;
  overflow-x: hidden;
}
.editLink-info-header img {
  margin-left: 30px;
  cursor: pointer;
}

.url_validText {
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #ff0000;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.editLink-info-header > .editLink-info-text {
  margin-left: -30px;
}

.editLink-info-family {
  background: #fbfbfb;
  /* padding-bottom: 50px; */
  min-height: 100vh;
  position: relative;
  padding-bottom: 0px;
}

.link-img-family {
  width: 54px;
  height: 50px;

  background: #ffffff;
  border-radius: 14px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 20px;
  margin-top: 50px;
}

.img-edit-icon {
  position: relative;
  width: 10px;
  height: 10px;
  margin-right: 7px;
  margin-bottom: 7px;
}
.img-edit-icon img {
  position: absolute;
  /* top: 0; */
}

.link-img-upload-cont-flex {
  display: flex;
  /* justify-content: space-between; */
  position: relative;
  margin-left: 10%;
  width: 60px;
  height: 60px;
}

/* .remove-icon {
    margin-top: -30px;
  } */

.save-link-form {
  /* position: absolute; */
  display: flex;
  align-items: center;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
  left: 0;
  right: 0;
  justify-content: center;
  bottom: 10px;

  margin-top: 60px;
  margin-bottom: 50px;
}
.save-link-form input {
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 300px;
  height: 49px;

  background: #36a6a4;
  opacity: 1;
  border-radius: 9px;
  margin-top: 50px;
}
.link-img-family {
  position: relative;
}
.links-bg input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  bottom: 0;
}

.modi-flexxx {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
}
.editLink-imgContFam {
  width: 55px;
  height: 55px;
  position: relative;
  border: 1.3px dashed #179593;
  border-radius: 21px;
  margin-right: 10px;

  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}

.editLink-imgContFam:hover {
  background: #e9e9e9;
}

.img-editLink-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;

  background: rgba(242, 242, 242, 0.3);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .linkImgFile-uploadinput {
  width: 56px;
  height: 56px;
} */
.linkImgFile-uploadinput input[type="file"] {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  /* bottom: 0; */
  opacity: 0;
  /* z-index: 1000000; */
}

.add-links-family {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height */

  display: flex;
  justify-content: flex-end;
  align-items: center;

  color: #36a6a4;
  width: 100%;
  margin-top: 15px;
  /* margin-right: 12%; */
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.add-links-cont {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* margin-right: 30px;
  margin-top: 10px;
  /* justify-content: flex-end; */
  margin-right: 12%;
  cursor: pointer;
}
.add-links-cont img {
  margin-right: 5px;
  cursor: pointer;
}

@media only screen and (max-width: 1400px) {
  .totalResLinkBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  body {
    background: #e8e8e8;
  }

  .prefix_genLink {
    left: 9%;
    top: 47%;
  }
  .totalResLinkBody {
    max-width: 100%;
  }
  .editLink-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;
    overflow-x: hidden;
  }

  .url_validText {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #ff0000;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
  .editLink-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .editLink-info-header > .editLink-info-text {
    margin-left: -30px;
  }

  .editLink-info-family {
    background: #fbfbfb;
    /* padding-bottom: 50px; */
    position: relative;
    padding-bottom: 70px;
  }

  .link-img-family {
    width: 54px;
    height: 50px;

    background: #ffffff;
    border-radius: 14px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
    margin-bottom: 20px;
    margin-top: 50px;
  }

  .img-edit-icon {
    position: relative;
    width: 10px;
    height: 10px;
    margin-right: 7px;
    margin-bottom: 7px;
  }
  .img-edit-icon img {
    position: absolute;
    /* top: 0; */
  }

  .link-img-upload-cont-flex {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .add-links-family {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height */

    display: flex;
    justify-content: flex-end;

    color: #36a6a4;
    margin-right: 6%;
  }
  .add-links-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 30px; */
    margin-top: 10px;
  }
  .add-links-cont img {
    margin-right: 5px;
  }

  /* .remove-icon {
    margin-top: -30px;
  } */

  .save-link-form {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    bottom: 30px;

    margin-top: 60px;
    margin-bottom: 50px;
  }
  .save-link-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 92vw;
    height: 49px;

    background: #36a6a4;
    opacity: 1;
    border-radius: 9px;
  }
  .link-img-family {
    position: relative;
  }
  .links-bg input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }

  /* .save-link-form input:focus {
    opacity: 1;
  } */
}

@media only screen and (max-width: 516px) {
  .prefix_genLink {
    left: 9%;
    top: 47%;
  }
}
