.teamTemp_cardGen {
  border-radius: 14px;
  border: 0.3px solid #e2e8f0;
  background: #f8fafc;
  box-shadow: 0px 16px 10px 0px rgba(218, 218, 218, 0.3);
  height: 66px;
  padding: 14px 7px 15px 20px;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
  margin-top: 10px;
}

.teamTemp_cardGen:hover {
  border-radius: 10px;
  /* background: #f8f8f8; */
  /* transform: translateX(-5%); */
  transform: scale(1.05);
}
.teamTemp_cardName {
  color: #333;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
}

.teamTemp_cardHandle {
  color: #838383;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  /* margin-top: 1px; */
}
