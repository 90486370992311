.inputFieldContact-sele {
  position: relative;
  width: 460px;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}
.inputFieldContact-sele select:focus {
  border: 1.5px solid #36a6a4;
}

.inputFieldContact-sele select {
  /* width: 90%; */
  -webkit-appearance: none;
  border-radius: 16px;
  width: 98%;
  height: 52px;

  background: #f8fafc;
  /* DEETS GREEN */

  border: 1.5px solid #e2e8f0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #64748b;
  padding-left: 20px;
  padding-right: 10px;
}

@media only screen and (max-width: 1400px) {
  /* .inputFieldContact-sele select {
    width: 85%;
  } */

  .inputFieldContact-sele {
    width: 460px;
  }
}

@media only screen and (max-width: 600px) {
  .inputFieldContact-sele {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  .inputFieldContact-sele select:focus {
    border-bottom: 2px solid #36a6a4;
  }

  .inputFieldContact-sele select {
    width: 88.5%;
    -webkit-appearance: none;
    border-radius: 16px;
  }
}
@media only screen and (max-width: 453px) {
  .inputFieldContact-sele select {
    width: 90%;
  }
}

@media only screen and (max-width: 396px) {
  .inputFieldContact-sele select {
    width: 93%;
  }
}

@media only screen and (max-width: 318px) {
  .inputFieldContact-sele select {
    width: 97%;
  }
}
