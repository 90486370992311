.totalResBodyTeamLinkmodal {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
}

.share-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: rgba(23, 25, 35, 0.2);
  backdrop-filter: blur(10px);
  max-width: 500px;
}

.share-family-modal {
  position: absolute;
  bottom: 0;
  height: 120px;
  width: 100%;
  background: #000000;
  /* padding: 10px; */
  /* z-index: 400000; */
  /* overflow-y: hidden; */
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  left: 0;
  right: 0;
}

@-webkit-keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

@keyframes animatedown {
  from {
    bottom: -300px;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}

.sharecontainer-family {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

@media only screen and (max-width: 1400px) {
  .totalResLinkBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
  .share-modal {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .totalResLinkBody {
    max-width: 100%;
  }
  .share-modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    /* padding-top: 100px;  */
    display: flex;
    justify-content: center;
    align-items: baseline;
    /* align-items: flex-end; */
    right: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(23, 25, 35, 0.2);
    backdrop-filter: blur(10px);
  }

  .share-family-modal {
    position: absolute;
    bottom: 0;
    height: 120px;
    width: 100%;
    background: #000000;
    /* padding: 10px; */
    /* z-index: 400000; */
    /* overflow-y: hidden; */
    -webkit-animation-name: animatedown;
    -webkit-animation-duration: 0.4s;
    animation-name: animatedown;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
  }

  @-webkit-keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes animatedown {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  .sharecontainer-family {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    align-items: center;
    margin-top: 15px;
  }
}
