@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyTeamTempCre {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative;
  height: 100vh; */
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}

.create-temp-info-header {
  width: 100%;
  height: 56px;

  background: #e8e8e8;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* margin-bottom: 20px; */
  overflow-x: hidden;
}
.create-temp-info-header img {
  margin-left: 30px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.create-temp-info-header > .create-temp-info-text {
  margin-left: -30px;
}

.create-temp-name {
  position: relative;
  width: 100%;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.create-temp-name input {
  padding: 8px 20px;
  padding-top: 30px;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 90%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  -webkit-appearance: none;
  border-radius: 0;

  color: #333333;
}

.create-temp-name input:focus {
  /* border: 1.8px solid #6b6b6b; */
  border: none;
  outline: none;
  border-bottom: 2px solid #36a6a4;
}
.create-temp-name span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.create-temp-name input:focus + span,
.create-temp-name input:not(:placeholder-shown) + span {
  top: 7px;
  -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  font-size: 14px;
}
.create-temp-name input,
.create-temp-name span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.create-temp-family-cont {
  /* position: relative; */
  background: #f2f2f2;
  height: 100%;
  overflow-x: hidden;
  padding-bottom: 100px;
  /* position: absolute; */
  /* top: 0;
    right: 0; */
}

.create-temp-img-preview {
  position: relative;
  margin-top: 20px;
  width: 328px;
  height: 126px;
  margin-left: auto;
  margin-right: auto;
  border: none;

  /* Label */

  background: #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .bacccc{
    position: relative;
  } */
.create-temp-preview-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: 0.01em;

  /* Text color */

  color: #000000;

  opacity: 0.21;
}
.close-create-temp-img {
  position: absolute;
  right: 10px;
  top: 10px;
}
.create-temp-color-code-family {
  margin-top: 0px;
  margin-bottom: 40px;
  width: 100%;
  height: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  background: #ffffff;
}

.create-temp-color-code-cont {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin-top: 5px;
}
.create-temp-color-code {
  width: 32px;
  height: 29px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .backdrop-buttons-select {
    display: flex;
    justify-content: space-evenly;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 50px;
  } */
.create-temp-button {
  display: flex;
  /* padding: 30px 11px; */
  justify-content: center;
  align-items: center;
  width: 194px;
  height: 24px;

  background: rgba(51, 51, 51, 0.2);
  border-radius: 40px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  /* White */

  color: #ffffff;

  opacity: 0.78;
  position: relative;
}

.create-temp-button2 {
  display: flex;
  /* padding: 30px 11px; */
  justify-content: center;
  align-items: center;

  background: rgba(51, 51, 51, 0.2);
  border-radius: 40px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  /* White */

  color: #ffffff;

  width: 42px;
  height: 24px;

  /* Text color */

  background: #333333;
  border-radius: 40px;

  position: relative;
}

.teamBackdropCam-icon img {
  margin-top: 5px;
  margin-right: 5px;
}

.teamBackdropCam-icon2 img {
  margin-top: 5px;
  /* margin-right: 5px; */
}
.create-temp-select-text {
  background: #e9e9e9;
  width: 100%;
  height: 41px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  justify-content: center;

  /* Text color */

  color: #333333;
  margin-top: 20px;
}

.create-temp-center-upload-iii {
  margin-left: auto;
  margin-right: auto;
}
.create-temp-upload-btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.create-temp-cover-image-bg input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.create-temp-cover-image-bg2 input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}

.create-temp-customize-icon-lock {
  width: 100%;
  height: 58px;

  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  flex-direction: column;
  color: #c1c1c1;
  margin-top: 20px;
}
.create-temp-customize-lock-btn {
  width: 32px;
  height: 29px;

  background: conic-gradient(
    from 180deg at 50% 50%,
    #22005d 0deg,
    #fbe200 63.75deg,
    #0535ba 140.63deg,
    rgba(69, 118, 154, 0.395833) 217.5deg,
    #960d0d 275.62deg,
    #22005d 360deg
  );
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.create-temp-custom-theme-text {
  margin-top: 2px;
}

.create-temp-done-genColor {
  margin-top: 100px;
  width: 50%;
  height: 49px;
  margin-left: auto;
  margin-right: auto;
  /* primary/deets green */
}
.create-temp-done-genColor button {
  outline: none;
  /* border: none; */
  border: 1px solid #36a6a4;
  font-family: "Poppins";

  /* color: #ffffff; */
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
  border-radius: 3px;
}

.save-create-temp-info {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
}
.save-create-temp-info button {
  border: none;
  outline: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  width: 100%;

  height: 49px;
  margin-top: 50px;

  /* background: #fcb816; */
  /* opacity: 0.3; */
  border-radius: 6px;
}

.delete-create-temp-info {
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  /* flex-direction: column; */
}

.delete-create-temp-info button {
  border: 1px solid #fa1414;
  /* border-radius: 200px; */
  background: transparent;

  outline: none;

  width: 50%;

  height: 39px;
  margin-top: 20px;

  /* background: #fcb816; */
  /* opacity: 0.3; */
  border-radius: 100px;
}

.create-temp-add-field {
  margin-top: 30px;
  width: 100%;
  height: 42px;

  background: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #333333;
}
.create-temp-add-field-text {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #333333;

  margin-left: 20px;
}

.create-temp-add-field-arrow {
  margin-right: 20px;
}
.create-temp-personal-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  height: 42px;
  margin-top: 15px;

  background: #ffffff;
  /* Deets entry field stroke */

  border-bottom: 1px solid #cbd5e0;
}

.create-temp-personal-text {
  margin-left: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  /* Text color */

  color: #333333;

  opacity: 0.7;
}

.create-temp-forward-icon {
  margin-right: 10px;
}

.create-temp-field-fam {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }

  .create-temp-info-header {
    width: 100vw;
    height: 56px;

    background: #f8f8f8;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }
  .create-temp-info-header img {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .create-temp-info-header > .create-temp-info-text {
    margin-left: -30px;
  }

  .create-temp-name {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .create-temp-name input {
    padding: 8px 20px;
    padding-top: 30px;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 76%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    -webkit-appearance: none;
    border-radius: 0;

    color: #333333;
  }

  .create-temp-name input:focus {
    /* border: 1.8px solid #6b6b6b; */
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .create-temp-name span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .create-temp-name input:focus + span,
  .create-temp-name input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .create-temp-name input,
  .create-temp-name span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .create-temp-family-cont {
    /* position: relative; */
    background: #f2f2f2;
    height: 100%;
    overflow-x: hidden;
    padding-bottom: 100px;
    /* position: absolute; */
    /* top: 0;
    right: 0; */
  }

  .create-temp-img-preview {
    position: relative;
    margin-top: 20px;
    width: 328px;
    height: 126px;
    margin-left: auto;
    margin-right: auto;
    border: none;

    /* Label */

    background: #e9e9e9;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  /* .bacccc{
    position: relative;
  } */
  .create-temp-preview-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;

    letter-spacing: 0.01em;

    /* Text color */

    color: #000000;

    opacity: 0.21;
  }
  .close-create-temp-img {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .create-temp-color-code-family {
    margin-top: 0px;
    margin-bottom: 40px;
    width: 100%;
    height: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    background: #ffffff;
  }

  .create-temp-color-code-cont {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    margin-top: 5px;
  }
  .create-temp-color-code {
    width: 32px;
    height: 29px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  /* .backdrop-buttons-select {
    display: flex;
    justify-content: space-evenly;
    padding-right: 10px;
    padding-left: 10px;
    margin-bottom: 50px;
  } */
  .create-temp-button {
    display: flex;
    /* padding: 30px 11px; */
    justify-content: center;
    align-items: center;
    width: 194px;
    height: 24px;

    background: rgba(51, 51, 51, 0.2);
    border-radius: 40px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    /* White */

    color: #ffffff;

    opacity: 0.78;
    position: relative;
  }

  .create-temp-button2 {
    display: flex;
    /* padding: 30px 11px; */
    justify-content: center;
    align-items: center;

    background: rgba(51, 51, 51, 0.2);
    border-radius: 40px;

    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    /* White */

    color: #ffffff;

    width: 42px;
    height: 24px;

    /* Text color */

    background: #333333;
    border-radius: 40px;

    position: relative;
  }

  .teamBackdropCam-icon img {
    margin-top: 5px;
    margin-right: 5px;
  }

  .teamBackdropCam-icon2 img {
    margin-top: 5px;
    /* margin-right: 5px; */
  }
  .create-temp-select-text {
    background: #e9e9e9;
    width: 100%;
    height: 41px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    /* identical to box height, or 157% */

    display: flex;
    align-items: center;
    justify-content: center;

    /* Text color */

    color: #333333;
    margin-top: 20px;
  }

  .create-temp-center-upload-iii {
    margin-left: auto;
    margin-right: auto;
  }
  .create-temp-upload-btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .create-temp-cover-image-bg input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }

  .create-temp-cover-image-bg2 input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }

  .create-temp-customize-icon-lock {
    width: 100%;
    height: 58px;

    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    flex-direction: column;
    color: #c1c1c1;
    margin-top: 20px;
  }
  .create-temp-customize-lock-btn {
    width: 32px;
    height: 29px;

    background: conic-gradient(
      from 180deg at 50% 50%,
      #22005d 0deg,
      #fbe200 63.75deg,
      #0535ba 140.63deg,
      rgba(69, 118, 154, 0.395833) 217.5deg,
      #960d0d 275.62deg,
      #22005d 360deg
    );
    border-radius: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .create-temp-custom-theme-text {
    margin-top: 2px;
  }

  .create-temp-done-genColor {
    margin-top: 100px;
    width: 50%;
    height: 49px;
    margin-left: auto;
    margin-right: auto;
    /* primary/deets green */
  }
  .create-temp-done-genColor button {
    outline: none;
    /* border: none; */
    border: 1px solid #36a6a4;
    font-family: "Poppins";

    /* color: #ffffff; */
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #333333;
    border-radius: 3px;
  }

  .save-create-temp-info {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .save-create-temp-info button {
    border: none;
    outline: none;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: #ffffff;
    width: 100%;

    height: 49px;
    margin-top: 50px;

    /* background: #fcb816; */
    /* opacity: 0.3; */
    border-radius: 6px;
  }

  .delete-create-temp-info {
    /* position: absolute; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    /* flex-direction: column; */
  }

  .delete-create-temp-info button {
    border: 1px solid #fa1414;
    /* border-radius: 200px; */
    background: transparent;

    outline: none;

    width: 90%;

    height: 39px;
    margin-top: 20px;

    /* background: #fcb816; */
    /* opacity: 0.3; */
    border-radius: 100px;
  }

  .create-temp-add-field {
    margin-top: 30px;
    width: 100%;
    height: 42px;

    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    color: #333333;
  }
  .create-temp-add-field-text {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;

    color: #333333;

    margin-left: 20px;
  }

  .create-temp-add-field-arrow {
    margin-right: 20px;
  }
  .create-temp-personal-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 42px;
    margin-top: 15px;

    background: #ffffff;
    /* Deets entry field stroke */

    border-bottom: 1px solid #cbd5e0;
  }

  .create-temp-personal-text {
    margin-left: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    /* Text color */

    color: #333333;

    opacity: 0.7;
  }

  .create-temp-forward-icon {
    margin-right: 10px;
  }

  .create-temp-field-fam {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    flex-direction: column;
  }
}
