.totalResTempLinkBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.prefix_genLink__teamedit {
  position: absolute;
  left: 3%;
  top: 45%;
  z-index: 100;
  font-size: 14px;
  color: #333333;
  opacity: 0.5;
}

.editTeamLink-info-family {
  background: #fbfbfb;
  /* padding-bottom: 50px; */
  min-height: 100vh;
  position: relative;
  padding-bottom: 0px;
}

.editLink-flexxBodyGen__teamedit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.add-links-family__teamedit {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height */

  display: flex;
  justify-content: flex-end;
  align-items: center;

  color: #36a6a4;
  width: 100%;
  margin-top: 15px;
  /* margin-right: 12%; */
  margin-right: auto;
  margin-left: auto;
  width: 90%;
}

.add-phone-cont__teamedit {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 30px; */
  margin-top: 10px;
  cursor: pointer;
}

.add-phone-cont__teamedit {
  margin-right: 5px;
  cursor: pointer;
  /* width: 28px;
  height: 28px; */
}

.addCon_svg__teamedit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}
@media only screen and (max-width: 1400px) {
  .totalResTempLinkBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResTempLinkBody {
    max-width: 100%;
  }

  .prefix_genLink__teamedit {
    left: 9%;
    top: 45%;
  }

  .add-phone-cont__teamedit {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 30px; */
    margin-top: 10px;
    cursor: pointer;
  }
  .add-phone-cont__teamedit {
    margin-right: 5px;
    /* width: 28px;
    height: 28px; */
  }

  .ditTeamLink-info-family {
    background: #fbfbfb;
    /* padding-bottom: 50px; */
    position: relative;
    padding-bottom: 70px;
  }

  .add-links-family__teamedit {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height */

    display: flex;
    justify-content: flex-end;

    color: #36a6a4;
    margin-right: 6%;
  }
}

@media only screen and (max-width: 516px) {
  .prefix_genLink__teamedit {
    left: 9%;
    top: 45%;
  }
}
