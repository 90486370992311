/* Extra small devices (phones, 600px and down) */
@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResAdminBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
  /* overflow-x: hidden; */
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background-color: #e8e8e8;
  overflow-x: hidden;
}
.new_profile-pics-cont {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 320px;

  background: #fbfbfb;
  /* border-radius: 0px 0px 40px 0px; */
  padding-top: 10px;
  margin-top: 50px;
}

.name_suffixGen {
  margin-top: 5px;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;

  color: #333333;
}
.profile_imageLoader {
  /* margin-left: auto;
  margin-right: auto; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.new_profile_cardGenCover {
  position: absolute;
  /* top: -45px; */
  width: 90%;
  /* height: 100%; */
  height: 300px;
  left: 50%;
  transform: translateX(-50%);
}

.new_pattern_backGen {
  width: 100%;

  height: 100%;
  position: absolute;
  top: 0;
  overflow: hidden;
  border-radius: 15px;
}

.new_bannerCoverB {
  /* position: absolute; */
  width: 100%;
  height: 100%;

  top: -30px;

  /* background: rgba(0, 0, 0, 0.8); */
  /* background: rgba(235, 236, 236, 0.98); */
  background: #ebecec;
  border-radius: 15px;
  /* background: #003399; */
}

.logo_textt {
  position: absolute;
  right: 0;
  top: 0;
}

.new_profile_logoCont {
  position: absolute;
  right: 16px;
  top: 8px;

  /* width: 100px; */
  /* height: 40px; */
  font-style: normal;
  font-weight: 600;
  font-size: 12px;

  letter-spacing: 0.0025em;

  color: #000000;
}

.new_profile_logoCont img {
  width: 50px;
  height: auto;
  object-fit: cover;
}

.new_logo_edit {
  position: absolute;
  right: 16px;
  bottom: 18px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 35px;
  height: 35px;

  background: #ffffff;
  box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.1),
    0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.04);
  border-radius: 50%;
  transition: 0.3s ease-in-out 0s;
}
.new_logo_edit:hover {
  transform: scale(1.2);
}
.new_bannerCard_flexGen {
  position: relative;
  display: flex;
  align-items: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.new_cont-all-avatar {
  position: relative;
}

.new_user-profile-pics {
  overflow: hidden;
  position: relative;

  width: 172px;
  height: 200px;
  background: #f8fafc;
  border-radius: 18px;

  font-style: normal;
  font-weight: 600;
  font-size: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #3c485e;
  margin-left: 13px;
  margin-right: 9px;
}

.new_user-profile-pics-text {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 700;
  font-size: 44px;

  color: #171923;
}

.new_user-name {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 1.36rem;

  color: #171923;
  max-width: 200px;
  /* word-wrap: break-word; */
  text-transform: capitalize;
  /* letter-spacing: -0.01em; */
}

.new_user-title {
  font-style: normal;

  font-weight: 600;
  font-size: 0.9rem;

  color: #333333;

  opacity: 0.8;
  max-width: 250px;
  word-wrap: break-word;
  margin-top: 6.7px;
  line-height: 18px;
}

.new_back_background {
  background: #171923;
  width: 100%;
  height: 80px;
  position: absolute;
  top: 0;
}
.profile-pics-cont {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  width: 100%;
  height: 290px;
  border: none;
  margin-top: -1px;

  background: #171923;
  border-radius: 0px 0px 40px 0px;
  /* padding-top: 10px; */
  margin-top: 50px;
}
.containertt {
  background: #f2f2f2;
  height: 100vh;
}
.backdrop-cont {
  position: absolute;
  margin-top: 0px;

  top: 5px;
  box-sizing: border-box;

  width: 95%;
  height: 220px;

  background: #ffffff;
  border-radius: 0px 0px 35px 35px;
  overflow: hidden;
}
.user-profile-pics {
  overflow: hidden;
  position: relative;

  width: 210px;
  height: 210px;

  background: #fbffff;
  /* box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2); */
  border: 4px solid #36a6a4;
  backdrop-filter: blur(1.5px);

  border-radius: 15px;
  top: -20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #3c485e;
}
.edit-profile-pics-family {
  position: absolute;
  bottom: 20px;

  left: 37px;
}

.deets-admin-logo {
  margin-left: 55px;
  margin-top: 10px;
}

.uploaded-img img {
  margin-top: 16px;
  height: 100%;
  width: 100%;
}

.cont-all-avatar {
  position: relative;
}
.navbar-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding-left: 20px;
  padding-right: 20px; */
  height: 59px;

  /* background: #171923; */
  margin-bottom: 0;
  border: none;
  /* position: relative; */
  position: fixed;
  width: 500px;
  z-index: 9000;
  margin-top: -50px;
}

.shareicon-cont {
  width: 30px;
  height: 30px;
  /* background: #171923; */
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-right: 45px;
  /* right: 50%; */
  position: relative;
  cursor: pointer;
}

.copyUrl_img {
  width: fit-content;
  margin-left: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.share-iii {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 5%; */
}
.edit-blackpen {
  position: absolute;
  height: 35px;

  top: 10px;
  right: 10px;
  width: 35px;

  background: #ffffff;
  box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.2),
    0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out 0s;
  cursor: pointer;
}

.edit-blackpen:hover {
  background: #f2f2f2;
}
.edit-blackpencont {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.backdrop-img-main {
  width: 100%;
  height: 100%;
}
.user-profile-details {
  position: absolute;
  bottom: 5px;
  text-align: center;
}

.edit-profile-pics-icon {
  position: relative;
  height: 35px;

  width: 35px;
  /* background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(2px); */

  background: #ffffff;
  box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.1),
    0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.04);
  /* background: #ffffff; */
  /* box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.2),
    0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.1); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 35px;
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
}

.new_genIconCont {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
}

.new_childIcon {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.new_childAddIcon {
  position: absolute;

  top: 59%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.new_childWebIcon {
  position: absolute;

  top: 59%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}
.new_phone-icon-s {
  /* margin-top: 5px; */
  width: 100%;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border-radius: 50%;
  opacity: 0.1;
}
.phone-first-flexAd {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out 0s;
}
.new_genLinkCont {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
}

.new_link-icon-s {
  /* margin-top: 5px; */
  width: 100%;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border-radius: 50%;
  opacity: 0.2;
}

.new_childlink {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}
.edit-profile-pics-icon:hover {
  transform: scale(1.2);
}

.email-icon-sAd {
  margin-top: 5px;
}

.edit-profile-pics-icon input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
}
.user-profile-pics-text {
  margin-top: 10px;
}

.contact-info-familyAd {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.phone-contAd:hover .arrow-forwardIconAd img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}

.email-contAd:hover .arrow-forwardIconAd img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}

.location-contAd:hover .arrow-forwardIconAd img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}

.genLinksDivAd:hover .arrow-forwardIconAd img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}
.user-name {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;

  color: #ffffff;
}
.user-title {
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  /* Label */

  color: #e9e9e9;
}
.body-div {
  /* padding-left: 10px;
  padding-right: 10px; */
  background: #fbfbfb;
  padding-bottom: 70px;
}
.prof-con-cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-right: auto;
  margin-left: auto;

  padding-top: 20px;
  /* width: 100%; */
}
.profile-button {
  width: 42%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;

  font-style: normal;
  font-weight: 700;
  font-size: 14.4px;

  border: none;
  color: #171923;
  border-radius: 15px;
  box-sizing: border-box;
  height: 50px;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
  background: #ffffff;

  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
}

.profile-button:hover {
  /* transition: 0.5s; */
  background: #f8f8f8;
  border-radius: 9px;
}
.connect-button {
  height: 50px;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 21px;
  border: none;

  color: #ffffff;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;

  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
}

.connect-button:hover {
  opacity: 0.8;
  border-radius: 9px;
}

/* .about-user-text {
  padding-left: 10px;
  padding-right: 10px;
} */

.new_uploaded-img {
  width: 210px;
  height: 219px;
}

/* .new_uploaded-img img {
  margin-top: 20px;
  width: 210px;
  height: 209px;
} */

.note-content {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 22px;
}

.contact-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}
.contact-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}

.phone-contAd {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-left: 20px; */
  margin-right: 20px;
  transition: 0.3s ease-in-out 0s;
}

.phone-contAd:hover .arrow-forwardIconAd img {
  transition: 0.3s ease-in-out 0s;
  transform: translateX(5px);
}

.social-media-groupAd {
  display: flex;
  justify-content: flex-start;

  flex-wrap: wrap;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.phone-first-flexAd {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s ease-in-out 0s;
}

.phone-icon-sAd {
  margin-top: 5px;
}

.phone-numbAd {
  font-style: normal;
  text-decoration: none;
  margin-left: 20px;
  font-weight: 600;
  font-size: 17px;
  /* Text color */

  color: #333333;
  /* margin-right: 20px; */
  line-height: 22px;
  letter-spacing: 0.0075em;
  /* margin-right: 10px; */
  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.contact-user-text {
  /* padding-left: 10px;
  padding-right: 10px; */
}
.contact-placeholder {
  height: 50px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}
.call-update-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  /* position: relative; */
}
.call-icon-r {
  margin-left: 10px;
  margin-right: 30px;
  margin-top: 10px;
}
.forward-icon-r {
  margin-right: 30px;
}
.call-inside-cont {
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-admin-cont {
  width: 100px;
  height: 22px;
  /* padding-right: 20px; */
}
.email-contAd {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-left: 20px; */
  margin-right: 20px;
  transition: 0.3s ease-in-out 0s;
}

.email-address {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  /* Text color */
  margin-left: 20px;
  color: #333333;
  /* margin-right: 20px; */
  /* margin-right: 220px; */
  /* margin-left: 70px; */
  line-height: 22px;
  letter-spacing: 0.0075em;
  margin-right: 10px;

  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.address-admin {
  width: 214px;
  height: 66px;
}

.email-addressAd {
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  /* Text color */
  margin-left: 20px;
  color: #333333;
  /* margin-right: 20px; */
  /* margin-right: 220px; */
  /* margin-left: 70px; */
  line-height: 22px;
  letter-spacing: 0.0075em;
  /* margin-right: 10px; */

  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.location-contAd {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-left: 20px; */
  /* margin-right: 20px; */
  transition: 0.3s ease-in-out 0s;
}

.location-addressAd {
  font-style: normal;
  font-weight: 600;
  margin-left: 20px;
  /* margin-right: 10px; */
  font-size: 17px;
  /* Text color */

  color: #333333;

  line-height: 22px;
  letter-spacing: 0.0075em;
  width: 400px;
  overflow: hidden;
}
.social-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}

.social-media-groupAd {
  display: flex;
  justify-content: flex-start;

  flex-wrap: wrap;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
}

.social-media-iconAd {
  position: relative;
  width: 75px;
  height: 75px;
  display: flex;
  margin-right: 15px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  background: #ffffff;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  /* identical to box height, or 183% */

  letter-spacing: 0.01em;

  color: #c2c2c2;
  transition: 0.2s ease-in-out 0s;
  box-sizing: border-box;
}
.social-media-iconAd:hover {
  box-shadow: none;
  /* border: 2px solid #179593; */
}

.social-media-tagAd {
  position: absolute;
  bottom: 2px;
}
.social-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
.social-user-text {
  /* padding-left: 10px;
  padding-right: 10px; */
}
.social-placeholder {
  height: 50px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}

.snapchat-entry {
  width: 95px;
  height: 95px;

  background: #ffffff;
  box-shadow: 0px 1.69681px 5.09043px rgba(0, 0, 0, 0.1),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
  border-radius: 6px;
  margin: 5px;
}

.social-snap-chat-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-snap-chat-icon img {
  margin-top: 30px;
}
.snap-chat-tag {
  margin-left: 5px;
  width: 34px;
  height: 20px;

  /* primary/deets green */

  background: #ebf6f6;
  opacity: 0.1;
  border-radius: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  /* identical to box height */

  display: flex;

  justify-content: center;
  align-items: center;
  /* primary/deets green */

  color: #86c9c8;

  opacity: 0.89;
}

.snap-chat-tag-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media-big-entry {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.video-groupAd {
  margin-top: 10px;
  margin-left: 20px;
  margin-right: 20px;
}

div.main-linksAd {
  font-style: normal;
  margin-left: 10px;
  /* margin-right: 10px; */
  font-weight: 600;
  font-size: 17px;
  /* Text color */
  /* overflow-wrap: break-word; */
  color: #333333;
  /* margin-right: 20px; */
  line-height: 22px;
  letter-spacing: 0.0075em;
  word-wrap: break-word;
  /* width: 60%; */
  white-space: nowrap;
  width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}
.video-header-text {
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
.video-user-text {
  /* padding-left: 10px;
  padding-right: 10px; */
}
.video-placeholder {
  height: 50px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}

.links-groupAd {
  padding-bottom: 100px;
}

.links-contAd {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* margin-left: 20px; */
  /* margin-right: 20px; */
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  transition: 0.3s ease-in-out 0s;
}

.links-first-flexAd {
  display: flex;
  justify-content: center;
  align-items: center;
}

.links-icon-sAd {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 42px;
  height: 42px;

  background: transparent;
  border: 2px solid #e9e9e9;
  border-radius: 50%; */

  width: 32px;
  height: 32px;
  border-radius: 50%;
  /* position: relative; */
}

.link_iconColor {
  width: 32px;
  height: 32px;
  fill: #333;
}

.links-icon-sAd img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.links-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  /* padding-right: 50px; */
  width: 100%;
  height: 42px;
  margin-top: 20px;
  background: #ffffff;
}
.links-header-text {
  /* font-style: normal; */
  font-weight: 700;
  font-size: 14px;

  color: #333333;
}
.links-user-text {
  /* padding-left: 10px;
  padding-right: 10px; */
}
.links-placeholder {
  height: 50px;
  /* background: red; */
  text-align: center;
  padding-top: 30px;
  color: #ececec;
  font-weight: 400;
  font-size: 12px;
}

.stats_modalGen {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  top: 0;
  width: 100%; /* Full width */
  max-width: 500px;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.1);
  /* transition: 0.5s ease-in-out 0s; */
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}
.links-family {
  margin-bottom: 30px;
}

.stats-cont {
  padding-top: 30px;
  padding-bottom: 30px;
  /* border-top: 1px solid #eeeeee; */
  /* border-bottom: 1px solid #eeeeee; */
  /* margin-top: 10px; */
  background: #fbfbfb;

  width: 100%;
  position: relative;

  /* margin-bottom: 50px; */
}

.stats_modal_cont {
  width: 90%;
  overflow: hidden;

  margin-right: auto;
  margin-left: auto;
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 14px;
  position: absolute;
  bottom: 20px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* padding-top: 10px; */
  padding-bottom: 10px;
  transition: 0.5s ease-in-out 0s;
  /* height: 500px; */
  cursor: pointer;
}

/* .stats_modal_cont:hover {
  border-radius: 6px;
  background-color: #ebe7e7;
} */
.stats_flexGen {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 20px;
  /* transition: 3.5s ease-in-out 0s; */
}

.stats_header {
  width: 100%;

  background: #f9f9f9;
  border-bottom: 0.4px solid #eeeeee;
  border-radius: 8px 8px 0px 0px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in-out 0s;
}

.stats_SectionSecondText {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;

  color: #333333;
  margin-top: 40px;
  margin-bottom: 20px;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
}

.stats_SectionText {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;

  color: #333333;
  margin-top: 20px;
  width: 88%;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s ease-in-out 0s;
}

.stats_numberScan {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 10px;
}

.stats_numberScanSecond {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 10px;
}
.close_statsGen {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #94a3b8;
  /* width: 88%;
display: flex;
align-items: center;
justify-content: center; */
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  width: fit-content;
  margin-top: 50px;
}
@media only screen and (max-width: 1400px) {
  .totalResAdminBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }

  .navbar-cont {
    width: 500px;
  }
  .user-profile-pics {
    overflow: hidden;
    position: relative;

    width: 190px;
    height: 190px;

    /* background: #e9e9e9; */

    background: #fbffff;
    /* box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2); */
    border: 4px solid #36a6a4;
    backdrop-filter: blur(1.5px);

    border-radius: 15px;
    top: -20px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #3c485e;
  }
  /* 
  .new_uploaded-img {
    width: 210px;
    height: 219px;
  } */
  .new_uploaded-img img {
    width: 210px;
    height: 209px;
  }
  .edit-profile-pics-family {
    position: absolute;
    bottom: 20px;

    left: 43px;
  }

  div.main-linksAd {
    width: 350px;
  }

  .backdrop-cont {
    position: absolute;
    margin-top: 0px;

    top: 5px;
    box-sizing: border-box;

    width: 95%;
    height: 200px;

    background: #ffffff;
    border-radius: 0px 0px 35px 35px;
    overflow: hidden;
  }

  .edit-profile-pics-icon {
    margin-left: 25px;
  }

  .new_user-title {
    font-size: 0.8125rem;
    max-width: 220px;
    font-weight: 600;
  }

  .shareicon-cont {
    margin-right: 35px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background-color: #e8e8e8;
    overflow-x: hidden;
  }

  .totalResAdminBody {
    max-width: 100%;
  }

  .new_back_background {
    background: #171923;
    width: 100%;
    height: 80px;
    position: absolute;
    top: 0;
  }

  .new_profile-pics-cont {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100.5%;
    height: 305px;

    background: #fbfbfb;

    padding-top: 10px;
    margin-top: 50px;
  }
  .new_profile_cardGenCover {
    height: 288px;
  }

  .new_user-profile-pics {
    overflow: hidden;
    position: relative;

    width: 162px;
    height: 195px;
    background: #f8fafc;
    /* box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2); */
    border-radius: 18px;
    /* top: -30px; */

    font-style: normal;
    font-weight: 600;
    font-size: 60px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #3c485e;
  }

  /* .new_uploaded-img {
    width: 201px;
    height: 209px;
  } */

  /* .new_uploaded-img img {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  } */
  /* .new_uploaded-img {
    width: 161px;
    height: 169px;
  } */

  .new_uploaded-img {
    width: 164px;
    height: 197px;
  }

  .new_uploaded-img img {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }

  .new_user-name {
    font-style: normal;
    font-weight: 700;
    font-size: 1.36rem;

    color: #171923;
  }

  .new_user-title {
    font-size: 0.8125rem;
    max-width: 220px;
  }
  .profile-pics-cont {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    width: 100%;
    height: 225px;
    border: none;
    margin-top: -1px;

    background: #171923;
    border-radius: 0px 0px 40px 0px;
    padding-top: 10px;
    margin-top: 50px;
  }
  .containertt {
    background: #f2f2f2;
  }
  .backdrop-cont {
    position: absolute;
    margin-top: 0px;

    top: 5px;
    box-sizing: border-box;

    width: 95%;
    height: 150px;

    background: #ffffff;
    border-radius: 0px 0px 35px 35px;
    overflow: hidden;
  }
  .user-profile-pics {
    overflow: hidden;
    position: relative;

    width: 161px;
    height: 161px;

    /* background: #e9e9e9; */

    background: #fbffff;
    /* box-shadow: 4px 6px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px; */

    border: 4px solid #36a6a4;
    backdrop-filter: blur(1.5px);

    border-radius: 15px;
    top: -30px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #3c485e;
  }
  .edit-profile-pics-family {
    position: absolute;
    bottom: 15px;

    left: 53px;
  }

  .deets-admin-logo {
    margin-left: 55px;
  }

  .uploaded-img img {
    margin-top: 16px;
    height: 100%;
    width: 100%;
  }

  .cont-all-avatar {
    position: relative;
  }
  .navbar-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding-left: 20px;
    padding-right: 20px; */
    height: 59px;

    /* background: #171923; */
    margin-bottom: 0;
    border: none;
    /* position: fixed; */
    position: fixed;
    width: 100%;
    z-index: 9000;
  }

  .shareicon-cont {
    width: 30px;
    height: 30px;
    /* background: #171923; */
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-right: 35px;
  }

  .share-iii {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-blackpen {
    position: absolute;
    height: 35px;
    /* margin-left: 460px;
    margin-top: 20px; */
    top: 10px;
    right: 10px;
    width: 35px;

    background: #ffffff;
    box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.2),
      0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .edit-blackpencont {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .backdrop-img-main {
    width: 100%;
    height: 100%;
  }
  .user-profile-details {
    position: absolute;
    bottom: 5px;
    /* left: 0; */
    text-align: center;
  }

  .edit-profile-pics-icon {
    position: relative;
    height: 35px;
    /* margin-left: 460px;
    margin-top: 20px; */
    width: 35px;
    /* background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(2px); */

    background: #ffffff;
    box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.1),
      0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.04);

    /* background: #ffffff; */
    /* box-shadow: 0px 33.9362px 42.4202px -8.48404px rgba(0, 0, 0, 0.2),
      0px 16.9681px 16.9681px -8.48404px rgba(0, 0, 0, 0.1); */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .edit-profile-pics-icon input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }
  .user-profile-pics-text {
    margin-top: 10px;
  }

  .user-name {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;

    color: #ffffff;
  }
  .user-title {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;

    /* Label */

    color: #e9e9e9;
  }

  .prof-con-cont {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-right: auto;
    margin-left: auto;

    padding-top: 20px;
  }
  .profile-button {
    width: 42%;
    height: 45px;
    font-size: 0.89rem;
    /* font-size: 0.9375rem; */
  }
  .connect-button {
    width: 55%;

    height: 45px;
    font-size: 0.89rem;
  }
  /* .about-user-text {
    padding-left: 10px;
    padding-right: 10px;
  } */

  .phone-numbAd {
    width: 400px;
  }

  .email-addressAd {
    width: 400px;
  }

  .phone-first-flexAd {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .phone-contAd {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* margin-left: 20px; */
    margin-right: 20px;
  }

  .phone-icon-sAd {
    margin-top: 5px;
  }
  .contact-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }
  .contact-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .contact-user-text {
    /* padding-left: 10px;
    padding-right: 10px; */
  }
  .contact-placeholder {
    height: 50px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }
  .call-update-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    /* position: relative; */
  }
  .call-icon-r {
    margin-left: 10px;
    margin-right: 30px;
    margin-top: 10px;
  }
  .forward-icon-r {
    margin-right: 30px;
  }
  .call-inside-cont {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .email-admin-cont {
    width: 100px;
    height: 22px;
    /* padding-right: 20px; */
  }
  .address-admin {
    width: 214px;
    height: 66px;
  }
  .location-addressAd {
    width: 400px;
  }

  .email-contAd {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* margin-left: 20px; */
    margin-right: 20px;
  }
  .social-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }

  .social-media-iconAd {
    position: relative;
    width: 55px;
    height: 55px;
    display: flex;
    margin-right: 15px;
    margin-top: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    background: #ffffff;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08);
    border-radius: 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 22px;

    letter-spacing: 0.01em;

    color: #c2c2c2;
  }

  .social-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .social-user-text {
    /* padding-left: 10px;
    padding-right: 10px; */
  }
  .social-placeholder {
    height: 50px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }

  .snapchat-entry {
    width: 95px;
    height: 95px;

    background: #ffffff;
    box-shadow: 0px 1.69681px 5.09043px rgba(0, 0, 0, 0.1),
      0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
    border-radius: 6px;
    margin: 5px;
  }

  .social-snap-chat-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .location-contAd {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    /* margin-left: 20px; */
    /* margin-right: 20px; */
  }
  .social-snap-chat-icon img {
    margin-top: 30px;
  }
  .snap-chat-tag {
    margin-left: 5px;
    width: 34px;
    height: 20px;

    /* primary/deets green */

    background: #ebf6f6;
    opacity: 0.1;
    border-radius: 10px;

    font-style: normal;
    font-weight: 400;
    font-size: 10px;

    /* identical to box height */

    display: flex;

    justify-content: center;
    align-items: center;
    /* primary/deets green */

    color: #86c9c8;

    opacity: 0.89;
  }

  .snap-chat-tag-text {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .social-media-big-entry {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  }
  .video-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }
  .video-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .video-user-text {
    /* padding-left: 10px;
    padding-right: 10px; */
  }
  .video-placeholder {
    height: 50px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }

  .links-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    /* padding-right: 50px; */
    width: 100%;
    height: 42px;
    margin-top: 20px;
    background: #ffffff;
  }
  .links-header-text {
    /* font-style: normal; */
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }

  div.main-linksAd {
    width: 400px;
  }
  .links-user-text {
    /* padding-left: 10px;
    padding-right: 10px; */
  }
  .links-placeholder {
    height: 50px;
    /* background: red; */
    text-align: center;
    padding-top: 30px;
    color: #ececec;
    font-weight: 400;
    font-size: 12px;
  }
}

@media only screen and (max-width: 512px) {
  div.main-linksAd {
    width: 350px;
  }

  .phone-numbAd {
    width: 350px;
  }

  .email-addressAd {
    width: 350px;
  }

  .location-addressAd {
    width: 350px;
  }
}

@media only screen and (max-width: 451px) {
  div.main-linksAd {
    width: 280px;
  }

  .phone-numbAd {
    width: 280px;
  }

  .email-addressAd {
    width: 280px;
  }

  .location-addressAd {
    width: 280px;
  }

  .new_user-title {
    font-size: 0.8rem;
    max-width: 200px;
    font-weight: 600;
  }

  .connect-button {
    width: 55%;

    height: 44px;
    font-size: 0.89rem;
  }

  .profile-button {
    width: 42%;
    height: 44px;
    font-size: 0.89rem;
  }
}

@media only screen and (max-width: 430px) {
  .new_profile_cardGenCover {
    height: 253px;
  }
  .new_profile-pics-cont {
    height: 275px;
  }
}

@media only screen and (max-width: 426px) {
  .new_user-name {
    font-size: 1.35rem;
  }
}

@media only screen and (max-width: 420px) {
  .new_user-name {
    font-size: 1.32rem;
  }
}

@media only screen and (max-width: 415px) {
  .new_profile_cardGenCover {
    height: 238px;
  }
  .new_profile-pics-cont {
    height: 250px;
  }

  .new_user-name {
    font-size: 1.3rem;
  }

  .new_user-title {
    font-size: 0.6rem;
    line-height: normal;
  }

  .new_user-profile-pics {
    width: 142px;
    height: 165px;
  }
  .edit-profile-pics-family {
    bottom: 10px;

    left: 46px;
  }

  .new_uploaded-img {
    width: 164px;
    height: 170px;
  }

  .new_uploaded-img img {
    margin-top: 0px;
    height: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 409px) {
  .new_user-name {
    font-size: 1.25rem;
  }
}

@media only screen and (max-width: 398px) {
  .new_user-name {
    font-size: 1.22rem;
  }
}

@media only screen and (max-width: 396px) {
  .new_user-name {
    font-size: 1.2rem;
  }
  .new_user-title {
    font-size: 0.75rem;
    max-width: 170px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 390px) {
  .new_user-name {
    font-size: 1.1rem;
  }

  .new_user-title {
    font-size: 0.75rem;
    max-width: 170px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 380px) {
  .new_user-name {
    font-size: 1.05rem;
  }
  .new_user-title {
    font-size: 0.7rem;
    max-width: 160px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 370px) {
  .new_user-name {
    font-size: 1.05rem;
  }
  .new_user-title {
    font-size: 0.7rem;
    max-width: 160px;
    font-weight: 600;
  }
}
@media only screen and (max-width: 365px) {
  div.main-linksAd {
    width: 240px;
  }

  .new_user-name {
    font-size: 1rem;
  }

  .new_user-title {
    font-size: 0.7rem;
    max-width: 145px;
    font-weight: 600;

    line-height: normal;
  }
  .phone-numbAd {
    width: 240px;
  }

  .email-addressAd {
    width: 240px;
  }

  .location-addressAd {
    width: 240px;
  }
}
@media only screen and (max-width: 353px) {
  .new_user-name {
    font-size: 0.95rem;
  }
}

@media only screen and (max-width: 350px) {
  .new_user-name {
    font-size: 0.93rem;
  }
}

@media only screen and (max-width: 345px) {
  .new_user-name {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 340px) {
  .new_user-name {
    font-size: 0.8375rem;
  }

  .new_user-title {
    font-size: 0.4rem;
    max-width: 130px;
    line-height: normal;
  }
}

@media only screen and (max-width: 335px) {
  div.main-linksAd {
    width: 200px;
  }

  .new_user-name {
    font-size: 1rem;
  }

  .new_user-title {
    font-size: 0.7rem;
    max-width: 145px;
    font-weight: 600;

    line-height: normal;
  }
  .phone-numbAd {
    width: 200px;
  }

  .email-addressAd {
    width: 200px;
  }

  .location-addressAd {
    width: 200px;
  }
}

@media only screen and (max-width: 333px) {
  .new_user-name {
    font-size: 0.83rem;
  }
}

@media only screen and (max-width: 325px) {
  .new_user-name {
    font-size: 0.73rem;
  }
}
@media only screen and (max-width: 320px) {
  .user-name {
    font-size: 15px;
  }
  .user-title {
    font-size: 10px;
    margin-top: 0px;
  }
}

@media only screen and (max-width: 312px) {
  .new_user-name {
    font-size: 0.68rem;
  }

  .new_user-title {
    max-width: 120px;
  }
}

@media only screen and (max-width: 275px) {
  div.main-linksAd {
    width: 150px;
  }

  .phone-numbAd {
    width: 150px;
  }

  .email-addressAd {
    width: 150px;
  }

  .location-addressAd {
    width: 150px;
  }
}

@media only screen and (max-width: 213px) {
  div.main-linksAd {
    width: 80px;
  }

  .phone-numbAd {
    width: 80px;
  }

  .email-addressAd {
    width: 80px;
  }

  .location-addressAd {
    width: 80px;
  }
}

@media only screen and (max-width: 170px) {
  div.main-links {
    width: 50px;
  }

  .phone-numbAd {
    width: 50px;
  }

  .email-addressAd {
    width: 50px;
  }

  .location-addressAd {
    width: 50px;
  }
}
