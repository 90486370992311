.createTeam-info-family {
  background: #f8fafc;
  /* padding-bottom: 100px; */
  height: 100vh;
}

.totalResBodyCreateTeam {
  max-width: 500px;
  margin: auto;
  position: relative;
}

.createTemp_heading {
  color: #333;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  margin-top: 50px;
}

.createtemplate_form_gen {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
}
.createTemp_input input {
  border-radius: 16px;
  border: 1.5px solid #e2e8f0;
  background: #f8fafc;
  outline: none;
  height: 48px;
  padding: 12px;
  box-sizing: border-box;
  width: 100%;
  color: #1e293b;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;

  margin-top: 5px;
}

.createTemp_input input::placeholder {
  color: #64748b;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.createTemp_input input[type="text"] {
  color: #1e293b;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.createTempBtn_ {
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  border-radius: 14px;
  background: #36a6a4;
  height: 45px;
  width: 80%;
  outline: none;
  border: none;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 70px;
}

.createTempBtn_:hover {
  opacity: 0.8;
  border-radius: 10px;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyCreateTeam {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyCreateTeam {
    max-width: 100%;
  }

  .createTeam-info-family {
    background: #f8fafc;
    /* padding-bottom: 100px; */
  }
}
