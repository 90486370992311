.totalResBodyTeamQrmodal {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative; */
}
.qrcode-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: rgba(23, 25, 35, 0.2);
  backdrop-filter: blur(10px);
}

/* Modal Content */
.qrcode-modal-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* background-color: #fefefe; */
  margin: auto;
  padding: 0;
  /* border: 1px solid #888; */
  /* width: 80%; */

  width: 267px;
  height: 298px;

  background: #ffffff;
  box-shadow: 4px 9px 20px 4px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.4s;
  animation-name: animateright;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
}

/* Add Animation */
@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.qrcode-modal-body {
  /* margin-top: 20px; */
  padding: 2px 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01em;

  /* Text color */

  color: #333333;
}
.qrcode-close_logout_icon {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3), 0px 6px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: -50px;
}
.qrcode-text-scan {
  margin-top: 10px;
}
@media only screen and (max-width: 600px) {
  /* The Modal (background) */
  .qrcode-modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    /* padding-top: 100px;  */
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(23, 25, 35, 0.2);
    backdrop-filter: blur(10px);
  }

  /* Modal Content */
  .qrcode-modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    /* background-color: #fefefe; */
    margin: auto;
    padding: 0;
    /* border: 1px solid #888; */
    /* width: 80%; */

    width: 267px;
    height: 298px;

    background: #ffffff;
    box-shadow: 4px 9px 20px 4px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
  }

  /* Add Animation */
  @-webkit-keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  .qrcode-modal-body {
    /* margin-top: 20px; */
    padding: 2px 16px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;

    /* Text color */

    color: #333333;
  }
  .qrcode-close_logout_icon {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3),
      0px 6px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -50px;
  }
  .qrcode-text-scan {
    margin-top: 10px;
  }
}
