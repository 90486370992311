.connectionCard_bodyGen {
  width: 100%;
  height: 93px;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  background: #fbfbfb;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  padding: 5px;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
}

.deleteContact_iconnn__ {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}

.deleteContact_iconnn__ img {
  margin-left: 10px;
  cursor: pointer;
}

.connectionCard_bodyGen:hover {
  background: #f8f8f8;
}
.connectionCard_date {
  font-style: normal;
  font-weight: 400;
  font-size: 10px;

  color: #ababab;
  margin-right: 25px;
  /* position: absolute; */
  /* right: 10px;
  top: 7px; */
}
.connections_optIcon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.connections_optIcon img {
  cursor: pointer;
}

.connections_dateOpt {
  width: fit-content;
  /* position: absolute;
  bottom: 0px; */
  /* width: 100%; */
  /* display: flex;
  flex-direction: column; */
  margin-top: 65%;
}
.connects_detailFlex {
  display: flex;
  align-items: center;
}

.connects_detailsIcon {
  margin-right: 5px;
}
.connectionCard_SubBody {
  display: flex;
  align-items: flex-start;
  margin-top: 7px;
  margin-left: 10px;
}
.connectModal_textHead {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #000000;
}

.connectModalMail_text {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #838383;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.connectModalPhone_text {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #838383;

  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.saveConnects_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #36a6a4;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06);
  border-radius: 15px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 90%;
  height: 30px;

  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.connectionCard_img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.connectionCard_Right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.connectionCard_RightName {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: #171923;
}

.connectionCard_RightDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 11px;

  color: #838383;
  /* width: 100%; */
  width: 150px;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 4px;
}

.connectModal_mailGen {
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.conectModal_phoneGen {
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
}
.connectModal_mailIcon {
  margin-right: 5px;
}

.connectModalPhone_icon {
  margin-right: 5px;
}
.menu_genBody {
  width: 155px;
  height: 157px;
  position: absolute;
  right: -15px;
  top: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  z-index: 10000;
}

.deleteContact_Btn {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: #36a6a4; */
  /* box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.08),
    0px 1.69681px 3.39362px rgba(0, 0, 0, 0.06); */
  border-radius: 13px;

  color: #94a3b8;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  width: 90%;
  height: 30px;

  /* color: #ffffff; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 7px;
  /* border: none; */
  outline: none;

  /* border-radius: 12px; */
  border: 0.3px solid #94a3b8;
  background: #fbfbfb;
  box-shadow: 0px 1.6968085765838623px 3.3936171531677246px 0px
      rgba(0, 0, 0, 0.06),
    0px 4px 14px 0px rgba(0, 0, 0, 0.08);
  cursor: pointer;
  transition: 0.3s ease-in-out 0s;
}
.deleteContact_Btn button {
  outline: none;
}

.deleteContact_Btn:hover {
  opacity: 0.8;
  /* border: 1.5px solid #d2d2d2; */
  border-radius: 10px;
}

@media only screen and (max-width: 600px) {
  .connectionCard_bodyGen:hover {
    background: #f8f8f8;
  }
}

@media only screen and (max-width: 475px) {
  /* .connectionCard_RightDesc {
    width: 250px;
  } */
}

@media only screen and (max-width: 415px) {
  /* .connectionCard_RightDesc {
    width: 250px;
  } */
}

@media only screen and (max-width: 390px) {
  /* .connectionCard_RightDesc {
    width: 260px;
  } */
}

@media only screen and (max-width: 365px) {
  /* .connectionCard_RightDesc {
    width: 220px;
  } */
}

@media only screen and (max-width: 325px) {
  /* .connectionCard_RightDesc {
    width: 170px;
  } */
}
