@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyTeamConnect {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 100vh;
}

body {
  background: #e8e8e8;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  height: 100%;
}
.team-connections-view-body {
  position: relative;
  height: 100%;
}
.team-connections-view-header {
  width: 100%;
  height: 56px;

  background: #304457;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  /* margin-bottom: 20px; */
  overflow-x: hidden;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.team-connections-view-header > .team-connections-view-text img {
  margin-left: 20px;
}
.team-connections-add-connect {
  margin-right: 20px;
}
.team-connections-search-fam {
  margin-top: 10px;
  position: relative;
  width: 75%;
  align-items: center;
}
.search-team-connections-icon {
  position: absolute;
  left: 0;
  margin-top: 10px;
  margin-left: 10px;
}
.team-connections-search-input input {
  width: 100%;

  height: 40px;

  /* White */

  background: #ffffff;
  outline: none;
  border: none;
  padding-left: 50px;
  padding-right: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;

  color: #333333;

  opacity: 0.7;
}
.team-connections-search-bar-cont {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
}
.team-connections-search-filter {
  margin-top: 10px;
  width: 13%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* White */

  background: #ffffff;
}
.team-noConnections-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tetette {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.team-noConnections-text-nn {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #333333;
  margin-top: 10px;
}

.team-noConnections-text-nnnn {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #333333;

  opacity: 0.67;
  margin-top: 10px;
}

.team-noConnections-add-btn {
  width: 90%;
  height: 49px;
  /* height: 100%; */

  background: #fcb816;
  border-radius: 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  /* position: absolute; */
  bottom: 2%;
  margin: 0 auto;
  left: 0;
  right: 0;
}

@media only screen and (max-width: 600px) {
  body {
    background: #e8e8e8;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
  }
  .team-connections-view-body {
    position: relative;
    height: 100%;
  }
  .team-connections-view-header {
    width: 100%;
    height: 56px;

    background: #304457;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    /* margin-bottom: 20px; */
    overflow-x: hidden;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .team-connections-view-header > .team-connections-view-text img {
    margin-left: 20px;
  }
  .team-connections-add-connect {
    margin-right: 20px;
  }
  .team-connections-search-fam {
    margin-top: 10px;
    position: relative;
    width: 68%;
    align-items: center;
  }
  .search-team-connections-icon {
    position: absolute;
    left: 0;
    margin-top: 10px;
    margin-left: 10px;
  }
  .team-connections-search-input input {
    width: 100%;

    height: 40px;

    /* White */

    background: #ffffff;
    outline: none;
    border: none;
    padding-left: 50px;
    padding-right: 10px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 13px;

    color: #333333;

    opacity: 0.7;
  }
  .team-connections-search-bar-cont {
    display: flex;
    width: 90%;
    align-items: center;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }
  .team-connections-search-filter {
    margin-top: 10px;
    width: 13%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;

    /* White */

    background: #ffffff;
  }
  .team-noConnections-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tetette {
    /* margin-top: 30%; */
    margin-top: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .team-noConnections-text-nn {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #333333;
    margin-top: 10px;
  }

  .team-noConnections-text-nnnn {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

    color: #333333;

    opacity: 0.67;
    margin-top: 10px;
  }

  .team-noConnections-add-btn {
    width: 90%;
    height: 49px;
    /* height: 100%; */

    background: #fcb816;
    border-radius: 6px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;

    color: #ffffff;
    /* position: absolute; */
    bottom: 2%;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
}
