.totalResBodyTeamTempDelTemp {
  width: 700px;

  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative;
  height: 100vh; */
}

.delete-temp-modal {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background: rgba(23, 25, 35, 0.2);
  backdrop-filter: blur(10px);
}

/* Modal Content */
.delete-temp-content {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* background-color: #fefefe; */
  margin: auto;
  padding: 0;
  /* border: 1px solid #888; */
  width: 80%;

  /* width: 267px; */
  height: 232px;

  background: #ffffff;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animateright;
  -webkit-animation-duration: 0.4s;
  animation-name: animateright;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  border-radius: 12px;
}

/* Add Animation */
@-webkit-keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animateright {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.delete-temp-body {
  padding: 2px 16px;
}
.close_delete_temp_icon {
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3), 0px 6px 10px rgba(0, 0, 0, 0.25);
  position: absolute;
  top: -50px;
}
.delete-temp-text-2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
  color: #333333;
}

.delete-temp-fulltext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  /* or 183% */
  margin-bottom: 20px;
  margin-top: 5px;

  /* Text color */

  color: #333333;
  opacity: 0.4;
}
.delete-temp-continue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  width: 212px;
  border-radius: 3px;
  margin-bottom: 12px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  background: #333333;
  /* Text color */

  color: #ffffff;
}
.delete-temp-cancel-btn {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  width: 212px;
  background: #ffffff;
  border-radius: 3px;
  margin-bottom: 5px;

  font-style: normal;
  font-weight: 400;
  border: 1px solid #cbd5e0;

  font-size: 14px;

  /* Text color */

  color: #333333;
}

@media only screen and (max-width: 600px) {
  /* The Modal (background) */

  .delete-temp-modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    /* padding-top: 100px;  */
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(23, 25, 35, 0.2);
    backdrop-filter: blur(10px);
  }

  /* Modal Content */
  .delete-temp-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    /* background-color: #fefefe; */
    margin: auto;
    padding: 0;
    /* border: 1px solid #888; */
    width: 80%;

    /* width: 267px; */
    height: 232px;

    background: #ffffff;

    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
    border-radius: 12px;
  }

  /* Add Animation */
  @-webkit-keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  .delete-temp-body {
    padding: 2px 16px;
  }
  .close_delete_temp_icon {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3),
      0px 6px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -50px;
  }
  .delete-temp-text-2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 5px;
    color: #333333;
  }

  .delete-temp-fulltext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    /* or 183% */
    margin-bottom: 20px;
    margin-top: 5px;

    /* Text color */

    color: #333333;
    opacity: 0.4;
  }
  .delete-temp-continue-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    width: 212px;
    border-radius: 3px;
    margin-bottom: 12px;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    background: #333333;
    /* Text color */

    color: #ffffff;
  }
  .delete-temp-cancel-btn {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 49px;
    width: 212px;
    background: #ffffff;
    border-radius: 3px;
    margin-bottom: 5px;

    font-style: normal;
    font-weight: 400;
    border: 1px solid #cbd5e0;

    font-size: 14px;

    /* Text color */

    color: #333333;
  }
}
