.singleTeam_tempBody {
  /* display: none; Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 300000000; /* Sit on top */
  /* padding-top: 100px;  */
  display: flex;
  justify-content: center;
  align-items: baseline;
  /* align-items: flex-end; */
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 500px;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  /* background: #171923; */
  background: rgba(23, 25, 35, 0.95);
  /* opacity: 0.7; */
  /* backdrop-filter: blur(10px); */
}

.singleTeam-switchFam {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.fixedSingle_header {
  position: fixed;
  width: 100%;
  z-index: 11000;
}

.fixedSingle_switch {
  position: fixed;
  width: 100%;
  top: 50px;
  background: #f9fafc;
  z-index: 11000;
}

.fixedSingle_tab2Search {
  position: fixed;
  width: 100%;
  margin-top: -145px;
  background: #f9fafc;
  z-index: 11000;
}

.single_tab1_genn {
  margin-top: 150px;
}

.single_eachCard_body {
  margin-top: 260px;
  overflow-y: scroll;
}
.singleTeam-btnGen {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  /* width: 328px; */
  width: 80%;
  height: 45px;

  background: #e2e8f0;
  border-radius: 8.91px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  margin-left: auto;
  margin-right: auto;
}

.switcher-animation_singleTeam {
  /* width: 163px; */
  width: 47.5%;
  height: 39px;
  position: absolute;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  /* left: 4px; */

  background: #f8fafc;
  /* border: 0.5px solid #e2e8f0; */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  font-weight: 700;
  font-size: 12px;
  color: #171923;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: 0.5s ease-in-out 0s; */
}

.members_listgen_fam {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 25px;
}

.member_optionsGenFam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.member_optionFamily {
  display: flex;
  align-items: center;
  width: 49%;
  height: 33px;
  border-radius: 7px;
  border: 0.5px solid #f1f5f9;
  background: #fbfbfb;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  padding-left: 10px;
  box-sizing: border-box;
}

.member_sortFamily {
  display: flex;
  align-items: center;
  width: 49%;
  height: 33px;
  border-radius: 7px;
  border: 0.5px solid #f1f5f9;
  background: #fbfbfb;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.04);
  padding-left: 10px;
  box-sizing: border-box;
}
.member_option_funnel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.member_sort_funnel {
  display: flex;
  align-items: center;
  justify-content: center;
}

.member_opt_selectGen {
  width: calc(100% - 25px);
}
.member_opt_selectGen select {
  outline: none;
  width: 100%;
  height: 33px;
  border: none;
  background: transparent;

  color: #94a3b8;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.custom_selectMembers_sortAll {
  width: calc(100% - 25px);
}

.custom_selectMembers_sortAll select {
  outline: none;
  width: 100%;
  height: 33px;
  border: none;
  background: transparent;

  color: #94a3b8;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}
.member_sort_funnel {
  display: flex;
  align-items: center;
  justify-content: center;
}
.member_sortFamily {
  display: flex;
  align-items: center;
  width: 45%;
}

.connect-searchWrapperMember {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-top: 20px;
}

.connect-searchWrapperMember input {
  width: 100%;
  height: 48px;

  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 10px;

  font-family: "Poppins";
  color: #94a3b8;

  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  outline: none;

  border-radius: 16px;
  border: 1.5px solid #f1f5f9;
  background: #f8fafc;
}

.connect-searchIconMember {
  height: 20px;
  width: 20px;
  position: absolute;
  box-sizing: border-box;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
}

.connect-searchIconMember img {
  width: 100%;
  height: 100%;
}
.useImageSwitch_singleTeam {
  width: 45%;
  height: 39px;
  border-radius: 7px;
  font-style: normal;

  display: flex;
  color: #686c75;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
}

.useColorSwitch_singleTeam {
  /* position: relative; */
  width: 45%;
  height: 39px;
  border-radius: 7px;
  color: #686c75;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  cursor: pointer;
}

.singleTeamModal-Content {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;

  background: #f8fafc;
  /* padding: 10px; */
  /* z-index: 400000; */
  overflow-y: scroll;
  -webkit-animation-name: animatedown;
  -webkit-animation-duration: 0.4s;
  animation-name: animatedown;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
  left: 0;
  right: 0;

  border-radius: 0px;
  /* padding: 10px; */
}

.edit_tempNameGenFam {
  width: 100%;
  background: #f1f5f9;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  height: 110px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
}

.tempName_headerFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.temp_nameHText {
  color: #333;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}

.temp_nameEditTextName {
  color: #36a6a4;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

.tempName___ {
  display: flex;
  width: 80%;
  height: 48px;
  padding-left: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;

  border-radius: 16px;
  border: 1.5px solid #e2e8f0;
  background: #e2e8f0;

  color: #94a3b8;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-left: auto;
  margin-right: auto;
}

.singleTeam_personGen {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;
  margin-bottom: 22px;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.singleTeam_personText_ {
  color: #838383;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
}

.singleTeam_personEditText__ {
  border-radius: 10px;
  border: 0.5px solid #36a6a4;
  background: rgba(54, 166, 164, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 121px;
  height: 28px;
  color: #36a6a4;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}

.singleteamOptional_text {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  color: #838383;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
}

.personalTemp_inviteBtn {
  border-radius: 14px;
  background: #36a6a4;
  height: 45px;
  outline: none;
  border: none;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  margin-top: 50px;
}

.personalTemp_inviteBtn:hover {
  border-radius: 12px;
  opacity: 0.8;
}

.personalTemp_deleteBtn {
  color: #fb7185;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  border-radius: 14px;
  background: transparent;
  height: 45px;
  outline: none;
  border: 1px solid #fb7185;
  display: block;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 50px;
}

.personalTemp_deleteBtn:hover {
  border-radius: 12px;
  opacity: 0.8;
}

.team_noMembers_gen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.team_nomembersHeader_ {
  color: #1e293b;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin-top: 10px;
}

.team_nomembersSub_text {
  color: #64748b;
  text-align: center;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  margin-top: 15px;
}

.teamnomember_invite_btn {
  border-radius: 14px;
  background: #36a6a4;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  color: #fff;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  outline: none;
  border: none;
  position: fixed;
  bottom: 10px;
  cursor: pointer;
  z-index: 11000;
}

.teamnomember_invite_btn:hover {
  border-radius: 14px;
  opacity: 0.8;
}

@media only screen and (max-width: 1400px) {
  .singleTeam_tempBody {
    max-width: 500px;
  }
}

@media only screen and (max-width: 600px) {
  .singleTeam_tempBody {
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    display: flex;
    justify-content: center;
    align-items: baseline;
    right: 0;
    top: 0;
    max-width: 100%;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(23, 25, 35, 0.95);
    /* opacity: 0.7; */
  }

  .singleTeamModal-Content {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;

    background: #f8fafc;
    /* padding: 10px; */
    /* z-index: 400000; */
    overflow-y: scroll;
    -webkit-animation-name: animatedown;
    -webkit-animation-duration: 0.4s;
    animation-name: animatedown;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
    left: 0;
    right: 0;

    border-radius: 0px;
    /* padding: 10px; */
  }
}
