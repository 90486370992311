@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

@media only screen and (max-width: 600px) {
  .team-card-personalCom-fam {
    width: 90%;

    background: #ffffff;

    border-radius: 6px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 10px;
  }
  .team-card-peesonalCom-cont {
    display: flex;
    /* margin-top: 20px; */
  }
  .team-card-personalCom-userIcon {
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .team-card-personalCom-right-fam {
    width: 80%;
  }
  .team-card-personalCom-uI {
    width: 37px;
    height: 37px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #f9f9f9;
    border-radius: 50%;
  }
  .team-card-personalCom-name-con {
    display: flex;
    align-items: center;
  }
  .team-card-personalCom-export {
    display: flex;
    margin-left: 10px;
    align-items: center;
    justify-content: center;
    padding: 1px;
    width: 24px;
    height: 15px;

    background: rgba(54, 166, 164, 0.1);
    border-radius: 6px;
  }
  .team-card-personalCom-ex-num {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;

    color: #36a6a4;
    margin-left: 3px;
  }

  .team-card-personalCom-name {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .team-card-personalCom-name-sta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  .team-card-personalCom-penSta {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;

    color: #fa1414;

    opacity: 0.6;
    margin-right: 20px;
  }

  .team-card-personalCom-post-fam {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }

  .team-card-personalCom-dash {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;

    color: #333333;

    opacity: 0.6;
    margin-right: 20px;
  }

  .team-card-personalCom-post {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;

    color: #333333;

    opacity: 0.7;
  }
  .team-card-personalCom-email-fam {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .team-card-personalCom-email {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    color: #333333;

    opacity: 0.5;
  }

  .team-card-personalCom-dash {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;

    /* Text color */

    color: #333333;

    opacity: 0.6;
    margin-right: 20px;
  }
  .team-card-personalCom-dept-fam {
    width: 100%;
    height: 30px;

    background: #ffffff;
    border-top: 1px solid #f8f8f8;
    border-radius: 0px 0px 6px 6px;
    margin-top: 15px;
    /* align-items: center; */
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .team-card-personalCom-dept {
    width: 60px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: rgba(54, 166, 164, 0.1);
    border-radius: 30px;
    font-style: normal;
    font-weight: 275;
    font-size: 10px;
    margin-left: 20%;
    color: #36a6a4;
  }
}
