@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyTeamAddTemp {
  max-width: 500px; /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* position: relative;
  height: 100vh; */
}

.added-template-fam {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 42px;
  background: #ffffff;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.added-template-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #333333;
  margin-left: 10px;
}

.added-template-img {
  margin-right: 10px;
}
@media only screen and (max-width: 600px) {
  .added-template-fam {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 42px;
    background: #ffffff;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
  }

  .added-template-text {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    color: #333333;
    margin-left: 10px;
  }

  .added-template-img {
    margin-right: 10px;
  }
}
