.totalResTeamInviteMem {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

.video-Teaminvite-familyGen {
  background: #fbfbfb;
  position: relative;
  min-height: 100vh;
}

.video-Teaminvite-family {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.add-Teaminvite-family {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  /* identical to box height */

  display: flex;
  justify-content: flex-end;

  color: #36a6a4;
  /* margin-right: 12%; */
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}

.add-Teaminvite-cont {
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 30px; */
  margin-top: 10px;
  cursor: pointer;
}

.add-Teaminvite-cont {
  margin-right: 5px;
  cursor: pointer;
  /* width: 28px;
  height: 28px; */
}

.Teaminvite_svg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

@media only screen and (max-width: 1400px) {
  .totalResTeamInviteMem {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResTeamInviteMem {
    max-width: 100%;
  }

  .video-Teaminvite-family {
    background: #fbfbfb;
    /* padding-bottom: 50px; */
    min-height: 100vh;
    position: relative;
  }

  .video-Teaminvite-family {
    position: relative;
  }

  .add-Teaminvite-family {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height */

    display: flex;
    justify-content: flex-end;

    color: #36a6a4;
  }

  .add-Teaminvite-cont {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-right: 30px; */
    margin-top: 10px;
    cursor: pointer;
  }
  .add-Teaminvite-cont {
    margin-right: 5px;
    /* width: 28px;
    height: 28px; */
  }
}
