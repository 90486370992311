@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }

  .members-clickedInfo-info-header {
    width: 100vw;
    height: 56px;

    background: #304457;

    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding-left: 30px; */
    /* padding-right: 30px; */
    margin-bottom: 5px;
    overflow-x: hidden;
  }
  .members-clickedInfo-info-header img {
    margin-left: 30px;
  }
  .members-clickedInfo-backArrow {
    margin-left: 30px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .members-clickedInfo-info-header > .members-clickedInfo-info-text {
    margin-left: -50px;
  }

  .members-clickedInfo-info-nafam {
    background: #ffffff;
    width: 100%;
    border-bottom: 0.2px solid #cbd5e0;
  }
  .members-clickedInfo-info-nafam {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .members-clickedInfo-info-main1 {
    width: 20%;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
  }

  .members-clickedInfo-info-main1cont {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  .members-clickedInfo-info-main2 {
    width: 80%;
    align-items: center;
  }

  .members-clickedInfo-info-nameCont {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .members-clickedInfo-info-shardeCon {
    width: 27px;
    height: 15px;

    background: rgba(54, 166, 164, 0.1);
    border-radius: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .members-clickedInfo-info-shareiconss {
    margin-top: -2px;
    margin-right: 2px;
  }
  .members-clickedInfo-info-posi {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;

    /* Text color */

    color: #333333;

    opacity: 0.7;
  }

  .members-clickedInfo-info-namee {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    color: #333333;
  }
  .members-clickedInfo-info-shareno {
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #36a6a4;
  }

  .members-clickedInfo-info-tickk {
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;

    background: #ebf6f6;
    border-radius: 50%;
    /* opacity: 0.1; */
  }

  .members-clickedInfo-info-emaill {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;

    color: #000000;

    opacity: 0.5;
    word-wrap: break-word;
    width: 100%;
  }
  .members-clickedInfo-info-lastShared {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;

    color: #333333;

    opacity: 0.3;
  }

  .members-clickedInfo-info-lastDate {
    font-style: normal;
    font-weight: 500;
    font-size: 8px;

    color: #333333;

    opacity: 0.3;
  }

  .members-clickedInfo-info-posiFam {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .members-clickedInfo-info-posiFam2 {
    margin-right: 20px;
    text-align: right;
  }
  .members-clickedInfo-view-conneNN {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    margin-left: 5%;
    margin-top: 20px;

    color: #333333;

    opacity: 0.5;
  }
  .members-clickedInfo-view-sjs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5px;
    /* margin-bottom: 10px; */

    /* White */

    background: #ffffff;
    /* Deets entry field stroke */

    border-bottom: 0.2px solid #cbd5e0;
  }

  .members-clickedInfo-Namdxd {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    color: #333333;

    opacity: 0.8;
    margin-left: 10px;
  }
  .members-clickedInfo-Dateee {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;

    color: #333333;

    opacity: 0.3;
    margin-right: 10px;
  }
}
