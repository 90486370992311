@media only screen and (max-width: 600px) {
  /* The Modal (background) */
  .social-tag-modal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 300000000; /* Sit on top */
    /* padding-top: 100px;  */
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background: rgba(23, 25, 35, 0.2);
    backdrop-filter: blur(10px);
  }

  /* Modal Content */
  .social-tag-modal-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    /* background-color: #fefefe; */
    margin: auto;
    padding: 0;
    /* border: 1px solid #888; */
    /* width: 80%; */
    width: 267px;
    height: 363px;

    background: #ffffff;
    box-shadow: 4px 9px 20px 4px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    -webkit-animation-name: animateright;
    -webkit-animation-duration: 0.4s;
    animation-name: animateright;
    animation-duration: 0.4s;
    transition: transform 0.3s ease-in-out;
  }

  /* Add Animation */
  @-webkit-keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  @keyframes animateright {
    from {
      right: -300px;
      opacity: 0;
    }
    to {
      right: 0;
      opacity: 1;
    }
  }

  .social-tag-modal-body {
    /* margin-top: 20px; */
    padding: 2px 16px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    /* margin-left: auto;
    margin-right: auto; */

    /* Text color */

    color: #333333;
  }
  .social-tag-close_logout_icon {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    background: #ffffff;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3),
      0px 6px 10px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: -50px;
  }
  .social-tag-text-scan {
    margin-top: 10px;
  }
  .add-tag-content-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    /* Text color */

    color: #333333;

    opacity: 0.4;
    margin-bottom: 10px;
  }
  .add-tag-header {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    color: #333333;
    margin-bottom: 10px;
  }
  .tags-flex {
    display: flex;
    justify-content: space-evenly;
  }
  .personal-tag-cont {
    margin-right: 5px;
    width: 119px;
    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    /* Text color */

    color: #e9e9e9;

    /* Label */

    background: #f8f8f8;

    border-radius: 20px;
  }

  .business-tag-cont {
    width: 119px;
    height: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    /* Text color */

    color: #e9e9e9;

    /* Label */

    background: #f8f8f8;

    border-radius: 20px;
  }
  .business-tag-cont:hover {
    background: #36a6a4;
    color: #ffffff;
  }

  .personal-tag-cont:hover {
    background: #36a6a4;
    color: #ffffff;
  }

  .inputFieldSocialTag {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .inputFieldSocialTag input {
    padding: 8px 10px;
    padding-top: 20px;
    padding-right: 50px;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 78%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #333333;
  }

  .inputFieldSocialTag input:focus {
    /* border: 1.8px solid #6b6b6b; */
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .inputFieldSocialTag span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .inputFieldSocialTag input:focus + span,
  .inputFieldSocialTag input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .inputFieldSocialTag input,
  .inputFieldSocialTag span {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .add-tag-button {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 212px;
    height: 49px;
    color: #333333;
    background: #ffffff;
    border: 1px solid #36a6a4;
    border-radius: 3px;
    margin-top: 20px;
  }
  .tag-cancel-button {
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 212px;
    height: 49px;
    color: #ffffff;
    background: #36a6a4;
    border: 1px solid #36a6a4;
    border-radius: 3px;
    margin-top: 10px;
  }
}
