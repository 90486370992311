@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBodyLogin {
  max-width: 500px;
  margin: auto;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}
.logout-info-family {
  background: #fbfbfb;
  padding-bottom: 50px;
  position: relative;
  min-height: 100vh;
}
.logout-info-header {
  width: 100%;
  height: 56px;

  background: #ffffff;

  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-left: 30px; */
  /* padding-right: 30px; */
  margin-bottom: 20px;
  overflow-x: hidden;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.logout-info-header img {
  margin-left: 20px;
}

/* .backdrop-header img {
  margin-right: 100px;
} */

.logout-info-text img {
  margin-left: -10px;
}

.formBodyGenFam {
  margin-top: 20px;
}
.log-in-email-text {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Text color */
  color: #333333;
}

.loginEmail_subText {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #878787;
  margin-top: 5px;
}
.email_errorCont {
  font-size: 10px;

  color: #ff0000;
  /* width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left; */
}
.login_page-inputField {
  position: relative;
  width: 100%;
  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.login_page-inputField input {
  padding: 8px 10px;
  padding-top: 20px;
  padding-right: 50px;
  border: none;
  border-bottom: 1px solid #cbd5e0;
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 85%;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
  -webkit-appearance: none;
  border-radius: 0;
}

.loginPassEye {
  cursor: pointer;
}
.login_page-inputField input:focus {
  /* border: 1.8px solid #6b6b6b; */
  border: none;
  outline: none;
  border-bottom: 2px solid #36a6a4;
}
.login_page-inputField span {
  pointer-events: none;
  opacity: 0.5;
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #333333;
}

.login_page-inputField input:focus + span,
.login_page-inputField input:not(:placeholder-shown) + span {
  top: 7px;
  -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  transform: scale(0.7) translateY(-10%) translateX(-8.5px);
  font-size: 14px;
}
.login_page-inputField input,
.login_page-inputField span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.login_page_flex_hide {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}
.login_page_flex_hide img {
  position: absolute;
  right: 7%;
}

.show-password {
  position: absolute;
  color: #cad0dc;
  right: 7%;
}
.remember-me-fam {
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  width: 93%;
  height: 36px;
  background: rgba(51, 51, 51, 0.05);
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;

  /* Text color */

  color: #333333;
}
.rem-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 20px;
}

.login_switch_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
}
/* .react-switch > .react-switch-handle {
  width: 25px !important;
  height: 25px !important;
  top: 2px !important;
} */

.forgot-pass-line {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  margin-top: 30px;
  margin-bottom: 20px;

  color: #4487db;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgot-link_pre {
  width: fit-content;
  color: #4487db;
}

.signUpFam_line {
  margin-top: 30px;
  margin-bottom: 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.signUp_init {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #171923;
}

.signUp_link {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  color: #4487db;
  margin-left: 5px;
}
.login-save-form {
  /* position: absolute; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-right: auto;
  margin-left: auto;
  margin-top: 50px;
  /* margin-bottom: 50px; */
}

.login_btn-save {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_btn-save input {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 15px 10px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 80%;
  height: 49px;

  background: #36a6a4;
  /* opacity: 0.3; */
  border-radius: 14px;
  box-sizing: border-box;
  transition: 0.5s ease-in-out 0s;
}

.login_btn-save input:hover {
  opacity: 0.8;
  border-radius: 10px;
}
.show-password {
  width: 22px;
  height: 22px;
}
/* .login-save-form input {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 83%;
  height: 49px;

  background: #36a6a4;
  border-radius: 9px;
} */

/* .login-save-form input:focus {
    opacity: 1;
  } */
.get-card {
  position: absolute;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 10px;
  color: #36a6a4;
  bottom: 5px;
  left: 0;
  right: 0%;
  margin-right: auto;
  margin-left: auto;
}

@media only screen and (max-width: 1400px) {
  .totalResBodyLogin {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  .totalResBodyLogin {
    max-width: 100%;
  }
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }
  .logout-info-family {
    background: #fbfbfb;
    padding-bottom: 100px;
  }
  .logout-info-header {
    width: 100vw;
    height: 56px;

    background: #ffffff;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  }
  .logout-info-header img {
    margin-left: 20px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .logout-info-text img {
    margin-left: -10px;
  }
  .log-in-email-text {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    /* identical to box height */
    display: flex;
    align-items: center;
    justify-content: center;
    /* Text color */
    color: #333333;
  }

  .login_page-inputField {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .login_page-inputField input {
    padding: 8px 10px;
    padding-top: 20px;
    padding-right: 50px;
    border: none;
    border-bottom: 1px solid #cbd5e0;
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 75%;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #333333;
    -webkit-appearance: none;
    border-radius: 0;
  }

  .login_page-inputField input:focus {
    /* border: 1.8px solid #6b6b6b; */
    border: none;
    outline: none;
    border-bottom: 2px solid #36a6a4;
  }
  .login_page-inputField span {
    pointer-events: none;
    opacity: 0.5;
    position: absolute;
    padding-left: 20px;
    left: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #333333;
  }

  .login_page-inputField input:focus + span,
  .login_page-inputField input:not(:placeholder-shown) + span {
    top: 7px;
    -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    transform: scale(0.7) translateY(-10%) translateX(-8.5px);
    font-size: 14px;
  }
  .login_page-inputField input,
  .login_page-inputField span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .login_page_flex_hide {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }
  .login_page_flex_hide img {
    position: absolute;
    right: 7%;
  }

  .show-password {
    position: absolute;
    color: #cad0dc;
    right: 7%;
  }
  .remember-me-fam {
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    width: 90%;
    height: 36px;
    background: rgba(51, 51, 51, 0.05);
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;

    /* Text color */

    color: #333333;
  }
  .rem-text {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }

  .login_switch_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  }
  /* .react-switch > .react-switch-handle {
    width: 25px !important;
    height: 25px !important;
    top: 2px !important;
  } */

  .forgot-pass-line {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    /* margin-left: 40px; */
    margin-top: 30px;

    color: #4487db;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-save-form {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    /* bottom: 30px; */

    margin-top: 50px;
    /* margin-bottom: 50px; */
  }
  .show-password {
    width: 22px;
    height: 22px;
  }
  .login-save-form input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 10px 15px;
    color: #ffffff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 80%;
    height: 49px;

    background: #36a6a4;
    /* opacity: 0.3; */
    border-radius: 12px;
    box-sizing: border-box;
  }

  /* .login-save-form input:focus {
    opacity: 1;
  } */
  .get-card {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 10px;
    color: #36a6a4;
  }
}
