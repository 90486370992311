.sectionHam-info-header {
  /* width: 100%; */
  height: 56px;

  /* background: #171923; */

  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 20px;
  overflow-x: hidden;

  position: fixed;
  width: 500px;
  z-index: 9000;
  margin-top: -50px;
}

/* .support-info-header img {
    margin-left: 30px;
  } */

/* .backdrop-header img {
  margin-right: 100px;
} */

.sectionHam-info-header > .sectionHam-info-text {
  margin-left: -30px;
}

@media only screen and (max-width: 600px) {
  .sectionHam-info-header {
    width: 100%;
  }
}
