.feedbackSmall-secondFlex {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */

  margin-left: 20px;
  margin-right: 10px;
}

.feedbackSmall-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;

  height: 48px;

  /* Grays/Gray 50 */

  background: #f8fafc;
  /* Grays/Gray 200 */

  border: 1.5px solid #e2e8f0;
  border-radius: 50px;
  margin-top: 5px;
  margin-right: 2px;
  cursor: pointer;
  transition: 0.5s ease-in-out 0s;
}

.feedbackSmall-number {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  color: #171923;
  margin-right: 5px;
}

.feedbackSmall-text {
  margin-right: 20px;

  font-weight: 600;
  font-size: 14px;

  color: #333333;

  opacity: 0.7;
}

/* @media only screen and (max-width: 425px) {
  .feedbackBig-flex {
    height: 38px;
  }
  .feedbackBig-text {
    font-size: 10px;
  }

  .feedbackBig-number {
    font-size: 12px;
  }
} */

@media only screen and (max-width: 366px) {
  .feedbackSmall-number {
    margin-right: 5px;
    font-weight: 600;
    font-size: 14px;
  }

  .feedbackSmall-secondFlex {
    margin-right: 10px;
  }

  .feedbackSmall-text {
    margin-right: 20px;

    font-size: 12px;
  }
}

@media only screen and (max-width: 361px) {
  .feedbackSmall-number {
    margin-right: 5px;
  }

  .feedbackSmall-secondFlex {
    margin-right: 10px;
  }
}
