@import url("https://fonts.googleapis.com/css2?family=Abhaya+Libre:wght@400;500;600;700;800&family=Montserrat:wght@400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,400&display=swap");

.totalResBackBody {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  background: #e8e8e8;
  overflow-x: hiddens;
}

.backDrop-switchFam {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.delete_logoGen {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  margin-top: 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #333333;
}

.delete_logoBtn_actv {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  background: #36a6a4;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  margin-top: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
}

.delete_logoBtn_actv:hover {
  opacity: 0.8;
}

.delete_logoBtn_dis {
  width: 90%;
  margin-right: auto;
  margin-left: auto;
  background: #fbfbfb;
  border: 1px solid #171923;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 49px;
  margin-top: 10px;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
}
.backdrop-btnGen {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  width: 328px;
  height: 45px;

  background: #e2e8f0;
  border-radius: 8.91px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switcher-animation {
  width: 163px;
  height: 39px;
  position: absolute;
  left: 4px;
  bottom: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;

  /* left: 4px; */

  background: #f8fafc;
  /* border: 0.5px solid #e2e8f0; */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  font-weight: 700;
  font-size: 12px;
  color: #171923;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition: 0.5s ease-in-out 0s; */
}

.useImageSwitch {
  width: 45%;
  height: 39px;
  border-radius: 7px;
  font-style: normal;

  display: flex;
  color: #686c75;
  font-weight: 700;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  cursor: pointer;
}
/* .useImageSwitch:hover {
  background: #fff;
  color: #36a6a4;
} */

.useColorSwitch {
  /* position: relative; */
  width: 45%;
  height: 39px;
  border-radius: 7px;
  color: #686c75;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  cursor: pointer;
}

/* .useColorSwitch:hover {
  background: #fff;
  color: #36a6a4;
} */

.onActiveSwitch {
  position: absolute;
  left: 4px;

  background: #f8fafc;
  /* border: 0.5px solid #e2e8f0; */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  font-weight: 700;
  font-size: 12px;
  color: #171923;
  transition: 0.5s ease-in-out 0s;
}
.onActiveSwitchTwo {
  position: absolute;
  right: 4px;
  background: #f8fafc;
  /* border: 0.5px solid #e2e8f0; */
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.12), 0px 3px 1px rgba(0, 0, 0, 0.04);
  border-radius: 7px;

  font-weight: 700;
  font-size: 12px;
  color: #171923;
  transition: 0.5s ease-in-out 0s;
}

.backdrop-sliderRight {
  transform: translateX(5px);
}

.backdrop-sliderLeft {
  transform: translateX(-5px);
}

/* .backdrop-sliderAni-ccc {
  -webkit-animation-name: animaterightoner;
  -webkit-animation-duration: 0.4s;
  animation-name: animaterightoner;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
}

.backdrop-sliderAni-cccxxx {
  -webkit-animation-name: animaterightonef;
  -webkit-animation-duration: 0.4s;
  animation-name: animaterightonef;
  animation-duration: 0.4s;
  transition: transform 0.3s ease-in-out;
} */

/* Add Animation */
@-webkit-keyframes animaterightonef {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@keyframes animaterightonef {
  from {
    left: -300px;
    opacity: 0;
  }
  to {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes animaterightoner {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

@keyframes animaterightoner {
  from {
    right: -300px;
    opacity: 0;
  }
  to {
    right: 0;
    opacity: 1;
  }
}

.backdrop-family-cont {
  background: #fbfbfb;
  min-height: 100vh;
  overflow-x: hidden;
  padding-bottom: 100px;
  /* position: absolute; */
  /* top: 0;
    right: 0; */
}

.backdrop-header {
  width: 100%;
  height: 56px;

  background: #171923;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;

  /* identical to box height, or 157% */

  /* Text color */

  color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.cancelBackdrop-btn {
  cursor: pointer;
}

.backdrop-header > .backdrop-text {
  margin-left: -60px;
}

.backdrop-uploadImgF {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 35px;
  height: 35px;

  /* background: rgba(255, 255, 255, 0.7); */

  backdrop-filter: blur(2px);

  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;

  transition: 0.2s ease-in-out 0s;
}

.backdrop-uploadImgF:hover {
  /* background: #e9e9e9; */
  transform: scale(1.1);
}

.backdrop-uploadImgF img {
  width: 16px;
  height: 14px;
}

.backdrop-select-text {
  background: #e9e9e9;
  width: 100%;
  height: 41px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;

  /* identical to box height, or 157% */

  display: flex;
  align-items: center;
  justify-content: center;

  /* Text color */

  color: #333333;
  margin-top: 20px;
}
.backdrop-img-preview {
  position: relative;
  margin-top: 20px;
  width: 320px;
  height: 150px;

  /* background: #ebf6f6; */
  border-radius: 14px;
  margin-left: auto;
  margin-right: auto;
  /* border: 2px dashed #36a6a4; */

  /* Label */

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .bacccc{
    position: relative;
  } */
.backdrop-preview-text {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;

  letter-spacing: 0.01em;

  /* Text color */

  color: #8b8f8f;

  /* opacity: 0.21; */
}
.backdrop__textGen {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.backdropText__ {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;

  color: #333333;

  opacity: 0.6;
}

.sketch-picker {
  margin-left: auto;
  margin-right: auto;
}
.close-backdrop-img {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
}

.close-backdrop-img img {
  width: 36px;
  height: 36px;
  cursor: pointer;
  transition: 0.2s ease-in-out 0s;
}

.close-backdrop-img:hover {
  transform: scale(1.1);
}

.genIconCont_back {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: relative;
}
.phone-icon-s_back {
  /* margin-top: 5px; */
  width: 100%;
  height: 100%;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  border-radius: 50%;
  opacity: 0.2;
}

.childIcon_back {
  position: absolute;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.childIcon_came {
  position: absolute;

  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
}

.color-code-family {
  margin-top: 0px;
  margin-bottom: 40px;
  width: 100%;
  height: 40px;
  padding-top: 15px;
  padding-bottom: 15px;
  /* background: #ffffff; */
}

.color-code-cont {
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  margin-top: 5px;
}
.color-code {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #cce3e2;
  border-radius: 14px;
  cursor: pointer;
}

.activeColor-code {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 4px solid #cce3e2;
  border-radius: 14px;
  border: 4px solid #36a6a4;
}

.checkIcon_cont {
  position: absolute;
  /* margin-left: -150px; */
  transform: translate(-50%, -50%);
  bottom: -30px;
  left: 50%;
}

.backdrop-button {
  display: flex;
  /* padding: 30px 11px; */
  justify-content: center;
  align-items: center;
  /* padding: 14px 11px; */

  width: 192px;
  height: 49px;

  /* primary/deets green */

  /* background: #36a6a4; */
  border-radius: 12px;

  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  position: relative;
  /* margin-top: 20px;
  margin-bottom: 5px; */
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}

.backdrop-button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.backdrop-button__ {
  display: flex;
  /* padding: 30px 11px; */
  justify-content: center;
  align-items: center;
  /* padding: 14px 11px; */

  width: 328px;
  height: 49px;

  /* primary/deets green */

  /* background: #36a6a4; */
  border-radius: 12px;

  font-weight: 600;
  font-size: 14px;

  color: #ffffff;
  position: relative;
  /* margin-top: 20px;
  margin-bottom: 5px; */
  transition: 0.5s ease-in-out 0s;
  cursor: pointer;
}

.backdrop-button__:hover {
  opacity: 0.8;
  cursor: pointer;
}
.center-upload-iiibtn__ {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}
.center-upload-iii {
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
}
.backdrop-upload-btn-center {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cover-image-bg input {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.backdrop-BtnContainerGen {
  display: flex;
  justify-content: space-between;
  /* flex-direction: column-reverse; */
  width: 328px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.backdrop-Btn_color {
  width: 328px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.backdrop-BackBtn_color {
  width: 328px;
  height: 49px;

  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  box-sizing: border-box;
  cursor: pointer;

  transition: 0.5s ease-in-out 0s;
  margin-right: auto;
  margin-left: auto;
}
.backdrop-BackBtn {
  /* padding: 14px 11px; */

  width: 130px;
  height: 49px;

  background: #ffffff;
  border: 1px solid #000000;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  color: #171923;
  box-sizing: border-box;
  cursor: pointer;

  transition: 0.5s ease-in-out 0s;
}

.backdrop-BackBtn_color:hover {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
}

.backdrop-BackBtn:hover {
  background: #e9e9e9;
  border: 1px solid #d2d2d2;
}

.customize-icon-lock {
  width: 100%;
  height: 58px;

  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  flex-direction: column;
  color: #c1c1c1;
  margin-top: 20px;
}
.customize-lock-btn {
  width: 32px;
  height: 29px;

  background: conic-gradient(
    from 180deg at 50% 50%,
    #22005d 0deg,
    #fbe200 63.75deg,
    #0535ba 140.63deg,
    rgba(69, 118, 154, 0.395833) 217.5deg,
    #960d0d 275.62deg,
    #22005d 360deg
  );
  border-radius: 2px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-theme-text {
  margin-top: 2px;
}

.save-backdrop-info {
  /* position: absolute; */
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  bottom: 30px;

  margin-top: 60px;
  margin-bottom: 50px;
}
.save-backdrop-info button {
  border: none;
  outline: none;

  color: #333333;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  width: 80vw;

  height: 49px;

  background: #ffffff;
  border: 1px solid #36a6a4;
  border-radius: 3px;

  border-radius: 9px;
  margin-top: 40px;
}

@media only screen and (max-width: 1400px) {
  .totalResBackBody {
    max-width: 500px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media only screen and (max-width: 600px) {
  body {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
    background: #e8e8e8;
    overflow-x: hiddens;
  }

  .totalResBackBody {
    max-width: 100%;
  }

  .backdrop-family-cont {
    background: #fbfbfb;
    min-height: 100vh;
    overflow-x: hidden;
    padding-bottom: 100px;
  }
  .backdrop-header {
    width: 100%;
    height: 56px;

    background: #171923;

    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    /* identical to box height, or 157% */

    /* Text color */

    color: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
  }

  /* .backdrop-header img {
  margin-right: 100px;
} */

  .backdrop-header > .backdrop-text {
    margin-left: -60px;
  }

  .backdrop-select-text {
    background: #e9e9e9;
    width: 100%;
    height: 41px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;

    /* identical to box height, or 157% */

    display: flex;
    align-items: center;
    justify-content: center;

    /* Text color */

    color: #333333;
    margin-top: 20px;
  }
  .backdrop-img-preview {
    position: relative;
    margin-top: 20px;
    width: 320px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    background: #ebebeb;

    /* Label */
    /* border: 2px dashed #36a6a4; */
    border-radius: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  /* .bacccc{
    position: relative;
  } */
  .backdrop-preview-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;

    letter-spacing: 0.01em;

    /* Text color */

    color: #8b8f8f;

    /* opacity: 0.21; */
  }
  .close-backdrop-img {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
  .close-backdrop-img img {
    width: 36px;
    height: 36px;
  }
  .color-code-family {
    margin-top: 0px;
    margin-bottom: 40px;
    width: 100%;
    height: 40px;
    padding-top: 15px;
    padding-bottom: 15px;
    /* background: #ffffff; */
  }

  .color-code-cont {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    margin-top: 5px;
  }
  .color-code {
    position: relative;

    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    border: 4px solid #cce3e2;
  }

  .backdrop-button {
    display: flex;
    /* padding: 30px 11px; */
    justify-content: center;
    align-items: center;
    /* padding: 14px 11px; */

    width: 192px;
    height: 49px;

    /* primary/deets green */

    /* background: #36a6a4; */
    border-radius: 12px;

    font-weight: 600;
    font-size: 14px;

    color: #ffffff;
    position: relative;
    /* margin-top: 20px;
  margin-bottom: 5px; */
  }

  .center-upload-iii {
    margin-left: auto;
    margin-right: auto;
  }
  .backdrop-upload-btn-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .cover-image-bg input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
  }

  .customize-icon-lock {
    width: 100%;
    height: 58px;

    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    flex-direction: column;
    color: #c1c1c1;
    margin-top: 20px;
  }
  .customize-lock-btn {
    width: 32px;
    height: 29px;

    background: conic-gradient(
      from 180deg at 50% 50%,
      #22005d 0deg,
      #fbe200 63.75deg,
      #0535ba 140.63deg,
      rgba(69, 118, 154, 0.395833) 217.5deg,
      #960d0d 275.62deg,
      #22005d 360deg
    );
    border-radius: 2px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom-theme-text {
    margin-top: 2px;
  }

  .save-backdrop-info {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 100vw;
    justify-content: center;
    bottom: 30px;

    margin-top: 60px;
    margin-bottom: 50px;
  }
  .save-backdrop-info button {
    border: none;
    outline: none;

    color: #333333;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    width: 80vw;

    height: 49px;

    background: #ffffff;
    border: 1px solid #36a6a4;
    border-radius: 3px;

    border-radius: 9px;
    margin-top: 40px;
  }
}
