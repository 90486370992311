.inputFieldDefaultR {
  position: relative;
  width: 100%;
  height: 48px;

  /* margin-left: 10px;
    margin-right: auto; */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.inputFieldDefaultR input {
  padding: 8px 20px;
  padding-top: 25px;
  border: 1.5px solid #e2e8f0;
  /* border-bottom: 1px solid #cbd5e0; */
  /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
  /* border-radius: 3px; */
  width: 100%;
  /* height: 49px; */

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  -webkit-appearance: none;
  border-radius: 18px;
  color: #333333;
  background: #f8fafc;
  /* box-sizing: border-box; */
  box-sizing: border-box;
}

.inputFieldDefaultR input:-webkit-autofill,
.inputFieldDefaultR input:-webkit-autofill:hover,
.inputFieldDefaultR input:-webkit-autofill:focus,
.inputFieldDefaultR input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f8fafc inset !important;
}

.inputFieldDefaultR input:focus {
  border: 1.8px solid #6b6b6b;
  outline: none;
  /* border: 1.5px solid #36a6a4; */
}
.inputFieldDefaultR span {
  pointer-events: none;
  /* opacity: 0.5; */
  position: absolute;
  padding-left: 20px;
  left: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: text;

  font-weight: 500;
  font-size: 14px;

  /* Text color */

  color: #cbd5e1;
}

.inputFieldDefaultR input:focus + span,
.inputFieldDefaultR input:not(:placeholder-shown) + span {
  top: 7px;
  /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
  /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
  transform: translateY(-10%) translateX(-8.5px);
  padding-left: 5%;
  font-size: 10px;
  color: #333333;
  opacity: 0.5;
}
.inputFieldDefaultR input,
.inputFieldDefaultR span {
  /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.default-save-formR {
  margin-top: 20px;
  margin-bottom: 30px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in-out 0s;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.default-save-formR:hover {
  opacity: 0.8;
}
.default-save-formR input {
  border: none;
  outline: none;
  font-family: "Poppins";

  padding: 14px 30px;
  color: #ffffff;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  height: 49px;
  background: #36a6a4;

  /* background: #36a6a4; */
  opacity: 1;
  border-radius: 18px;
  cursor: not-allowed;
  transition: 0.5s ease-in-out 0s;
  box-sizing: border-box;
}

@media only screen and (max-width: 1400px) {
  .inputFieldDefaultR {
    width: 100%;
  }

  .default-save-formR {
    width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .inputFieldDefaultR {
    position: relative;
    width: 100%;
    /* margin-left: 10px;
    margin-right: auto; */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    height: 48px;
  }

  .inputFieldDefaultR input {
    padding: 8px 20px;
    padding-top: 25px;
    padding-right: 35px;

    border: 1.5px solid #e2e8f0;
    /* border-bottom: 1px solid #cbd5e0; */
    /* border-bottom: 1.8px solid rgba(107, 107, 107, 0.4); */
    /* border-radius: 3px; */
    width: 100%;

    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    -webkit-appearance: none;
    border-radius: 18px;
    color: #333333;
    background: #f8fafc;
  }

  .inputFieldDefaultR input:focus {
    border: 1.8px solid #6b6b6b;
    outline: none;
    /* border: 1.5px solid #36a6a4; */
  }
  .inputFieldDefaultR span {
    pointer-events: none;
    /* opacity: 0.5; */

    position: absolute;

    padding-left: 20px;
    left: 10px;
    /* padding-left: 35px;
    left: 10px; */
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    cursor: text;

    font-weight: 500;
    font-size: 14px;

    /* Text color */

    color: #cbd5e1;
  }

  .inputFieldDefaultR input:focus + span,
  .inputFieldDefaultR input:not(:placeholder-shown) + span {
    top: 7px;
    /* -webkit-transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    /* transform: scale(0.7) translateY(-10%) translateX(-8.5px); */
    transform: translateY(-10%) translateX(-8.5px);
    padding-left: 10%;
    font-size: 10px;
    color: #333333;
    opacity: 0.5;
  }
  .inputFieldDefaultR input,
  .inputFieldDefaultR span {
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; */
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  }

  .default-save-formR {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
  }
  .default-save-formR input {
    border: none;
    outline: none;
    font-family: "Poppins";

    padding: 14px 30px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    width: 100%;
    height: 49px;
    background: #36a6a4;

    /* background: #36a6a4; */
    opacity: 1;
    border-radius: 18px;
  }
}

@media only screen and (max-width: 500px) {
  /* .inputFieldDefaultR span {
    padding-left: 30px;
  } */

  /* .default-save-formR input {
    width: 100%;
  } */
}

@media only screen and (max-width: 430px) {
  /* .inputFieldDefaultR span {
    padding-left: 20px;
  } */
}
